/*
 * Plugin: Web Slide Navigation System
 * Demo Link: http://webslidemenu.uxwing.com/
 * Author: UXWing
 * License: http://codecanyon.net/licenses/standard
*/

/*--------------------------------------------------------------------------
[Table of contents]

01 Base CSS
02 Desktop CSS
03 Tab CSS
04 Mobile CSS
05 Additional Themes (Optionnal)
--------------------------------------------------------------------------*/

/*--------------------------------------------------------------------------
|  01  Base CSS
--------------------------------------------------------------------------*/

.wsmain html, .wsmain body, .wsmain iframe, .wsmain h1, .wsmain h2, .wsmain h3, .wsmain h4, .wsmain h5, .wsmain h6 {
  margin: 0px;
  padding: 0px;
  border: 0px;
  font: inherit;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Lato,'sans-serif',arial;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  font-smooth: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-smoothing: subpixel-antialiased !important;
  font-smooth: subpixel-antialiased;
}

.wsmain .cl {
  clear: both;
}

.wsmain img, object, embed, video {
  border: 0 none;
  max-width: 100%;
}

.wsmain a:focus {
  outline: none !important;
}

.wsmain:before, .wsmain:after {
  content: "";
  display: table;
}

.wsmain:after {
  clear: both;
}
.pinned_wsmain:before, .pinned_wsmain:after {
  content: "";
  display: table;
}

.pinned_wsmain:after {
  clear: both;
}

/*--------------------------------------------------------------------------
|  02 Desktop CSS
--------------------------------------------------------------------------*/
.pinned_wsmenucontainer.wsoffcanvasopener {
  display: none;
}

.wsmobileheader {
  display: none;
}

#overlapblackbg {
  display: none;
}

.smllogo {
  padding: 0px 0px;
  margin: 0;
  float: left;
}

.smllogo a {
  display: block;
  float: left;
  padding: 12px 20px 0px 15px;
}

.smllogo a img {
  width: 90px
}

.topmenusearch {
  float: right;
  width: 168px;
  height: 47px;
  position: relative;
  margin: 0px 0px 0px 0px;
}
.pinned_topmenusearch {
  float: right;
  width: 168px;
  height: 47px;
  position: relative;
  margin: 0px 0px 0px 0px;
}

.topmenusearch .searchicon {
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}
.pinned_topmenusearch .searchicon {
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}
.topmenusearch input {
  width: 100%;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  height: 47px;
  z-index: 2;
  outline: none;
  color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.bespoke input {
  width: 100%;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  margin-left: 2px !important;
  margin-right: 2px !important;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  height:33px;
  z-index: 2;
  outline: none;
  color: #4d5055;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.pinned_topmenusearch input {
  width: 100%;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  height: 47px;
  z-index: 2;
  outline: none;
  color: #ccc;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.topmenusearch input:focus {
  color: #fff;
  width: 250px;
  background-color: #181818;
}
.pinned_topmenusearch input:focus {
  color: #fff;
  width: 250px;
  background-color: #181818;
}

.topmenusearch input:hover {
  color: #fff;
  width: 250px;
  background-color: #181818;
}
.pinned_topmenusearch input:hover {
  color: #fff;
  width: 250px;
  background-color: #181818;
}

.topmenusearch input:focus~.btnstyle .icon-search,.topmenusearch input:focus~.btnstyle .fa {
  color: #fff;
}
.pinned_topmenusearch input:focus~.btnstyle .icon-search,.pinned_topmenusearch input:focus~.btnstyle .fa {
  color: #fff;
}

.topmenusearch input:focus~.searchicon {
  opacity: 1;
  z-index: 3;
  color: #FFFFFF;
}
.pinned_topmenusearch input:focus~.searchicon {
  opacity: 1;
  z-index: 3;
  color: #FFFFFF;
}

.topmenusearch .btnstyle {
  top: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.3;
  color: #fff;
  z-index: 1000;
  background-color: transparent;
  border: solid 0px;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.pinned_topmenusearch .btnstyle {
  top: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.3;
  color: #fff;
  z-index: 1000;
  background-color: transparent;
  border: solid 0px;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.topmenusearch .btnstyle .icon-search,.topmenusearch .btnstyle .fa {
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
}
.pinned_topmenusearch .btnstyle .fa {
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.topmenusearch .btnstyle:hover {
  background-color: #282C37;
  opacity: 0.9;
}

.pinned_topmenusearch .btnstyle:hover {
  background-color: #282C37;
  opacity: 0.9;
}

.wsmain {
  width: 100%;
  max-width: 1280px;
  background: #2b3a40;
  margin: 35px auto;
  padding: 0px 0px;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.pinned_wsmain {
  width: 100%;
  max-width: 1280px;
  background: #2b3a40;
  margin: 35px auto;
  padding: 0px 0px;
  position: relative;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.wsmenu {
  font-family: Lato,'sans-serif',arial;
  color: #fff;
  font-size: 12px;
  padding: 0px 0px 0px 0px;
  margin: 0px auto;
  width: 90%;
  float: right;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}


.wsmenu-list {
  text-align: left;
  margin: 0 auto 0 auto;
  width: 100%;
  display: block;
  padding: 0px;
}

.wsmenu-list>li {
  text-align: center;
  display: block;
  float: left;
  margin: 0px;
  padding: 0px;
}

.wsmenu-list>li>a .fa {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 8px;
  color: #898d91;
}
.wsmenu-list>li>button .fa {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 8px;
  color: #898d91;
}

.wsmenu-list li ul li a .fa.fa-angle-double-right {
  font-size: 12px;
  margin: 0 3px 0 -4px;
}

.mobiletext {
  display: none;
}

.wsmenu-list li a .arrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid #898d91;
  content: "";
  right: 12px;
  height: 0;
  margin: 0 0 0 9px;
  position: absolute;
  text-align: right;
  top: 22px;
  width: 0;
}

.wsmenu-list>li>a {
  display: block;
  color: #f5f5f5;
  padding: 0px 30px 0px 18px;
  line-height: 47px;
  border-right: 1px solid rgba(0, 0, 0, 0.10);
  text-decoration: none;
  position: relative;
}
.wsmenu-list>li>button {
  color: #f5f5f5;
  padding: 0px 30px 0px 18px;
  line-height: 47px;
  border-right: 1px solid rgba(0, 0, 0, 0.10);
  text-decoration: none;
  position: relative;
}
.wsmenu-list li button .arrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid #898d91;
  content: "";
  right: 12px;
  height: 0;
  margin: 0 0 0 9px;
  position: absolute;
  text-align: right;
  top: 22px;
  width: 0;
}

.wsmenu-list li a:hover .arrow:after {
  border-top-color: #b3b3b3
}

.wsmenu-list li a.active .arrow:after {
  border-top-color: #b3b3b3
}

.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #b3b3b3
}
.wsmenu-list li button:hover .arrow:after {
  border-top-color: #b3b3b3
}

.wsmenu-list li button.active .arrow:after {
  border-top-color: #b3b3b3
}

.wsmenu-list li:hover>button .arrow:after {
  border-top-color: #b3b3b3
}

.megamenu iframe {
  width: 100%;
  margin-top: 10px;
  min-height: 200px;
}

.megamenu video {
  width: 100%;
  margin-top: 10px;
  min-height: 200px;
}

.wsmenu-list>li a.menuhomeicon {
  padding: 0px 12px;
}

.wsmenu-list>li.socialicon {
  float: left;
}

.wsmenu-list>li.socialicon a {
  width: 38px;
  padding: 0px 10px
}

.wsmenu-list>li.socialicon a .fa {
  font-size: 15px;
  color: #898d91;
  text-align: center;
}

.wsmenu-list>li.rightmenu {
  float: right;
}

.wsmenu-list>li.rightmenu a {
  padding: 0px 30px 0px 20px;
  border-right: none;
}

.wsmenu-list>li.rightmenu a .fa {
  font-size: 15px;
}

.wsmenu-list>li.rightmenu {
  float: right;
}

.wsmenu-list>li.rightmenu:before, .wsmenu-list>li.rightmenu:after {
  content: "";
  display: table;
}

.wsmenu-list>li.rightmenu:after {
  clear: both;
}

.wsmenu-list>li.rightmenu {
  zoom: 1;
}

.wsmenu-list>li.carticon a {
  padding: 0px 27px 0px 15px;
  text-align: center;
}

.wsmenu-list>li.carticon a .fa {
  font-size: 15px;
}

.wsmenu-list>li.carticon em.roundpoint {
  position: absolute;
  top: 8px;
  width: 15px;
  height: 15px;
  background-color: #DA4B38;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  font-style: normal;
  line-height: 14px !important;
  color: #fff;
  right: 20px;
}

.wsmenu-list li:hover .megamenu {
  opacity: 1;
}

.megamenu {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 48px;
  color: #000;
  z-index: 1000;
  margin: 0px;
  text-align: left;
  padding: 10px 0px;
  font-size: 15px;
  border: solid 1px #eeeeee;
  background-color: #fff;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.megamenu .title {
  border-bottom: 1px solid #CCC;
  font-size: 14px;
  padding: 9px 5px 9px 0px;
  font-size: 17px;
  color: #424242;
  margin: 0px 0px 7px 0px;
  text-align: left;
  height: 39px;
  margin: 0px 0px 10px 0px;
}

.link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}

.link-list li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 6px 0px;
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  display: block;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
  color: #424242;
}

.link-list .fa {
  font-size: 11px;
}

.megacollink {
  width: 23%;
  float: left;
  margin: 0% 1%;
  padding: 0px;
}

.megacollink li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}

.megacollink li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 8px 0px;
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  display: block;
  border-right: 1px solid #e7e7e7;
  background-color: #fff;
  color: #424242;
}

.megacollink .fa {
  font-size: 11px;
}

.megacolimage {
  width: 31.33%;
  float: left;
  margin: 0% 1%;
}

.typographydiv {
  width: 100%;
  margin: 0% 0%;
}

.typographylinks {
  width: 25%;
  float: left;
  margin: 0% 0%;
}

.typographylinks ul {
  padding: 0px;
}

.mainmapdiv {
  width: 100%;
  display: block;
  margin: 0% 0%;
}

.wsmenu-list .ad-style {
  width: 28%;
  float: right;
}

.wsmenu-list .ad-style a {
  border: none !important;
  padding: 0px !important;
  margin: 0px !important;
  line-height: normal !important;
  background-image: none !important;
}

.mobile-sub .megamenu .ad-style a:hover {
  background-color: transparent !important;
}

.wsmenu-list .megamenu li:hover>a {
  background: transparent !important;
}

.wsmenu-list .megamenu li a:hover {
  background: transparent !important;
  text-decoration: underline;
}

.wsmenu-list .megamenu li .fa {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}

.mrgtop {
  margin-top: 15px;
}

.show-grid div {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  margin: 2px 0px;
}

.megamenu .left.carousel-control {
  padding-top: 20%;
}

.megamenu .right.carousel-control {
  padding-top: 20%;
}

.carousel-inner .item img {
  width: 100%;
}

.megamenu .carousel-caption {
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  height: 31px;
  left: 0;
  padding: 7px 0;
  right: 0;
  width: 100%;
}

.typography-text {
  padding: 0px 0px;
  font-size: 14px;
}

.typography-text ul {
  padding: 0px 0px;
  margin: 0px;
}

.typography-text p {
  text-align: justify;
  line-height: 24px;
  color: #656565;
}

.typography-text ul li {
  display: block;
  padding: 2px 0px;
  line-height: 22px;
}

.typography-text ul li a {
  color: #656565;
}

.halfmenu {
  width: 40%;
  right: auto !important;
  left: auto !important;
}

.halfmenu .megacollink {
  width: 48%;
  float: left;
  margin: 0% 1%;
}

.halfdiv {
  width: 30%;
  right: auto;
  left: auto;
}

.halfdiv03 {
  width: 25%;
  right: auto;
  left: auto;
}

.menu_form {
  width: 100%;
  display: block;
}

.menu_form input[type="text"] {
  width: 100%;
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
}

.menu_form input[type="password"] {
  width: 100%;
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
}

.menu_form textarea {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  color: #000;
  font-size: 13px;
  padding: 8px 5px;
  margin-bottom: 8px;
  min-height: 122px;
}

.menu_form input[type="submit"] {
  width: 30%;
  font-family: Lato,'sans-serif',arial;
  display: inline-block;
  padding: 7px 15px;
  font-size: 13px;
  font-weight: bold;
  margin-right: 8px;
  float: right;
  color: #333;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  background-color: #eaeaea;
  background-image: -moz-linear-gradient(#fafafa, #eaeaea);
  background-image: -webkit-linear-gradient(#fafafa, #eaeaea);
  background-image: linear-gradient(#fafafa, #eaeaea);
  background-repeat: repeat-x;
  border-radius: 2px;
  border: 1px solid #ddd;
  border-bottom-color: #c5c5c5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  text-decoration: none;
}

.menu_form input[type="button"] {
  width: 30%;
  font-family: Lato,'sans-serif',arial;
  display: inline-block;
  padding: 7px 15px;
  font-size: 13px;
  font-weight: bold;
  float: right;
  color: #333;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  background-color: #eaeaea;
  background-image: -moz-linear-gradient(#fafafa, #eaeaea);
  background-image: -webkit-linear-gradient(#fafafa, #eaeaea);
  background-image: linear-gradient(#fafafa, #eaeaea);
  background-repeat: repeat-x;
  border-radius: 2px;
  border: 1px solid #ddd;
  border-bottom-color: #c5c5c5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  text-decoration: none;
}

.wsmenu-list li>.wsmenu-submenu {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsmenu-list li:hover>.wsmenu-submenu {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsmenu-submenu li>.wsmenu-submenu-sub {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsmenu-submenu li:hover>.wsmenu-submenu-sub {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsmenu-submenu-sub li>.wsmenu-submenu-sub-sub {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsmenu-submenu-sub li:hover>.wsmenu-submenu-sub-sub {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsmenu-list li>.megamenu {
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
  visibility: hidden;
}

.wsmenu-list li:hover>.megamenu {
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.wsmenu-submenu {
  position: absolute;
  top: 48px;
  z-index: 1000;
  margin: 0px;
  padding: 0px;
  border: solid 1px #eeeeee;
  background-color: #fff;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
}

.wsmenu-submenu li a {
  background: #fff !important;
  background-image: none !important;
  color: #666666 !important;
  border-right: 0 none !important;
  text-align: left;
  display: block;
  line-height: 22px;
  padding: 6px 12px;
  text-transform: none;
  font-size: 13px;
  letter-spacing: normal;
  border-right: 0px solid;
}

.wsmenu-submenu li {
  position: relative;
  margin: 0px;
  padding: 0px;
}

.wsmenuexpandermain {
  display: none;
}

.wsmenu-list li:hover .wsmenu-submenu {
  display: block;
}

.wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.4s, opacity 0.4s;
  -ms-transition: -ms-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
}

.wsmenu-list .wsmenu-submenu li:hover .wsmenu-submenu-sub {
  opacity: 1;
  list-style: none;
  padding: 0px;
  border: solid 1px #eeeeee;
  background-color: #fff;
}

.wsmenu-list .wsmenu-submenu li:hover .wsmenu-submenu-sub {
  display: block;
}

.wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub .wsmenu-submenu-sub-sub {
  min-width: 220px;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0px;
  padding: 0px;
  opacity: 0;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.4s, opacity 0.4s;
  -ms-transition: -ms-transform 0.4s, opacity 0.4s;
  -moz-transition: -moz-transform 0.4s, opacity 0.4s;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
}

.wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
  opacity: 1;
  list-style: none;
  padding: 0px;
  border: solid 1px #eeeeee;
  background-color: #fff;
}

.wsmenu-submenu li {
  position: relative;
  padding: 0px;
  margin: 0px;
  display: block;
}

.wsmenu-click {
  display: none;
}

.wsmenu-click02 {
  display: none;
}

.wsmenu-submenu .fa {
  margin-right: 7px;
}

.wsmenu-list li:hover>a {
  background-color: rgba(0, 0, 0, 0.10) !important;
  text-decoration: none;
}

.wsmenu-list>li>a:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
  text-decoration: none;
}

.wsmenu-list>li>a.active {
  background-color: rgba(0, 0, 0, 0.10) !important;
  text-decoration: none;
}

.wsmenu-submenu>li>a:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
  border-radius: 0px !important;
  text-decoration: none;
  text-decoration: none;
}
.wsmenu-list li:hover>button {
  background-color: rgba(0, 0, 0, 0.10) !important;
  text-decoration: none;
}

.wsmenu-list>li>button:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
  text-decoration: none;
}

.wsmenu-list>li>buttonb.active {
  background-color: rgba(0, 0, 0, 0.10) !important;
  text-decoration: none;
}

.wsmenu-submenu>li>a:hover {
  background-color: rgba(0, 0, 0, 0.10) !important;
  border-radius: 0px !important;
  text-decoration: none;
  text-decoration: none;
}

/*--------------------------------------------------------------------------
|  03 Tab CSS
--------------------------------------------------------------------------*/

@media only screen and (min-width: 1024px) and (max-width:1280px) {
  .wsmenu-list li a .arrow:after {
    display: none !important;
  }
  .wsmenu-list>li>a {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .wsmenu {
    width: 90% !important;
  }
  .smllogo {
    width: 10% !important;
  }
  .wsmain {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .pinned_wsmain {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .menu_form input[type="submit"] {
    width: 40% !important;
  }
  .menu_form input[type="button"] {
    width: 40% !important;
  }
  .topmenusearch {
    width: 180px;
  }
  .topmenusearch input:focus {
    width: 200px;
  }
  .pinned_topmenusearch {
    width: 180px;
  }
  .pinned_topmenusearch input:focus {
    width: 200px;
  }
}

@media only screen and (min-width:780px) and (max-width:1023px) {
  .wsmenu-list li a .arrow:after {
    display: none;
  }
  .wsmenu-list>li>a {
    white-space: nowrap !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .wsmenu {
    width: 85% !important;
  }
  .smllogo {
    width: 15% !important;
  }
  .wsmain {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .pinned_wsmain {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .wsmenu-list>li.socialicon {
    display: none !important;
  }
  .wsmenu-list>li.carticon {
    display: none !important;
  }
  .megacollink {
    width: 48%;
    margin: 1% 1%;
  }
  .typographylinks {
    width: 48%;
    margin: 1% 1%;
  }
  .menu_form input[type="submit"] {
    width: 40% !important;
  }
  .menu_form input[type="button"] {
    width: 40% !important;
  }
  .wsmenu-list>li>a .fa {
    display: none;
  }
  .mobiletext {
    display: block;
  }
  .topmenusearch {
    width: 120px;
  }
  .topmenusearch input:focus {
    width: 130px;
  }
  .topmenusearch input:hover {
    width: 130px;
  }
  .pinned_topmenusearch {
    width: 120px;
  }
  .pinned_topmenusearch input:focus {
    width: 130px;
  }
  .pinned_topmenusearch input:hover {
    width: 130px;
  }
}

@media only screen and (min-width: 781px) {
  .wsmenu-list li:hover>.wsmenu-submenu {
    display: block !important;
  }
  .wsmenu-submenu li:hover>.wsmenu-submenu-sub {
    display: block !important;
  }
  .wsmenu-submenu-sub li:hover>.wsmenu-submenu-sub-sub {
    display: block !important;
  }
  .wsmenu-list li:hover>.megamenu {
    display: block !important;
  }
  .wsmenu-list>li a.menuhomeicon .fa {
    margin-right: 0px !important;
  }
  .wsmenu-list>li.carticon a .fa {
    margin-right: 0px !important;
  }
  .wsmenu-list>li.socialicon a .fa {
    margin-right: 0px !important;
  }
}

/*--------------------------------------------------------------------------
|  04 Mobile CSS
--------------------------------------------------------------------------*/

@media only screen and (max-width: 780px) {
  .wsmain {
    margin: 0px;
    background-color: transparent;
  }
  .pinned_wsmain {
    margin: 0px;
    background-color: transparent;
  }
  .topmenusearch {
    margin: 0px 0 0;
    width: 100%;
    height: 44px;
  }
  .topmenusearch input:focus {
    width: 100%;
    background-color: #f5f5f5;
    color: #000;
  }
  .topmenusearch input:hover {
    width: 100%;
    background-color: #f5f5f5;
    color: #000;
  }
  .topmenusearch input {
    border-radius: 0px !important;
    height: 44px;
    background-color: #f1f1f1;
  }
  .pinned_topmenusearch {
    margin: 0px 0 0;
    width: 100%;
    height: 44px;
  }
  .pinned_topmenusearch input:focus {
    width: 100%;
    background-color: #f5f5f5;
    color: #000;
  }
  .pinned_topmenusearch input:hover {
    width: 100%;
    background-color: #f5f5f5;
    color: #000;
  }
  .pinned_topmenusearch input {
    border-radius: 0px !important;
    height: 44px;
    background-color: #f1f1f1;
  }
  .smllogo {
    width: 100%;
    display: none !important;
    margin: 0 auto;
    text-align: center;
    background-color: #2B3A40;
  }
  .smllogo a {
    margin: 0 auto;
    text-align: center;
    float: none;
    padding: 10px 0px 3px 0px;
  }
  .topmenusearch .btnstyle {
    height: 44px;
    color: #424242;
  }
  .pinned_topmenusearch .btnstyle {
    color: #424242;
  }
  .pinned_top_search_btn {
    margin-top: 12.5px !important;
  }
  .wsmenucontainer {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .pinned_wsmenucontainer {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  #overlapblackbg {
    left: 0;
    z-index: 102;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: none;
    /* background-color: rgba(0, 0, 0, 0.45); */
    cursor: pointer;
  }
  .wsmenucontainer.wsoffcanvasopener {
    display: block;
    top:-400px;
  }
  .pinned_wsmenucontainer.wsoffcanvasopener {
    display: block;
    top:-400px;
  }
  .wsmobileheader {
    display: block !important;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .callusicon {
    color: #c9c9c9;
    font-size: 16px;
    height: 16px;
    position: fixed;
    right: 15px;
    top: 15px;
    transition: all 0.4s ease-in-out 0s;
    width: 25px;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .callusicon:focus, callusicon:hover {
    color: #fff !important;
  }
  .wsoffcanvasopener .callusicon {
    display: none !important;
  }
  .smallogo {
    width: 100%;
    display: block;
    text-align: center;
    padding-top: 9px;
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 52px;
    background: #2b3a40;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .5);
  }
  .smallogo>img {
    margin-top: 6px;
  }
  .wsmenu-list>li:hover>a {
    background-color: rgba(0, 0, 0, 0.08) !important;
    text-decoration: none;
  }
  .mobiletext {
    display: inline-block !important;
  }
  .wsoffcanvasopener #overlapblackbg {
    display: block !important;
  }
  .wsoffcanvasopener .wsmain {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .wsoffcanvasopener .pinned_wsmain {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .wsmain {
    width: 100%;
    position: fixed;
    top: 0px !important;
    margin: 0;
    float: none;
    background-color: #fff;
    border-radius: 0px;
    z-index: 103;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: none !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
    max-height:500px;
    overflow-y: scroll;
  }
  .pinned_wsmain {
    width: 100%;
    position: fixed;
    top: 50px;
    margin: 0;
    float: none;
    background-color: #fff;
    border-radius: 0px;
    z-index: 103;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: none !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
    max-height:500px;
    height:80px;
  }
  .wsmenu {
    border-left: none;
    float: none;
    width: 100%;
    display: block !important;
    height: 100%;
    overflow-y: auto;
    display: block !important;
    transition:all 1s;
  }
  .wsmenu-click {
    height: 43px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .wsmenu-click i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 11px;
    margin-right: 8px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right;
  }
  .wsmenu-click02 {
    height: 43px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .wsmenu-click02 i {
    display: block;
    height: 23px;
    width: 25px;
    margin-top: 11px;
    margin-right: 8px;
    background-size: 25px;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.25);
    float: right;
  }
  .ws-activearrow>i {
    transform: rotate(180deg);
  }
  .wsmenu-rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .animated-arrow {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .wsoffcanvasopener .animated-arrow {
  
  }
  .animated-arrow {
    cursor: pointer;
    padding: 13px 35px 16px 0px;
    margin: 10px 0 0 15px;
  }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    cursor: pointer;
    height: 2px;
    width: 25px;
    font-smooth: always;
    background: white;
    position: absolute;
    display: block;
    content: '';
    border-radius: 50px;

  }
  .animated-arrow span:before {
    top: -7px;
  }
  .animated-arrow span:after {
    bottom: -7px;
  }
  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    transition: all 500ms ease-in-out;
  }
  .wsoffcanvasopener .animated-arrow span {
    background-color: transparent;
  }
  .wsoffcanvasopener .animated-arrow span:before, .animated-arrow.active span:after {
    top: 7px;
  }
  .wsoffcanvasopener .animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px;
  }
  .wsoffcanvasopener .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .megacollink {
    width: 96% !important;
    margin: 0% 2% !important;
  }
  .megacolimage {
    width: 90% !important;
    margin: 0% 5% !important;
  }
  .typographylinks {
    width: 98% !important;
    margin: 0% 1% !important;
  }
  .typographydiv {
    width: 86% !important;
    margin: 0% 7% !important;
  }
  .mainmapdiv {
    width: 90% !important;
    margin: 0% 5% !important;
  }
  .innerpnd {
    padding: 0px !important;
  }
  .typography-text {
    padding: 10px 0px;
  }
  .wsmenu-list .megamenu {
    background-color: #fff;
    color: #666666;
    display: none;
    position: relative !important;
    top: 0px;
    padding: 10px 0px;
    border: solid 0px;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
  .wsmenu-list li:hover .megamenu {
    display: none;
    position: relative !important;
    top: 0px;
  }
  .megamenu .title {
    color: #666666;
    font-size: 15px !important;
    padding: 10px 8px 10px 0px;
  }
  .megamenu>ul {
    width: 100% !important;
    margin: 0px;
    padding: 0px;
    font-size: 13px !important;
  }
  .megamenu>ul>li>a {
    padding: 9px 14px !important;
    line-height: normal !important;
    font-size: 13px !important;
    background-color: #e7e7e7 !important;
    color: #666666;
  }
  .megamenu>ul>li>a:hover {
    background-color: #000000 !important;
  }
  .ad-style {
    width: 100% !important;
  }
  .megamenu ul li.title {
    line-height: 26px;
    color: #666666;
    margin: 0px;
    font-size: 15px;
    padding: 7px 0px !important;
    border-bottom: 1px solid #ccc;
    background-color: transparent !important;
  }
  .wsmenu-submenu>li:hover>a {
    background-color: #7b7b7b;
    color: #666666;
  }
  .wsmenu>.wsmenu-list>li>a.active {
    color: #666666;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .wsmenu>.wsmenu-list>li>a:hover {
    color: #666666;
    background-color: rgba(0, 0, 0, 0.08);
  }
  .wsmenu-list li:hover .wsmenu-submenu {
    display: none;
  }
  .wsmenu-list li:hover .wsmenu-submenu .wsmenu-submenu-sub {
    display: none;
  }
  .wsmenu-list .wsmenu-submenu .wsmenu-submenu-sub li:hover .wsmenu-submenu-sub-sub {
    display: none;
    list-style: none;
    padding: 2px;
    border: solid 1px #eeeeee;
    background-color: #fff;
  }
  .wsmenu-list li:first-child a {
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
  }
  .wsmenu-list li:last-child a {
    -webkit-border-radius: 0px 4px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
    border-right: 0px solid;
  }
  .wsmenu-submenu-sub {
    width: 100% !important;
    position: static !important;
    left: 100% !important;
    top: 0 !important;
    display: none;
    margin: 0px !important;
    padding: 0px !important;
    border: solid 0px !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
  .wsmenu-submenu-sub li {
    margin: 0px 0px 0px 0px !important;
    padding: 0px;
    position: relative;
  }
  .wsmenu-submenu-sub a {
    display: block;
    padding: 10px 25px 10px 25px;
    border-bottom: solid 1px #ccc;
    font-weight: normal;
  }
  .wsmenu-submenu-sub li a.active {
    color: #000 !important;
  }
  .wsmenu-submenu-sub li:hover>a {
    background-color: #333333 !important;
    color: #fff;
  }
  .wsmenu>.wsmenu-list>li>a>.fa {
    font-size: 16px;
    color: #bfbfbf;
    margin-right: 11px;
    text-align: center;
    width: 19px;
  }
  .wsmenu-submenu-sub-sub {
    width: 100% !important;
    position: static !important;
    left: 100% !important;
    top: 0 !important;
    display: none;
    margin: 0px !important;
    padding: 0px !important;
    border: solid 0px !important;
    transform: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
  .wsmenu-submenu-sub-sub li {
    margin: 0px 0px 0px 0px !important;
  }
  .wsmenu-submenu-sub-sub a {
    display: block;
    color: #000;
    padding: 10px 25px;
    background: #000 !important;
    border-bottom: solid 1px #ccc;
    font-weight: normal;
  }
  .wsmenu-submenu-sub-sub li a.active {
    color: #000 !important;
  }
  .wsmenu-submenu-sub-sub li:hover>a {
    background-color: #606060 !important;
    color: #fff;
  }
  .wsmenu .wsmenu-list {
    display: block !important;
  }
  .wsmenu .wsmenu-list>li {
    width: 100%;
    display: block;
    float: none;
    border-right: none;
    background-color: transparent;
    position: relative;
    white-space: inherit;
  }
  .wsmenu>.wsmenu-list>li>a {
    padding: 9px 32px 9px 12px;
    font-size: 14px;
    text-align: left;
    border-right: solid 0px;
    background-color: transparent;
    color: #666666;
    line-height: 25px;
    border-bottom: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.13);
    position: static;
  }
  .wsmenu .wsmenu-list li a .arrow:after {
    display: none !important;
  }
  .wsmenu .wsmenu-list li ul li a .fa.fa-caret-right {
    font-size: 12px !important;
    color: #8E8E8E;
  }
  .mobile-sub .wsmenu-submenu {
    transform: none !important;
    opacity: 1 !important;
    display: none;
    position: relative !important;
    top: 0px;
    background-color: #fff;
    border: solid 1px #ccc;
    padding: 0px;
    visibility: visible !important;
  }
  .mobile-sub .wsmenu-submenu li a {
    line-height: 20px;
    height: 36px;
    background-color: #e7e7e7 !important;
    font-size: 13px !important;
    padding: 8px 0px 8px 18px;
    color: #8E8E8E;
  }
  .mobile-sub .wsmenu-submenu li a:hover {
    background-color: #e7e7e7 !important;
    color: #666666;
    text-decoration: underline;
  }
  .mobile-sub .wsmenu-submenu li:hover>a {
    background-color: #e7e7e7 !important;
    color: #666666;
  }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li a {
    line-height: 20px;
    height: 36px;
    background-color: #e7e7e7 !important;
    border-bottom: none;
    padding-left: 28px;
  }
  .mobile-sub .wsmenu-submenu li .wsmenu-submenu-sub li .wsmenu-submenu-sub-sub li a {
    line-height: 20px;
    height: 36px;
    background-color: #e7e7e7 !important;
    border-bottom: none !important;
    padding-left: 38px;
    color: #8e8e8e;
  }
  .wsmenu-list>li.socialicon a {
    width: 100%;
  }
  .wsmenu-list>li.carticon em.roundpoint {
    left: 22px;
  }
  .halfdiv .title {
    padding-left: 0px;
  }
  .halfdiv {
    width: 100%;
    display: block;
  }
  .halfdiv03 .title {
    padding-left: 15px;
  }
  .halfdiv03 {
    width: 100%;
  }
  .halfmenu {
    width: 100%;
    display: block;
  }
  .menu_form {
    padding: 10px 10px 63px 10px;
    background-color: #e7e7e7;
  }
  .menu_form input[type="button"] {
    width: 46%;
  }
  .menu_form input[type="submit"] {
    width: 46%;
  }
  .menu_form textarea {
    min-height: 100px;
  }
  .wsmenu-list li>.wsmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
  }
  .wsmenu-list li:hover>.wsmenu-submenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
  }
  .wsmenu-submenu li>.wsmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
  }
  .wsmenu-submenu li:hover>.wsmenu-submenu-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .wsmenu-submenu-sub li>.wsmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .wsmenu-submenu-sub li:hover>.wsmenu-submenu-sub-sub {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .wsmenu-list li>.megamenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .wsmenu-list li:hover>.megamenu {
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
    -o-transform: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
}

/*--------------------------------------------------------------------------
|  05 Additional Themes (Optionnal)
--------------------------------------------------------------------------*/

.gry {
  background: #424242;
  color: #fff;
}

.orange {
  background: #ff670f;
  color: #fff;
}

.blue {
  background: #4A89DC;
  color: #fff;
}

.green {
  background: #74a52e;
  color: #fff;
}

.red {
  background: #DA4B38;
  color: #fff;
}

.yellow {
  background: #F6BB42;
  color: #fff;
}

.purple {
  background: #967ADC;
  color: #fff;
}

.pink {
  background: #ee1289;
  color: #fff;
}

.chocolate {
  background: #934915;
  color: #fff;
}

.gry>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.gry>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.gry>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.gry>.wsmenu>.wsmenu-list>li:hover>a {
  background: #424242 !important;
  color: #fff !important;
}

.gry>.wsmenu>.wsmenu-list>li>a:hover {
  background: #424242 !important;
  color: #fff !important;
}

.gry>.wsmenu>.wsmenu-list>li>a.active {
  background: #424242 !important;
  color: #fff !important;
}

.gry>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.gry>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.gry>.wsmenu>.wsmenu-submenu>li>a:hover {
  background: #424242;
  border-radius: 0px !important;
  color: #fff;
}

.gry .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.gry .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.gry .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.orange>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.orange>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.orange>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.orange>.wsmenu>.wsmenu-list>li:hover>a {
  background: #ff670f !important;
  color: #fff !important;
}

.orange>.wsmenu>.wsmenu-list>li>a:hover {
  background: #ff670f !important;
  color: #fff !important;
}

.orange>.wsmenu>.wsmenu-list>li>a.active {
  background: #ff670f !important;
  color: #fff !important;
}

.orange>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.orange>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.orange>.wsmenu>.wsmenu-submenu>li>a:hover {
  background: #ff670f;
  border-radius: 0px !important;
  color: #fff;
}

.orange .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.orange .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.orange .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.blue>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.blue>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.blue>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.blue>.wsmenu>.wsmenu-list>li:hover>a {
  background: #4A89DC !important;
  color: #fff !important;
}

.blue>.wsmenu>.wsmenu-list>li>a:hover {
  background: #4A89DC !important;
  color: #fff !important;
}

.blue>.wsmenu>.wsmenu-list>li>a.active {
  background: #4A89DC !important;
  color: #fff !important;
}

.blue>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.blue>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.blue>.wsmenu>.wsmenu-submenu li>a:hover {
  background: #4A89DC !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.blue .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.blue .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.blue .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.green>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.green>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.green>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.green>.wsmenu>.wsmenu-list>li:hover>a {
  background: #74a52e !important;
  color: #fff !important;
}

.green>.wsmenu>.wsmenu-list>li>a:hover {
  background: #74a52e !important;
  color: #fff !important;
}

.green>.wsmenu>.wsmenu-list>li>a.active {
  background: #74a52e !important;
  color: #fff !important;
}

.green>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.green>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.green>.wsmenu>.wsmenu-submenu>li>a:hover {
  background: #74a52e !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.green .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.green .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.green .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.red>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.red>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.red>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.red>.wsmenu>.wsmenu-list>li:hover>a {
  background: #DA4B38 !important;
  color: #fff !important;
}

.red>.wsmenu>.wsmenu-list>li>a:hover {
  background: #DA4B38 !important;
  color: #fff !important;
}

.red>.wsmenu>.wsmenu-list>li>a.active {
  background: #DA4B38 !important;
  color: #fff !important;
}

.red>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.red>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.red>.wsmenu-submenu>li>a:hover {
  background: #DA4B38 !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.red .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.red .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.red .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.yellow>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.yellow>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.yellow>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.yellow>.wsmenu>.wsmenu-list>li:hover>a {
  background: #F6BB42 !important;
  color: #fff !important;
}

.yellow>.wsmenu>.wsmenu-list>li>a:hover {
  background: #F6BB42 !important;
  color: #fff !important;
}

.yellow>.wsmenu>.wsmenu-list>li>a.active {
  background: #F6BB42 !important;
  color: #fff !important;
}

.yellow>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.yellow>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.yellow>.wsmenu>.wsmenu-submenu>li>a:hover {
  background: #F6BB42 !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.yellow .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.yellow .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.yellow .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.yellow>.wsmenu>.wsmenu-list li a .fa {
  color: #424242 !important;
}

.purple>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.purple>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.purple>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.purple>.wsmenu>.wsmenu-list>li:hover>a {
  background: #967ADC !important;
  color: #fff !important;
}

.purple>.wsmenu>.wsmenu-list>li>a:hover {
  background: #967ADC !important;
  color: #fff !important;
}

.purple>.wsmenu>.wsmenu-list>li>a.active {
  background: #967ADC !important;
  color: #fff !important;
}

.purple>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.purple>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.purple>.wsmenu>.wsmenu-submenu>li>a:hover {
  background: #967ADC !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.purple .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.purple .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.purple .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.pink>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.pink>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.pink>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.pink>.wsmenu>.wsmenu-list>li:hover>a {
  background: #ee1289 !important;
  color: #fff !important;
}

.pink>.wsmenu>.wsmenu-list>li>a:hover {
  background: #ee1289 !important;
  color: #fff !important;
}

.pink>.wsmenu>.wsmenu-list>li>a.active {
  background: #ee1289 !important;
  color: #fff !important;
}

.pink>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.pink>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.pink>.wsmenu>.wsmenu-submenu>li>a:hover {
  background: #ee1289 !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.pink .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.pink .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.pink .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.chocolate>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.chocolate>.wsmenu>.wsmenu-list>li>a {
  background-color: #fff !important;
}

.chocolate>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.chocolate>.wsmenu>.wsmenu-list>li:hover>a {
  background: #934915 !important;
  color: #fff !important;
}

.chocolate>.wsmenu>.wsmenu-list>li a:hover {
  background: #934915 !important;
  color: #fff !important;
}

.chocolate>.wsmenu>.wsmenu-list>li a.active {
  background: #934915 !important;
  color: #fff !important;
}

.chocolate>.wsmenu>.wsmenu-list>li>a.active .fa {
  color: #fff !important;
}

.chocolate>.wsmenu>.wsmenu-list>li>a:hover .fa {
  color: #fff !important;
}

.chocolate>.wsmenu>.wsmenu-submenu>li a:hover {
  background: #934915 !important;
  border-radius: 0px !important;
  color: #fff !important;
}

.chocolate .wsmenu>.wsmenu-list li a:hover .arrow:after {
  border-top-color: #fff;
}

.chocolate .wsmenu>.wsmenu-list li a.active .arrow:after {
  border-top-color: #fff;
}

.chocolate .wsmenu>.wsmenu-list li:hover>a .arrow:after {
  border-top-color: #fff;
}

.tranbg {
  background-color: transparent !important;
}

.whitebg {
  background-color: #fff !important;
}

.whitebg:hover {
  color: #000 !important;
}

.pm_buttoncolor .wsmenu-list>li>a .fa {
  color: #fff !important;
}

.pm_buttoncolor03 .wsmenu-list>li:hover>a .fa {
  color: #fff !important;
}

.pm_buttoncolor .wsmenu-list li a .arrow:after {
  border-top-color: #fff;
}

.pm_buttoncolor03 {
  background-color: #fff !important;
}

.pm_buttoncolor03 .smllogo a {
  height: 48px;
  background: #565656;
  background: -moz-linear-gradient(top, #565656 0%, #424242 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #565656), color-stop(100%, #424242));
  background: -webkit-linear-gradient(top, #565656 0%, #424242 100%);
  background: -o-linear-gradient(top, #565656 0%, #424242 100%);
  background: -ms-linear-gradient(top, #565656 0%, #424242 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#424242', GradientType=0);
}

.pm_buttoncolor02 .smllogo a {
  height: 48px;
  background: #565656;
  background: -moz-linear-gradient(top, #565656 0%, #424242 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #565656), color-stop(100%, #424242));
  background: -webkit-linear-gradient(top, #565656 0%, #424242 100%);
  background: -o-linear-gradient(top, #565656 0%, #424242 100%);
  background: -ms-linear-gradient(top, #565656 0%, #424242 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#424242', GradientType=0);
}

.blue-grdt {
  background: #5999ee;
  background: -moz-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5999ee), color-stop(100%, #4a89dc));
  background: -webkit-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  background: -o-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  background: -ms-linear-gradient(top, #5999ee 0%, #4a89dc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5999ee', endColorstr='#4a89dc', GradientType=0);
  color: #fff;
}

.gry-grdt {
  background: #565656;
  background: -moz-linear-gradient(top, #565656 0%, #424242 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #565656), color-stop(100%, #424242));
  background: -webkit-linear-gradient(top, #565656 0%, #424242 100%);
  background: -o-linear-gradient(top, #565656 0%, #424242 100%);
  background: -ms-linear-gradient(top, #565656 0%, #424242 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#565656', endColorstr='#424242', GradientType=0);
  color: #fff;
}

.green-grdt {
  background: #86ba3d;
  background: -moz-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #86ba3d), color-stop(100%, #74a52e));
  background: -webkit-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  background: -o-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  background: -ms-linear-gradient(top, #86ba3d 0%, #74a52e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#86ba3d', endColorstr='#74a52e', GradientType=0);
  color: #fff;
}

.red-grdt {
  background: #f05c48;
  background: -moz-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f05c48), color-stop(100%, #da4b38));
  background: -webkit-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  background: -o-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  background: -ms-linear-gradient(top, #f05c48 0%, #da4b38 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f05c48', endColorstr='#da4b38', GradientType=0);
  color: #fff;
}

.orange-grdt {
  background: #fc7d33;
  background: -moz-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fc7d33), color-stop(100%, #ff670f));
  background: -webkit-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  background: -o-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  background: -ms-linear-gradient(top, #fc7d33 0%, #ff670f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d33', endColorstr='#ff670f', GradientType=0);
  color: #fff;
}

.yellow-grdt {
  background: #ffcd67;
  background: -moz-linear-gradient(top, #ffcd67 0%, #f6bb42 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffcd67), color-stop(100%, #f6bb42));
  background: -webkit-linear-gradient(top, #ffcd67 0%, #ff670f 100%);
  background: -o-linear-gradient(top, #ffcd67 0%, #f6bb42 100%);
  background: -ms-linear-gradient(top, #ffcd67 0%, #f6bb42 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcd67', endColorstr='#f6bb42', GradientType=0);
  color: #fff;
}

.purple-grdt {
  background: #a98ded;
  background: -moz-linear-gradient(top, #a98ded 0%, #967adc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #a98ded), color-stop(100%, #967adc));
  background: -webkit-linear-gradient(top, #a98ded 0%, #967adc 100%);
  background: -o-linear-gradient(top, #a98ded 0%, #967adc 100%);
  background: -ms-linear-gradient(top, #a98ded 0%, #967adc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a98ded', endColorstr='#967adc', GradientType=0);
  color: #fff;
}

.pink-grdt {
  background: #fb3ea4;
  background: -moz-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fb3ea4), color-stop(100%, #f21b8f));
  background: -webkit-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  background: -o-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  background: -ms-linear-gradient(top, #fb3ea4 0%, #f21b8f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb3ea4', endColorstr='#f21b8f', GradientType=0);
  color: #fff;
}

.tranbg>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
  border-right: none;
  border-left: none;
  background-color: transparent;
}

.tranbg>.wsmenu>.wsmenu-list>li>a:hover {
  background-color: transparent !important;
}

.tranbg>.wsmenu>.wsmenu-list>li>a {
  background-color: transparent !important;
}

.tranbg>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #898d91 !important;
}

.tranbg>.wsmenu {
  border: none;
}

.whitebg>.wsmenu>.wsmenu-list>li>a {
  color: #424242;
}

.whitebg>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #E5E5E5 !important;
}

.red-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.red-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

.gry-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.gry-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

.blue-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.blue-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

.green-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.green-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

.orange-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.orange-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

.yellow-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #424242 !important;
}

.yellow-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #424242 !important;
}

.yellow-grdt>.wsmenu>.wsmenu-list li a .fa {
  color: #424242 !important;
}

.purple-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.purple-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

.pink-grdt>.wsmenu>.wsmenu-list>li>a {
  color: #fff !important;
}

.pink-grdt>.wsmenu>.wsmenu-list>li>a>.arrow:after {
  border-top-color: #fff !important;
}

@media only screen and (max-width: 780px) {
  .tranbg {
    background-color: #fff !important;
  }
  .red-grdt>.wsmenu>.wsmenu-list>li>a>.fa {
    color: #fff !important;
  }
  .blue-grdt>.wsmenu>.wsmenu-list>li>a>.fa {
    color: #fff !important;
  }
  .orange-grdt>.wsmenu>.wsmenu-list>li>a>.fa {
    color: #fff !important;
  }
  .green-grdt>.wsmenu>.wsmenu-list>li>a>.fa {
    color: #fff !important;
  }
  .purple-grdt>.wsmenu>.wsmenu-list>li>a>.fa {
    color: #fff !important;
  }
  .pink-grdt>.wsmenu>.wsmenu-list>li>a>.fa {
    color: #fff !important;
  }
  .gry>.wsmenu-submenu>li:hover>a {
    background-color: #424242 !important;
    color: #fff;
  }
  .gry>.wsmenu .wsmenu-list>li>a.active {
    color: #fff;
    background-color: #424242;
  }
  .gry>.wsmenu .wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #424242;
  }
  .orange>.wsmenu-submenu>li:hover>a {
    background-color: #ff670f !important;
    color: #fff;
  }
  .orange>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #ff670f;
  }
  .orange>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #ff670f;
  }
  .blue>.wsmenu-submenu>li:hover>a {
    background-color: #4A89DC !important;
    color: #fff;
  }
  .blue>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #4A89DC;
  }
  .blue>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #4A89DC;
  }
  .green>.wsmenu-submenu>li:hover>a {
    background-color: #74a52e !important;
    color: #fff;
  }
  .green>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #74a52e;
  }
  .green>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #74a52e;
  }
  .red>.wsmenu-submenu>li:hover>a {
    background-color: #DA4B38 !important;
    color: #fff;
  }
  .red>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #DA4B38;
  }
  .red>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #DA4B38;
  }
  .yellow>.wsmenu-submenu>li:hover>a {
    background-color: #F6BB42 !important;
    color: #fff;
  }
  .yellow>.wsmenu .wsmenu-list>li>a.active {
    color: #fff;
    background-color: #F6BB42;
  }
  .yellow>.wsmenu .wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #F6BB42;
  }
  .purple>.wsmenu-submenu>li:hover>a {
    background-color: #967ADC !important;
    color: #fff;
  }
  .purple>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #967ADC;
  }
  .purple>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #967ADC;
  }
  .pink>.wsmenu-submenu>li:hover>a {
    background-color: #ee1289 !important;
    color: #fff;
  }
  .pink>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #ee1289;
  }
  .pink>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #ee1289;
  }
  .chocolate>.wsmenu-submenu>li:hover>a {
    background-color: #934915 !important;
    color: #fff;
  }
  .chocolate>.wsmenu>.wsmenu-list>li>a.active {
    color: #fff;
    background-color: #934915;
  }
  .chocolate>.wsmenu>.wsmenu-list>li>a:hover {
    color: #fff;
    background-color: #934915;
  }
}