.color_brand_blue {
	background-color: #15488a;
	color: white;
	border-color:#15488a;
  }
  .color_brand_blue,
  .color_brand_blue_hover:hover,
  [class*="button_type_"].color_brand_blue_hover:hover,
  [class*="button_type_"].color_brand_blue:not(.transparent):hover{
	  color:#15488a;
	  border-color:#15488a ;
  }
.top_phone_number{
	font-size: 1.5em;
	line-height: 1.5em;
}
.top_phone_icon{
	line-height: 1.6em !important;
	font-size: 1.5em;
	margin-right: 15px;
}

.page_title_header {
	margin-bottom:10px;
}


.color_pink,
.color_pink_hover:hover,
.color_pink_hover:hover [class*="color_"],
[class*="button_type_"].color_pink:not(.transparent):hover,
.dropdown_2_container.shoppingcart:hover > [class*="icon_wrap_"] > [class|="icon"]{
	color: #15488a;
}

.border_color_pink,
[class*="icon_wrap_"].color_pink,
.color_pink_hover:hover [class*="icon_wrap_"],
[class*="button_type_"].color_pink,
.p_table.bg_color_pink_hover:hover,
.p_table.bg_color_pink_hover.active,
[class*="button_type_"].color_pink_hover:not(.color_light):hover,
[class*="icon_wrap_"].color_pink_hover:hover,
.dropdown_2_container.shoppingcart:hover > [class*="icon_wrap_"]{
	border-color: #15488a;
}

.bg_color_pink,
.ui-slider-range,
.bg_color_pink_hover:hover,
.p_table.bg_color_pink_hover.active,
.animation_fill.color_pink:before,
[class*="button_type_"].transparent.color_pink:hover,
[class*="button_type_"].color_pink:not(.transparent){
	background:#15488a;
}


a,a:not([class*="_hover"]):hover,
.main_menu .current > a,
.md_no-touch .main_menu li:hover > a,
.md_touch .main_menu .touch_open_sub > a,
.main_menu > .current_click > a,
.sub_menu .current_click > a,
blockquote.type_2:before,
.thumbnails_container > ul > .active article > p,
.thumbnails_container > ul > li:hover article > p,
blockquote.type_2 > p:last-of-type:after,
.main_menu li:hover > a i[class|="icon"],
a:not([class*="_hover"]):hover [class*="icon_wrap"][class*="color_"],
a:not([class*="_hover"]):hover [class|="icon"][class*="color_"],
.categories_list li:hover > a,.side_menu_list li:hover > a,.categories_list li:hover > a > *,.side_menu_list li:hover > a > *,
.color_scheme,.color_scheme_hover:hover,.color_scheme_hover:hover [class*="color_"],
.dropdown_2_container.login:hover > [class*="icon_wrap_"] > [class|="icon"]{
	color: #15488a;
}

.tabs_nav .active a, .accordion_item.active, .owl-pagination > .active, .p_table.bg_color_blue_hover:hover, .p_table.bg_color_blue_hover.active, .dropdown .sub_menu, .color_scheme_hover:not(.color_light):hover, .color_scheme_hover:hover [class*="color_"], .dropdown_2_container.login:hover > [class*="icon_wrap_"] {
border-color: #15488a;
}


.dropdown_2_container.shoppingcart .dropdown_2 {
	border-top-color: #15488a;
}

.dropdown_2_container.shoppingcart .dropdown_2:before {
border-bottom-color: #15488a;
}


.border_color_purple, .paginations .active a, .paginations .active:last-child a, .paginations li a:hover, .paginations li:last-child a:hover, .step:hover .step_counter, [class*="icon_wrap_"].color_purple, .color_purple [class*="icon_wrap_"], [class*="button_type"].color_purple, .bg_color_purple_hover:hover, .p_table.bg_color_purple_hover:hover, .p_table.bg_color_purple_hover.active, [class*="icon_wrap_"].color_purple_hover:hover, [class*="button_type"].color_purple_hover:not(.color_light):hover {
border-color: #15488a;
}


.checkout_video:hover .video_button i[class|="icon"], .color_purple, .step:hover h3, blockquote.type_3:before, blockquote.type_3 > p:last-of-type:after, .color_purple_hover:hover, .category_link:hover .category_title, .color_purple [class*="icon_wrap_"], [class*="button_type_"]:not(.transparent).color_purple:hover {
color: #15488a;
}

.bg_color_purple, .paginations .active a, .paginations li a:hover, .step:hover .step_counter, .title_counter_type:before, .bg_color_purple_hover:hover, .animation_fill.color_purple:before, .p_table.bg_color_purple_hover.active, [class*="button_type_"].transparent.color_purple:hover, [class*="button_type_"].color_purple:not(.transparent) {
background: #15488a;
}

.dropdown_2_container.login .dropdown_2 {
border-top-color: #15488a;
}

.dropdown_2_container.login .dropdown_2:before {
border-bottom-color: #15488a;
}

.main_menu > li > a, #menu_button.color_blue, [class*="icon_wrap_"].color_blue, [class*="button_type_"].color_blue, [class*="icon_wrap_"].color_blue_hover:hover, [class*="button_type_"].color_blue_hover:hover {
border-color: #15488a;
}

[class*="button_type_"].transparent.color_blue:hover, [class*="button_type_"].color_blue:not(.transparent), .animation_fill.color_blue:before {
background: #15488a;
}

[class*="button_type_"].color_blue:not(.transparent):not(.disable_btn):hover{
	color: #fff !important;
	background: #8E9296;
	border-color: #8E9296;
}

.color_blue, .color_blue_hover:hover, [class*="button_type_"].color_blue:not(.transparent):hover {
color: #15488a;
}

.accordion_item dt {
	padding: 5px 20px 10px;
	cursor: pointer;
	width: 50px;
	float: right;
}

.category_caption{
	padding: 7px 7px 4px 7px;
	font-size: 11px;
}

.category_tile_image{
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	transition: .2s ease;
}

.category_tile_image:hover{
	transition: .2s ease;
	opacity: 0.3;
}

.accordion_item dd {
padding: 7px 20px 10px;
}

.category_tile_block:hover .category_name{
	color:#15488a;
}

.category_tile_block{
	border:1px solid #dbdddd;
	border-radius: 0;
	margin-bottom:6px;
	background-size:100% auto !important;

}

@media (max-width:550px){
	.category_tile_block {
		background-size:100% 100% !important;
		height:200px !important;
		background-position: center center !important;
	}
}

@media only screen and (max-width:380px){
	.category_tile_block {
		background-size:100% auto !important;
		height:150px !important;
		background-position: center center  !important;
	}
	#mobile-brand-logo-xs{
		display: block;
	}
	#mobile-brand-logo-sm{
		display: none;
	}
	.flex-bigger {
		margin-left: 10px !important;
	}
}

.hover_fill {
	transition:0.6s;
}

.category_tile_block:hover .hover_fill{
	/* background-image: none !important; */
	background-color: #15488aaf !important;
	transition:0.2s;
	border-radius: 2px;
}

.nl_align{
	float: left;
	margin-right: 10px;
	line-height: 28px;
}

.payment_icon img{
	width:50px;
	margin-top: -4px;
}

.social_icons li{
	float: right;
}

.social_icons > li:last-child {
margin-right: 15px;
}

.message_height{
	height: 40px;
}

.main_side_menu{
	border: 1px solid #bdc3c7;
	border-radius: 4px;
}

.accordion_item{
	border:none;
	border-bottom: 1px solid #bdc3c7;
	border-radius: 0px;
}
.accordion_item:last-child{
	border:none;
}

.accordion_item.active{
	border-color:#bdc3c7;
}

.bg_gradient, .divider_type_2, .gradient_line, #qLbar {
	background: #15488a;
}

body::-webkit-scrollbar{
	width:10px;
	background:#eee;
}
body::-webkit-scrollbar-thumb{
	background:#aaa;
}

::-webkit-scrollbar{
	width:10px;
	background:#eee;
}
::-webkit-scrollbar-thumb{
	background:#aaa;
}

.wsmain::-webkit-scrollbar{
	width:10px;
	background:#eee;
}
.wsmain::-webkit-scrollbar-thumb{
	background:#aaa;
}

.social_icon:hover i {
	color:#fff;
}

.translucent_bg, .translucent_bg.image_fixed:before {
	background-color: rgba(42, 118, 206, 01);
}

.top_banner_bg{/* background:url("/images/top_banner_placeholder.jpg") no-repeat; */}

.aboutus_bg{
	background: url("/images/aboutus_stat_placeholder.jpg") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
	background-attachment: fixed;
	background-size: cover;
}

blockquote{
	word-break:normal;
	/*text-align:justify;*/
	text-align:left;
}

.f_left_imp{
	float: left !important;
}

.contact_page_address_titles{
	margin-bottom: 15px;
}

.contact_page_address_subtitles{
	margin-bottom: 10px;
}

.contact_page_map{
	border:1px solid #ddd; 
	border-radius:10px;
	padding:8px;
}

.has-error .help-block,.has-error .control-label,.has-error .radio,.has-error .checkbox,.has-error .radio-inline,.has-error .checkbox-inline{
	color:#a94442
	}
.has-error .form-control{
	border: 1px solid #a94442;
}
.has-error .form-control:focus{
	border: 2px solid #a94442;
	}
.has-error .input-group-addon{
	color:#a94442;
	border-color:#a94442;
	background-color:#f2dede
	}
.has-error .form-control-feedback{
	color:#a94442
}

#cf_capcha-error{
	/*margin-left:10px;*/
}

.contact_feedback{
	margin-top:10px;
	border-radius: 6px;
	margin-bottom: -25px;
}

.category_tile_image{/*
	 -webkit-transform: scale(1, 1);
        -webkit-transition-duration: 500ms;
        -webkit-transition-timing-function: ease-out;
        -moz-transform: scale(1, 1);
        -moz-transition-duration: 500ms;
        -moz-transition-timing-function: ease-out;
        -ms-transform: scale(1, 1);
        -ms-transition-duration: 500ms;
        -ms-transition-timing-function: ease-out;*/

}

.category_tile_image:hover{/*
	-webkit-transform: scale(1.2, 1.2);
        -webkit-transition-duration: 500ms;
        -webkit-transition-timing-function: ease-out;
        -moz-transform: scale(1.2, 1.2);
        -moz-transition-duration: 500ms;
        -moz-transition-timing-function: ease-out;
        -ms-transform: scale(1.2, 1.2);
        -ms-transition-duration: 500ms;
        -ms-transition-timing-function: ease-out;*/

}

.overflow_hidden{
	overflow: hidden;
}

.clear_padding{
	padding-left:0px;
	padding-right: 0px;
}

.on_top{
	z-index: 50;
}

.top_welcome_message{
	color:#bdc3c7;
	margin-top: 5px;
}

.select_list{
	height: 210px;
	overflow: scroll;
	overflow-x: hidden;
}

.fe_width_1 {
	width: 130px;
}

.login_small_loader,.top_login_small_loader{
	margin-left:10px;
	display: none;
}

.disable_btn,.disable_btn:hover{
	background-color:#eee !important;
	color:#aaa !important;
	cursor:not-allowed !important;
}

.category_rotator{
	height: 488px !important;
}


.fp_item:hover .fp_images > img:first-child{
	opacity:1;
}

.product_image_border_box{
	border: 1px solid #e1e4e6;
	border-radius: 6px;
	padding: 0px;
}

li.disabled:hover a{
	pointer-events: none;
	cursor: not-allowed;	
}

li.disabled:hover{
	cursor: not-allowed;
}

.number_of_items_on_page{
	height: 140px;
}
.rating_stars{
	color:#f1c40f;
	font-size:8px;
}

.reviews{
	line-height: 15px;
}
.per_week_subtxt{
	font-size: 14px;
	font-weight: 500;
}

.t_md_align_r{
		text-align: right;
	}
.quantity input[type="text"]{
	width: 60px;
}

.sorting_options_list{
	height: 255px;
}

hr {
 margin-top: 0px; 
 margin-bottom: 0px;
}
a:hover{
	text-decoration: none;
}

h1, .pt_price, #countdown dt {
	font-size: 2.25em;
}

.page_title.relative,.page_title_2.relative{
	z-index:0;
}

.search_buttons.relative{
	z-index: 15;
}


.discount_price{
	font-size: 14px;
}

.tabs_nav .active a{
	background-color: #15488a;
	border-color: #15488a;
}

.related_articles_title{
	font-size: 20px;
}

#description h2,.description h2{
	font-size: 1.5em;
	margin-bottom:10px;
	margin-top:15px;
}

#description h3,.description h3{
	font-size: 1.3em;
	margin-bottom:10px;
	margin-top:15px;
}

#description p,.description p{
	margin-bottom: 10px;
}

#description{
	font-family: droid_sansregular;
}


.add_to_cart_btn_fix{
	float: right;
	margin-top:20px;
}

.price_breaks_table{
	font-size: 13px;
}
/*
.flex-viewport{
	min-height: 350px;
}*/

.hand_hover{
	cursor: pointer;
}

.owl-carousel{
	display: block;
}

.flexslider .flex-direction-nav a {
	bottom: 10px;
}


.fs_caption {
	padding: 20px 20px 17px;
}

.fs_caption h3{
	font-size: 1.9em;
}

a.on_offer.active{
	background-color:#d41010;
	border-color:#d41010;
}

.tabs_nav .active + li a {
	border-left: none;
}

.discounted_price_red{
	color:#d41010;
	display: block;
}

sup.discounted_price_red{
	margin-top: 15px;
}

.fs_caption {
	background: rgba(255,255,255,.45);
	top: 20px;
}

.number_of_gallery_images_on_page{
	height: 110px;
}

.project_description_up {
	padding: 5px 0 8px;
}

.portfolio_item {
	margin: 0px 0;
}
.gallery_image_name{
	font-size: 1em;
}

.home_page_slider_btn:hover{
	background: none !important;
}

.add_to_cart_controls,.add_to_cart_controls_ver {
	width: 35px;
	height: 35px;
	float: right;
	border: 1px solid #eee;
	border-radius: 6px;
	text-align: center;
	padding: 4px 8px;
	color: #ddd;
	webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	-webkit-animation-duration: .5s;
	-moz-animation-duration: .5s;
	-o-animation-duration: .5s;
	animation-duration: .5s;
}

.add_to_cart_controls.ok_action,.add_to_cart_controls_ver.ok_action{
	border-color: #3c763d;
	margin-right: 0px;
	color:#3c763d;
}

.add_to_cart_controls.cancel_action,.add_to_cart_controls_ver.cancel_action{
	border-color: #a94442;
	color:#a94442;
	margin-left: 10px;
}

.add_to_cart_controls.ok_action:hover,.add_to_cart_controls_ver.ok_action:hover{
	background-color: #3c763d;
	color:#fff;
	cursor: pointer;
}

.add_to_cart_controls.cancel_action:hover,.add_to_cart_controls_ver.cancel_action:hover{
	background-color: #a94442;
	color:#fff;
	cursor: pointer;
}

.products_options{
	width:auto; 
	height:auto;
}

.auto_width{
	width:auto;
}

.cart_color_options{
	display: none;
}
.product_quantity{
	float: left;
}

.qty_error,.color_option_error,.terms_error,.buy_qty_error{
	color: #d41010;
	font-size: 13px;
	font-style: italic;
	display: none;
	float: right;
	clear:both;
}

#fade {
     display:none;
    position: fixed; 
    left: 0%; 
    top: 0%; 
    text-align: center;
    background-color: #eee;
    -moz-opacity: 0.7; 
    opacity: .70;
    filter: alpha(opacity=70);
    width: 100%;
    height: 100%;
    z-index: 999; 
   }

 .cart_empty_message{
 	margin-bottom:20px;
 }

 .alert_box.success {
	background: #d5f5e3;
	color: #27ae60;
}

 .alert_box.danger {
	background: #f2dede;
	color: #a94442;
}

.alert_box{
	display: none;
	position: fixed;
	width: 100%;
	z-index: 999;
}

.added_items_list{
	text-align: left;
}

.top_cart_color_option{
	font-style: italic; 
}

.added_to_cart_modal_body{
	font-size: 1.2em;
	text-align: left;
}

#added_to_cart_model{
	margin-top: 10px;
}

.modal-backdrop {
	z-index: 1040;
}

.modal {
	z-index: 1050;
}

.vertical_ver_clear{
	clear:left;
}

.add_to_cart_controls_ver{
	float: right;
	text-align: center;
	padding: 4px 8px;
}

.add_to_cart_controls_ver.ok_action{
	clear:left;
	margin-top:10px;
}

.add_to_cart_controls_ver.cancel_action{
	margin-top:10px;
}

.total_price .price {
	min-width: 0px; 
}

.bootstrap-datetimepicker-widget table td,.bootstrap-datetimepicker-widget table th{
	border:none;
}

.bootstrap-datetimepicker-widget table tr:hover{
	background:none;
}

.cart_highlight{
	font-weight: bold;
	color:#088f64;
}

.weekly_discount_table{
	width: 100%;
}

.weekly_discount_table td{
	padding:5px 10px;
	font-weight:normal;
}

.icon-help:hover{
	color:#fff;
}
.modal-open {
	overflow: hidden;
	margin-right: -10px;
	margin-left: 0px;
}

.cart_update_needed_error,.ordering_limitations_error{
	color:#d41010;
	font-style: italic;
	display: none;
}
.ordering_limitations_error{
	display: block;
}

#no_of_hire_days{
	font-size: 13px;
}

#details_del_date,#details_col_date{
	cursor: pointer;
}

.product_code_subtitle{
	font-style: italic;
	font-size: 13px;
}

.product_item_rating_text{
	font-size: 13px;
}

.open_product{
	cursor: pointer;
}

.long_term_hire_table_product_page tr td,.long_term_hire_table_product_page tr th{
	font-size:0.7em;
	padding:3px;
}

.promo{
	color:#d41010;
}

header .color_grey_light[class*="icon_wrap"]{
	border-color:#A8ACAE !important;
}

header .color_grey_light_2{
	color: #A3A6A8 !important;
}

header .color_grey_light[class*="icon_wrap"]:hover{
	border-color:#15488a !important;
}

header .icon-lock:hover,header .icon-basket:hover,header .color_grey_light_2:hover{
	color:#15488a !important;
}

.main_menu.type_2 > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
}

.dim_bg{
	background-color:#eee;
}

select{
	border: 1px solid #e1e4e6;
	border-radius: 4px;
	padding: 10px 2px;
	width: 100%;
	color:#606366;
	font-weight:normal;
}
select:hover{
	cursor: pointer;
}

.date_link:hover{
	background-color:#ecf0f1;
}

.paginations li a {
	width: 27px;
	height: 27px;
}

.collection_option,.delivery_option{
	font-size: 0.9em;
}

.col_option_desc,.del_option_desc {
	font-size: 0.9em;
	font-weight: 400;
	font-style: italic;
	color: #15488a;
}

input:not([type="submit"]) {
	font-style: normal; 
}

.main_menu_links,.article_menu_links{
	padding: 3px 21px 4px;
	cursor: pointer;
	float: left;
}
.accordion_item:not([class*="active"]) .article_menu_links a{
	color:rgb(96, 99, 102) !important;
}
.accordion_link {
	margin-top:10px;
}

.open_wireframe:hover{
	cursor: pointer;
}

.r_slider .button_type_3:hover{
		background-color:#fff !important;
	}

.winners_table tr:nth-child(even){
	background-color:#E9EDF2;
}

.winners_table tr:nth-child(even):hover{
	background-color:#E9EDF2;
}

#crafty_postcode_result_display{
	display: none;
	margin-bottom:8px;
}


.product_image_border_box:hover .quick_view_overlay{
	transform: translate(0%,0%) scale(1);
	opacity:0.7;
}

.side_thumb:hover{
	cursor: pointer;
}

.slides:after {content: "\0020"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}

.discount_code_error{
	clear:both;
	display:block;
	width:100%;
}


.crossed_price_breaks{
	text-decoration: line-through;
	font-size:0.9em;
}

.price_breaks_red{
	color:#d41010;
}

.competition_entries_trigger:hover{
	cursor: pointer;
}

[class*="_isotope_container"].four_columns .portfolio_item{
	border-left: 5px solid transparent;  border-right: 5px solid transparent;
}


[class*="_isotope_container"].three_columns .portfolio_item, [class*="_isotope_container"].three_columns .blog_isotope_item, [class*="_isotope_container"].three_columns .shop_isotope_item:not(.list_view) {
border-left: 5px solid transparent;
border-right: 5px solid transparent;
}


[class*="_isotope_container"].four_columns .portfolio_item, [class*="_isotope_container"].four_columns .blog_isotope_item, [class*="_isotope_container"].four_columns .shop_isotope_item:not(.list_view) {
border-left: 5px solid transparent;
border-right: 5px solid transparent;
}


.direct_debit_section{
	border:1px solid #bdc3c7;
	border-radius: 6px;
	padding: 10px;
}

#direct_debit .error{
	margin-top:5px;
}


.clear_direct_debit{
	cursor: pointer;
}

.success_page_txt{
	line-height: 2em;
}

.step_nav li:not(:last-child):after{
	content:"";
	display:block;
	width:22px;
	height:40px;
	position:absolute;
	right:-42px;
	top:50%;
	margin-top:-20px;
	background:url("/images/icon-right.png") no-repeat;
}

.order_presentation_step{
	margin-top:15px;
}

.middle_margin{
	margin: 0px auto;
}

.wolrd_pay_error{
	color:#a94442;
}

#printable_quote{
	display: none;
}

.maps_overlay{
	width:100%;
	height:100%;
	position: absolute;
	background-color:#eee;
	opacity: 0;
}



/********** new home page modifications *************/

.header_top_part {
	background-color: #15488a;
	color:#fff !important;
}

.header_top_part a{
	color:#fff;
}

.header_top_part a:hover{
	color:#f1f1f1;
}

[role="banner"] {
	padding-top: 0px;
}

.animation_fill{
	color: #fff;
	background-color:#15488a;
}

.animation_fill:hover{
	color: #15488a;
	background-color:#fff;
}

.animation_fill[class*="color_"]:hover{
	color: #15488a;
}


.item_services h6:hover .widget_icon{
	background-color:#fff;
}

.widget_title{
	color:#15488a;
}

.widget_sub{
	color:#34383d;
}


.item_services h6:hover .animation_fill,.widget_link:hover .animation_fill {
color: #15488a;
background-color:#fff;
}


#back_to_top {
top: 90%;
}

.category_wall_style {
	/*margin-left: -20px;*/
	width: 74%;
}

.categories_wall figure{
	padding-left:5px;
	padding-right:5px;
}

.top_cart_hud{
	border:1px solid #bdc3c7;
	padding:5px;
	border-radius: 6px;
}

.color_bluify{
	color:#15488a !important;
}

.defaultimg,.circular_image{
	border-radius: 6px;
	-webkit-border-radius:6px;
	-moz-border-radius: 6px;
}

.request_callback:hover{
	cursor: pointer;
}


#added_to_cart_model a.add_to_cart_button{
	font-size: 0.8em;
}


.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover,
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover,
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover{
	background-color:#eee;
	border-radius: 0;
}

input[type="checkbox"]:disabled + label{ 
	color:#9E9C9C;
}

input[type="checkbox"]:disabled + label:hover{ 
	cursor: not-allowed;
}

.config_product_price_each{
	font-size: 0.8em;
}

.birds_eye img{
	border-top:1px dashed #eee;
	border-left:1px dashed #eee;
}
.birds_eye{
	position: absolute;
	bottom:0;
	right:15px;
}

.birds_eye_image_container img{
	width: 65px;
	height: 35px;
}

@media only screen and (min-width: 780px) and (max-width: 991px)  {
	.category_wall_style {
		/*margin-left: -20px;*/
		width: 100%;
	}
}



/******************** tracking steps styling *****************************/
.bs-wizard {border-bottom: solid 1px #e0e0e0; padding: 0 0 10px 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 16px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 14px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: #fbe8aa; top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: #fbbd19; border-radius: 50px; position: absolute; top: 8px; left: 8px; } 
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 20px 0;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #fbe8aa;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }


.postcodechecker_success{
	background: #d5f5e3;
  	color: #27ae60;
  	padding: 13px 55px 13px 20px;
}

.postcodechecker_failed{
	background: #fae5d3;
  	color: #d35400;
  	padding: 13px 55px 13px 20px;
}


textarea,
.select_title,
input:not([type="submit"]){
	border: 1px solid #D4D6D8;
}


.tr_all,
.popup_wrap:before,
.popup_wrap:after,
.owl-pagination > div{
	-webkit-transition:all .3s ease;
	-moz-transition:all .3s ease;
	-o-transition:all .3s ease;
	transition:all .3s ease;
	-webkit-animation-duration:.3s;	
	-moz-animation-duration:.3s;	
	-o-animation-duration:.3s;	
	animation-duration:.3s;	
}



.color_red,
.required:after,
.required_l:before,
.color_red_hover:hover,
.color_red_hover:hover [class*="color_"],
[class*="button_type_"].color_red:not(.transparent):hover{
	color:#F9636A;
	background-color:#fff;
}
.bg_color_red,
[class*="button_type_"].transparent.color_red:hover,
[class*="button_type_"].color_red:not(.transparent){
	background:#F9636A;
}
input:not([type="submit"]).error,
[class*="button_type_"].color_red,
.blog_side_button[class*="border_"]:hover{
	border-color:#F9636A;
}

select.error,textarea.error{
	border-color:#F9636A;
}


.top_free_zone_saving_message p{
	padding: 15px;
    border-radius: 7px;
    margin-bottom: 15px;
    margin-top: -20px;
    text-align: center;
    background-color: #F9636A;
    font-size: 1.2em;
    color:#fff;
    font-family: lato;
}

.top_free_zone_saving_message span{
	color: #fff;
    font-weight: bold;
}

.padding_top_bottom_2_5{
	padding: 2.5px 0px;
}

.special_offer{
	background-color: #d41010;
    border-color: #d41010;
    color: #fff;
}

.special_offer a{
	color: #fff !important;
}  

.new_arrivals{
	background-color: #fdc202;
    border-color: #fdc202;    
}

.container_product_title {
	width: 100%;
	display: table;
	table-layout: fixed;
}

.heading_product_title {
    height: 34px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.code_on_image {
    position: absolute;
    z-index: 2;
    left: 0;
	right: 0;
    top: 0;
    color: #34383d;
    font-size: 12px;
}

.remaining-variants {
	font-weight: 700;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
}
.remaining-variants-anchor {
	float: right; 
	margin-right: 14px; 
	margin-top: 5px;
}

div.scrollmenu {
	margin-top: 10px;
	overflow-y: auto;
	white-space: nowrap;
	min-height: 135px;
}

div.scrollmenu a {
	display: inline-block;
}


.variant-link {
	margin-right: 2px;
	margin-top: 0px;
}

.variant-link:first-child {
	margin-top: 2px
}

.variant-container {
	z-index: 10; 
	position:absolute; 
	display: grid; 
	top: 0;
	right: 0;
}

.span-variant-label {
	color: #606366;
	text-align: center;
	display: block;
	font-size: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.product_group_image {
    left: 0;
    z-index: 2;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
    height: 100%;
    width: 100%;
}

.product_group_image_container {
	width: 10%; 
	height: 10%; 
	position:absolute; 
	top:0; 
	z-index: 1;
}

.options_span {
	position: absolute;
    top: 0;
    margin-top: 45%;
	margin-left: 105%;
    font-size: 8px;
    color: #828686;
}


/**************** Sale Ribbon ********************/

.ribbon {
  position: absolute;
  right: 11px;
  top: 0px;
  z-index: 2;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 12px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#D41010 0%, #D41010 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  /* z-index: 500000000; */
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #D41010;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #D41010;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #D41010;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #D41010;
} 

.comment:before{
	border: none;
}

.comment_block_main:nth-child(odd){
	clear:left;
}
.comment{
	padding:0;
	font-size: 13px;
}
.comment_block{
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	-webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.6s; /* Firefox < 16 */
        -ms-animation: fadein 0.6s; /* Internet Explorer */
         -o-animation: fadein 0.6s; /* Opera < 12.1 */
            animation: fadein 0.6s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.radio_circle:before{
	border:1px solid #F9636A !important;
}

.fontellico_icon:before{
	font-family:fontelico !important;
}

[class*="icon_wrap_"].fontellico_wrap{
	border-color: #ccc;
    background-color: #FEF300;
    color: #000;
}

.fontellico_wrap{
	margin-right:1px;
}

.success_font h4{
	font-family: lato !important;
	line-height: 1.5 !important;
}

#angular_preloader{
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	overflow: visible;
	opacity: 0.6;
	background: #fefefe url('/images/full_page_loader.gif') no-repeat center center;
}


/********************* Internal quote page styling ****************/

.internal_quote_page .angucomplete-dropdown {
    width: 100%;
}

.internal_quote_page .angucomplete-selected-row {
    background-color: #15488a;
}

.internal_quote_page .field_with_btn{
	width:85%;
	float: left;
}

.internal_quote_page .field_btn{
	height: 40px;
	vertical-align:top;
	width:10%;
}

.internal_quote_page select:disabled{
	background-color:#F3F3F3;
}

.internal_quote_page input:disabled:hover,.internal_quote_page select:disabled:hover{
	cursor: not-allowed
}

.internal_quote_page .full button span {
    background-color: limegreen;
    border-radius: 32px;
    color: black;
  }
.internal_quote_page .partially button span {
    background-color: orange;
    border-radius: 32px;
    color: black;
  }
.internal_quote_page .btn-default {
    background: none !important;
}
.internal_quote_page .btn.active{
	background-color:#15488a !important;
}
.internal_quote_page .btn.active .text-info{
	color:#fff !important;
}
.internal_quote_page .dropdown-menu table td,.internal_quote_page .dropdown-menu table th{
	padding:0 !important;
}
.internal_quote_page .btn-default:disabled,.internal_quote_page .btn-default[disabled] {
    background-color: #e0e0e0 !important;
    background-image: none;
}

.internal_quote_page .errorinput{
	border: 1px solid #a94442 !important;
}
.internal_quote_page .form_error_block{
	color:#a94442;
}

.internal_quote_page .product_options_select{
	width: 100px;
    height: 30px;
    line-height: 10px;
    font-size: 14px;
    padding: 5px;
    font-weight: normal;
}

.internal_quote_page input:disabled {
    background: #F3F3F3;
}

.internal_quote_page .customer_radio_select{
	margin-top:-25px;
}

.internal_quote_page #result_display{
	margin:10px 0px;
}
.internal_quote_page .customer_addresses_table tr td{
	padding:5px 20px;
}

.internal_quote_page .list_entry{
	background-color: #eee;
	padding: 15px;
	margin-bottom:5px;
	border-radius:3px;
}

.internal_quote_page .list_entry:hover{
	cursor: pointer;
}

.internal_quote_page .selected_entry{
	background-color:#15488a;
	color:#fff;
}


.internal_quote_page .time_pickers input{
	border-radius: 5px;
}

.internal_quote_page .time_pickers tr td{
	padding:5px;
	border:none;
}

.internal_quote_page .time_pickers tr:hover{
	background:none;
}

.internal_quote_page #delivery_time_start,.internal_quote_page #collection_time_start{
	width:90px;
	float: left;
}
.internal_quote_page #delivery_time_end,.internal_quote_page #collection_time_end{
	width:90px;
	float: left;
	margin-left:10px;
}

.internal_quote_page .custom_surcharges_field{
	width: 100px;
	border-color: #ccc;
	border-width: 2px;
}

.internal_quote_page .angucomplete-dropdown{
	overflow-y: auto;
    max-height: 400px;
}

.internal_quote_page .reset_checklist_btn:disabled{
	background: #F3F3F3;
}

.internal_quote_page .reset_checklist_btn:disabled:hover{
	background: #F3F3F3;
	cursor: not-allowed;
	color:#F9636A;
}
.internal_quote_page .std_trans_field{
	border-color: #ccc;
    border-width: 2px;
    background-color: #f9f9f9;
}


.brochure{
	height: 25px;
	/*background-color: #088f64;*/
	width: 170px;
	margin:0px auto;
	text-align: center;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	color: #fff;
	font-size: 0.9em;
}

.brochure:hover{
	cursor: pointer;
}

.brochure_label:before,.brochure_label:after{
	margin-top: 45px;
}


.blog_post ul li {
		list-style-type: disc;
		margin-left: 15px;
	}


.add_to_cart_button:hover{
	cursor: pointer;
}

.add_to_cart_button:disabled,.add_to_cart_button:disabled:hover{
	background-color: #f8f8f8 !important;
	cursor: not-allowed !important;
	color:#ccc !important;
	border-color:#f8f8f8 !important;
}

input.error,select.error{
	border:1px solid #d41010 !important;
}
.option_color_selector{
	font-size: 14px;
}
input.has-error,select.has-error,textarea.has-error{
	border-color:#a94442;
}
.help-block.error{
	color: #a94442;
}
.date_pickers:hover{
	cursor: pointer;
}
table.uib-daypicker{
	border-spacing: 0px;
    border-collapse: separate;
}
table.uib-daypicker td,table.uib-daypicker th{
	padding: 0px;
}
table.uib-daypicker td .btn-default[disabled]:hover{
	cursor: not-allowed;
}
table.uib-daypicker td .btn-default{
	background-image: none;
}
table.uib-daypicker td .btn-default[disabled].active{
	background-color: #e0e0e0;
}
table.uib-daypicker td .btn.btn-default.btn-sm.btn-info .text-muted{
	color: inherit;
}
.qty_error{
	float: none;
	text-align: center;
}
.get_quote_btn:disabled{
	background-color: #eee !important;
    color: #aaa !important;
    cursor: not-allowed !important;
}
.edit_btn:hover{
	cursor: pointer;
}
select:disabled{
	background-color: #f9f9f9;
}
select:disabled:hover{
	cursor: not-allowed;
}

input[type="radio"] + label.modifier_label{
	background-color: #fff;
    width: 100px !important;
    height: 100px;
    border: 1px solid #15488a;
    border-radius: 5px;
    padding-left: 0;
}

input[type="radio"] + label.modifier_label:before{
	bottom: 10px;
    left: calc(50% - 13px);
    width: 26px;
    height: 26px;
    border: 1px solid #15488a;
    top: inherit;
}

input[type="radio"] + label.modifier_label:after{
	background: #15488a;
    width: 16px;
    height: 16px;
    left: calc(50% - 8px);
    bottom: 15px;
    top: inherit;
}

.modifier_label .radio_top_stripe_date{
	margin-top: -25px;
    height: 25px;
    text-align: center;
    font-weight: bold;
}

.modifier_label .radio_top_stripe_cheapest{
	background-color: #15488a;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
}

.modifier_label .radio_top_stripe_price{
	bottom: 50px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    left: calc(50% - 27px);
}

.modifier_label .radio_top_stripe_price sup{
	line-height: 20px;
    padding-left: 3px;
}

.modifier_types_label{
	font-weight: bold;
	font-size: 18px;
	color:#34383d;
}

.modifier_label.disabled-radio{
	opacity: 0.3;
	filter: grayscale(100%);
	filter: blur(50%);
}

.modifier_label.disabled-radio:hover{
	cursor: not-allowed;
}

.uib-title,.uib-right,.uib-left{
	box-shadow: none;
	background-image: none;
}

.uib-title:hover,.uib-right:hover,.uib-left:hover{
	color:#fff;
	background-color:#31b0d5;
}

.uib-day button:hover,.uib-day button:focus,.uib-day button:active,.uib-month button:hover,.uib-year button:hover{
	color:#fff;
	background-color: #31b0d5;
}

.uib-day button:disabled{
	color:#000;
	background-color: #efefef;
}

.uib-month button.active .text-info,.uib-year button.active .text-info,.uib-month button .text-info{
	color:#fff;
}

.uib-month button,.uib-year button{
	box-shadow: none;
	background-image: none;
}

.uib-day button,.uib-day button:disabled{
	border: none;
}

.uib-day button:hover .text-muted{
	color:#fff;
}

.faq{
	text-align: left;
}

.faq h3{
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 5px;
}

.faq p{
	margin-bottom: 15px;
}

.faq_section_title h2{
	text-align: left;
	color: #15488a;
	font-size: 22px;
	font-weight: bold;
}

.faq_section_title{
	background-image: none !important;
}

.main_menu > li > a{
	padding:25px 7px;
}

#back_to_top{
	color: #15488a;
	border-color: #15488a;
	bottom:50px;
}

.instant_quote_saved{
	position: relative;
	padding: 15px;
    border-radius: 5px;
    border: 1px solid #088f64;
    color: #088f64;
    margin-bottom:25px;
}

.instant_quote_saved button{
	float: right;
    width: 80px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: #088f64 !important;
    margin-right: 0px;
    line-height: 43px;
    border-radius: 0;
    border: none;
}

.instant_quote_saved button:hover{
	color: #fff !important;
	cursor: pointer;
}

.fade_instant_quote_details,.fade_instant_quote_products {
	transition-property: all;
	transition-duration: 5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.fade_instant_quote_details.active,.fade_instant_quote_products.active{
	display: block;
}

.fade_instant_quote_details,.fade_instant_quote_products{
	display: none;
}




/************* Full page loader **************/
.d-flex{
	display: flex !important;
}
.justify-content-center{
	justify-content: center !important;
}
.loader_img{
	top:50%;
	position: fixed;
	z-index: 20;
}
.sk-circle {
  margin: 40px auto;
  width: 90px;
  height: 90px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #15488a;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/*
#preloader_container{
	width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999999;
    background-color: #fefefe;
}*/

#preloader_container_trans{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    left: 0px;
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.8);
}

.lazy {
    display: none;
}
#floating_menu_tel,#floating_menu_search_btn{
	display: none;
}
.main_menu.type_2 > li > a{
	font-size: 16px;
}
input.cart_item_qty[readonly], .success_page_address input[readonly],input#delivery_postcode[readonly]{
	background-color: #f7f9f9;
}
input.cart_item_qty[readonly]:hover, .success_page_address input[readonly]:hover,input#delivery_postcode[readonly]:hover{
	cursor: not-allowed;
}
.shop_isotope_item, .portfolio_item {
	padding-top: 5px;
}
.owl-item .fp_item{
	padding-top: 5px;
}
.stock_holding_overlay{
	position: absolute;
    top: 5px;
    left: 0px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 10px;
    z-index: 3;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    background-color: #15488a;
    padding: 5px;
}
.ribbon{
    right: -5px !important;
}
.green_bold{
	color: #088f64;
    font-weight: bold;
    line-height: 26px;
}
.checkout_page_section{
	padding: 30px 0 10px;
}
.table_type_2 td:not([colspan]), .table_type_4 td{
	padding: 5px;
	vertical-align: middle;
}
.order_summary_btn{
	margin: 20px auto;
    min-width: 250px;
    display: block;
    text-align: center;
    font-size: 24px;
}
.details_page_container .tabs b{
	display: block;
}
.order_summary_plus{
	border: 2px solid #15488a;
    padding: 15px;
    border-radius: 117px;
    width: 25px;
    height: 25px;
    display: inline-block;
}
.order_summary_btn:hover span, .order_summary_btn:hover span i, .order_summary_btn:hover span i{
	color: #fff;
	border-color: #fff;
}
.color_grey, .tweet_time a {
    color: #1b1b1b;
}
.tabs_nav li:not(:last-child) a {
    border-top-color: #bdc3c7;
}
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}
#added_to_cart_model table tr td, #added_to_cart_model table tr td a{
	font-size: 16px;
}
#sorting_options_list, #top_items_per_page_selector, #bottom_items_per_page_selector{
	padding: 2px;
}
.lead_time{
	font-size: 12px;
    color: #d41010;
    line-height: 15px;
    font-style: italic;
    font-weight: 400;
}
.noscroll{
	overflow: hidden;
	position: fixed;
}
.billing_address_box{
	background-color: #f7f9f9;
	padding: 15px;
	border: 1px solid #dddede;
	border-radius: 5px;
	margin-bottom: 15px;
}
.ext_dates{
	width: 70%;
}
.add_to_cart_buy_button{
	background-color:#dc5da2;
	border:none;
}
.add_to_cart_buy_button:hover{
	background-color:#e2528f !important;
	color:#fff !important;
}
.buy_summary_block{
	margin-bottom: 15px;
    margin-top: 5px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
    border: 1px solid #15488a;
}
.add_to_cart_buy_button:disabled{
	background-color: #ccc;
}
.add_to_cart_buy_button:disabled:hover{
	cursor: not-allowed;
	background-color: #ccc !important;
}

#bottom_floating_nav{
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: #48484A;
  z-index: 999999999;
}
#bottom_floating_nav .section{
  width: 25%;
  float: left;
  color:#fff;
  text-align: center;
  padding-top:6px;
}
#bottom_floating_nav .section .text{

}
#bottom_floating_nav .section .icon{
  color: #fff;
  font-size: 23px;
  margin-bottom:15px;
  clear:both;
}
#products_floating_menu,#events_floating_menu{
  background-color:#fff;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.floating_menu_top_bar{
  height:40px;
  font-size: 18px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #48484A;
  padding:5px 15px;
}
.floating_menu_top_bar .title{
  float: left;
  color: #a4d605;
}
.floating_menu_top_bar .close_floating_bar{
    float: right;
    margin-top: -1px;
    display: block;
    width: 32px;
    height: 32px;
    text-align: center;
    color: #a4d605;
    border: 1px solid #a4d605;
    border-radius: 20px;
}
.floating_menu_top_bar .close_floating_bar:hover{
  cursor: pointer;
}
.floating_menu_top_bar .close_floating_bar i{
  color: #a4d605;
  margin-top: -2px;
  display: block;
}
.floating_menu_body{
  padding:60px 10px 10px 10px;
}
#products_floating_menu .floating_menu_body .floating_menu_category_item,#events_floating_menu .floating_menu_body .floating_menu_category_item{
  margin-bottom: 10px;
  text-align: center;
}
#products_floating_menu .floating_menu_body .floating_menu_category_image,#events_floating_menu .floating_menu_body .floating_menu_category_image{
  margin-bottom: 5px;
}
.floating_tawk_trigger{
  width:50px;
  height: 50px;
  background-color: #15488a;
  position: fixed;
  text-align: center;
  bottom:45px;
  right:20px;
  display: block;
  z-index:7;
  border-radius:25px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
}
.bottom_nav_chat:hover,.bottom_nav_events:hover,.bottom_nav_products:hover,.bottom_nav_home:hover{
  cursor: pointer;
}
.floating_menu_category_item:nth-child(4),.floating_menu_category_item:nth-child(7),.floating_menu_category_item:nth-child(10){
  clear: left;
}
.bottom_mobile_menu_cat_block_text{
  display: block;
  min-height: 35px;
}
.noscroll { overflow: hidden; }
[aria-hidden="true"] { display: none; }
[aria-hidden="false"] { display: block; }
.mobile_menu_close{
  color:#fff;
  font-weight: bold;
}
#bottom_floating_nav a img{
  width: 32px;
}
#bottom_floating_nav{
	display: none;
}
.quoteshow{
	visibility: visible;
    opacity: 1;
    position: fixed;
    top: 25px;
    right: 5px;
    left: unset;
}
.dropdown_2_container{
	z-index: 9999;
}
.mobile_sub_cat_selector{
    height: 40px;
    margin-top: 2px !important;
    color: #fff;
    border-color: #15488a;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    background-color: #15488a;
    display: none !important;
}
.wsmenu-submenu .fa{
	margin-top: 5px;
    margin-left: 5px;
}
.search-border-box{
	border: 1px solid #fff;
	margin:10px;
}
.pinned-search-border-box{
	border: 1px solid #15488a;
	margin:11.5px 10px 10px 10px;
}
.topmenusearch input{
	height: 25px  !important;
	float: none  !important;
	position: unset  !important;
}
.topmenusearch{
	margin:10px;
	border-radius: 5px;
}

.topmenusearch .btnstyle .fa,.topmenusearch .btnstyle .icon-search{
	line-height: 27px !important;
}
.pinned_topmenusearch input{
	height: 25px  !important;
	float: none  !important;
	position: unset  !important;
}
.pinned_topmenusearch{
	margin:10px;
	border-radius: 5px;
}

.pinned_topmenusearch .btnstyle .fa,.pinned_topmenusearch .btnstyle .icon-search{
	line-height: 27px !important;
}
.top_search_btn{
	height: 25px;
    width: 30px   !important;
    background-color: transparent !important;
    margin-top: 11px;
    margin-right: 11px;
    line-height: 25px;
    padding: 0px;
}
.pinned_top_search_btn{
	height: 25px;
    width: 30px   !important;
    background-color: transparent !important;
    margin-top: 11px;
    margin-right: 11px;
    line-height: 25px;
    padding: 0px;
}
.wsmenu{
	font-size: 13px !important;
}    
.search-border-box input{
	background-color: #fff !important;
}
.pinned-search-border-box input{
	background-color: #fff !important;
}

.topmenusearch input:focus~.btnstyle .fa, .topmenusearch input:focus~.btnstyle .fa{
	color: #15488a !important;
}
.pinned_topmenusearch input:focus~.btnstyle .fa, .pinned_topmenusearch input:focus~.btnstyle .fa{
	color: #15488a !important;
}
.topmenusearch .btnstyle .fa,
.topmenusearch .btnstyle .icon-search,
.topmenusearch input:hover,
.topmenusearch input:focus, 
.topmenusearch input{
	color: #15488a !important;
}
.pinned_topmenusearch .btnstyle .fa,
.pinned_topmenusearch .btnstyle .icon-search,
.pinned_topmenusearch input:hover,
.pinned_topmenusearch input:focus, 
.pinned_topmenusearch input{
	color: #15488a !important;
}
.mobile_checkout_steps{
	width: 100%;
	height: 100px;
    margin-bottom: 10px;
    
}

.mobile_checkout_steps .step .label{
    font-size: 16px;
    text-align: center;
    width: 100%;
    padding: 2px 0px;
}
.mobile_checkout_steps .step .number{
	
    color: #fff;
    text-align: center;
    height: 61px;
    font-size: 30px;
    border-radius: 16px;
    padding-top: 18px;
}
.mobile_checkout_steps .step{
	width: 24.5%;
	padding:3px 20px;
	float: left;
}
@media (max-width:450px){
	.mobile_checkout_steps .step {
		padding:3px 5px;
	}
}

.mobile_checkout_steps .step .number{
	background-color: #088f64;
}
.mobile_checkout_steps .step .label{
	color: #088f64;
}

.mobile_checkout_steps .step.active .number{
	background-color: #15488a;
}
.mobile_checkout_steps .step.active .label{
	color: #15488a;
}
.mobile_checkout_steps .step.disabled .label{
	color: #eee;
}

.mobile_checkout_steps .step.disabled .number{
	background-color: #eee;
}
.mobile_checkout_steps .step.active:hover{
	cursor: pointer;
}
.mobile_checkout_steps .step.disabled:hover{
	cursor: not-allowed;
	pointer-events: none;
}
.totals_table tr td:nth-child(2){
	width:14%;
	vertical-align: middle;
	text-align: right;
}
.totals_table{
	border-top:1px solid #ddd;
	margin-bottom: 0px;
}
.quote_email_notification_txt{
   color: #15488a;
   text-align: right;
   display: block;
   width: 100%;
   margin-bottom: 15px; 
}
.discount_code{
	margin-left: 0px;
}

/********************** responsive fixes ************************/


@media only screen and (max-width:1200px){
	div.scrollmenu {
		min-height: 135px;
	}
}

@media only screen and (max-width:1024px){
	.t_md_align_l{
		text-align: left;
	}
	.header_bottom_part {
		margin-top:20px;
	}
	.consent_body{
		width: 100% !important;
	}
	.consent_body{
		font-size: 11px;
	}
	.header_top_part{
		display: none;
	}
	/* .categories_wall .category_tile:nth-last-child(-n+5) {
	  display: none;
	} */
	div.scrollmenu {
		min-height: 135px;
	}

}

.product_group {
	float: right;
	width: 52%;
}

.row_product_dropdown {
	display: flex;
	align-items: center;
	padding-left: 14px;
}
.product_group_p {
	font-size: 20px;
	font-weight:300;
	width:70%;
}

@media only screen and (max-width:780px){
	.category_wall_style {
		margin-left: 0px !important;
		width: 100% !important;
	}
	body{
		padding-top: 35px;
	}
	.consent_body{
		line-height: 36px;
	}
	#consent_continue_btn{
		margin-top:7px;
	}
	.categories_wall .category_tile:nth-last-child(-n+5) {
	  display: block;
	}
	.product_group {
		float: unset;
		width: 100%;
	}
	.row_product_dropdown {
		display:block;
		align-items: unset;
		padding-right: 14px;
	}
	.product_group_p {
		font-size: 20px;
		font-weight: 300;
		width: 70%;
		margin-bottom: 5px;
	}
	
	.product_group_image_container {
		width: 15%; 
		height: 15%; 
	}
	.remaining-variants {
		font-size: 12px;
		/* margin-top: 6px; */
	}
	div.scrollmenu {
		min-height: 135px;
	}
	
	.options_span {
		margin-left: 27px;
	}


	/* .categories_wall .category_tile:nth-last-child(-n+4) {
	  display: none;
	} */

}


@media only screen and (max-width:780px){
	.brochure{
		margin-bottom:15px !important;
		display: none;
	}

	.header_top_right,.header_top_left{
		margin-top: 0px !important;
	}
	.t_md_align_l{
		text-align: left;
	}

	.login{
		float:left !important;
	}
	.dropdown_2_container.login .dropdown_2{
		left:320%;
	}
	.dropdown_2_container.login .dropdown_2:before{
		right:90%;
	}
	/*.dropdown_2_container.shoppingcart .dropdown_2 {
		left: -200%;
	}*/
	.dropdown_2_container.shoppingcart .dropdown_2:before {
		right:6%;
	}
	.pagination_fix,.per_page_fix{
		text-align:center;
	}

	form [class*="col-"]{
		padding-left:5px;
		padding-right:5px;
	}

	.ext_dates{
		width: 100%;
	}
	.buy_details_hide{
		display: none !important;
	}
	.mobile-bar-container .mobile-bar-child:nth-child(1){
		flex: 2 0px;
	}
}
@media only screen and (max-width:780px){
	.top_phone_number{
		display: none;
	}
	.page_title_header {
		margin-bottom: 2px !important;
	}
}

@media only screen and (max-width:500px){
	.checkout_steps_helper_text{
		display: none;
	}
	.t_md_align_l{
		text-align: center;
	}
	.sticky_part:after, .header_bottom_part:not([class*="type_"]):after, .header_bottom_part.type_3.sticky:after{
		background: none;
	}
	.floating_menu{
		position: fixed;
	    top: 0;
	    width: 100%;
	    background-color: #fff;
	    z-index: 99;
	    border-bottom: 1px solid #ccc;
	    padding-top: 5px;
	    height: 50px;
	}
	.floating_menu_search input{
		margin-top: 5px;
		position: fixed;
		top: 0;
		width: 75% !important;
		left: 10px;
		display: inline-block;
	}
	.d_none_floating_mobile{
		display: none !important;
	}

	.floating_menu_nav{
		background-color:#fff;
		display: block;
	    position: fixed;
	    width: 100%;
	    left: 0px;
	    top: 50px;
	    display: none;
	    height: 100%;
	    overflow-y: scroll;
	    padding: 5px 10px;
	}
	.floating_menu #menu_button{
		float: left;
		width: 17%;
	}
	#floating_menu_tel{
		display: inline-block;
    	font-size: 24px;
    	width: 63%;
    	text-align: center;
    	padding-top: 10px;
	}

	#floating_menu_search_btn{
		display: inline-block;
    	width: 17%;
    	float:right;
    	text-align: center;
    	color:#15488a !important;
	}
	
	.fp_item:hover .fp_price{
		text-align: center;
	}
	.stock_holding_overlay{
	    font-size: 8px;
	    font-weight: normal;
	    padding: 2px;
	}
	.brochure{
		display: none;
	}
	.instant_quote_saved_text{
		    width: 80%;
	}

	.instant_quote_saved button{
	    line-height: 60px;
	}
	
	.header_top_right,.header_top_left{
		margin-top: 10px !important;
	}

	.project_description_up{
		display:none;
	}

	.modifier_label{
		width: 40% !important;
		margin-bottom: 35px;
	}

	.portfolio_item.type_2 .popup_wrap:hover img{
	-webkit-transform:translateY(0px);
	-moz-transform:translateY(0px);
	-o-transform:translateY(0px);
	-ms-transform:translateY(0px);
	transform:translateY(0px);
	}
	.portfolio_item.type_2 .popup_wrap:hover .project_description{
		-webkit-transform:translateY(0px);
		-moz-transform:translateY(0px);
		-o-transform:translateY(0px);
		-ms-transform:translateY(0px);
		transform:translateY(0px);	
	}
	.portfolio_item.type_2 .popup_wrap:hover .project_description_up{
		-webkit-transform:translateY(-100%);
		-moz-transform:translateY(-100%);
		-o-transform:translateY(-100%);
		-ms-transform:translateY(-100%);
		transform:translateY(-100%);
	}

	.table_padding_fix tr td{
		padding-top:5%;
		padding-bottom:5%;
	}
	.blog_side_button {
		height: 60px;
		width: 60px;
		}
	.day_of_the_month {
		font-size: 1.8em;
		line-height:0.6em;
	}
	.category_tile:nth-child(even){
		clear: right;
		display: block;
	}
	.category_tile:nth-child(odd){
		clear: left;
		display: block;
	}
	.order_quote_btn{
		margin-bottom: 10px;
	}

	.ext_dates{
		width: 100%;
	}
	.buy_details_hide{
		display: none !important;
	}
	#bottom_floating_nav{
		display: block;
	}
	.category_price_from{
		font-weight: normal;
	    padding-top: 11px;
	    text-align: left;
	}
	.category_top_pagination,.category_view_switcher,.category_product_code,.not_mobile_nav{
		display: none;
	}
	/* .footer_bottom_part{
		margin-bottom:30px;
	} */
	#back_to_top{
    	bottom: 50px;
    	top: unset;
	}
	.footer_contact_form{
		display: none;
	}
	.footer_payment_icons,.footer_social_icons{
		display: none;
	}
	.mobile_sub_cat_selector, .mobile_cat_menu_jump {
	    display: inline-block !important;
	}
	#consent_detailed_modal label{
		font-size:14px;
	}
	#consent_detailed_modal .consent_sub_txt{
		font-size:12px;
	}
	#consent_topbar{
		padding-bottom: 10px !important;
		height: 65px !important;
	}
	.consent_body{
		width: 100% !important;
		line-height: 24px;
	}
	body{
		padding-top: 35px;
	}
	#consent_topbar{
		top:-40px !important;
	}
	.tabs_nav .active + li a{
		border-left:1px solid #bdc3c7 !important;	
	}
	.categories_wall{
		margin-bottom: 20px;
	}
	#consent_topbar{
		margin-top:10px;
	}
	#consent_continue_btn{
	    font-size: 20px;
	    height: 30px;
	    border-radius: 64px;
	    width: 30px;
	    text-align: center;
	    padding-top: 4px;
	    background-color: #15488a;
	    color: #fff;
	    margin-top: -9px;
	}
	.search-border-box{
		padding: 0px;
    	height: 45px;
    	margin: 0px
	}
	.pinned-search-border-box{
		padding: 0px;
    	height: 35px;
    	margin: 8px;
	}
	.pinned_top_search_btn {
		margin-top:13px;
	}
	.search-border-box input{
		height: 100% !important;
	}
	.pinned-search-border-box input{
		height: 100% !important;
	}
	.topmenusearch .btnstyle{
		height: 0px !important;
	}
	.topmenusearch input:placeholder-shown{
		color: #15488a !important;
		border: 1px solid #15488a;
	}
	.pinned_topmenusearch .btnstyle{
		height: 0px !important;
	}
	.pinned_topmenusearch input:placeholder-shown{
		color: #15488a !important;
	}
	.added_items_list li{
		padding-right: 0px;
	}
	.dropdown_2_container:hover .dropdown_2{
		top: 45px;
	}
	.header_bottom_part .container{
		margin-bottom: 0px;
	}
	.totals_table tr td,.table_type_2 tr td{
		font-size: 14px;
	}
	.table_type_2{
		border-bottom: 5px solid #48484a;
	}
	.quote_email_notification_txt{
		font-size: 14px;
		text-align: center;
	}
	.discount_code,.discount-form{
		width: 100%;
	}
	.mobile-bar-container .mobile-bar-child:nth-child(1){
		flex: 1 0px;
	}
	.circular_image{
		display: none !important;
	}

	.categories_wall .category_tile:nth-last-child(-n+5) {
	  display: block;
	}

	/* .categories_wall .category_tile:nth-last-child(-n+4) {
	  display: none;
	} */
}


@media only screen and (max-width:400px){

	.flex-bigger a span {
		font-size:10pt;
	}


	.quantity input[type="text"]{
		width: 50px;
	}

}

@media only screen and (max-width:500px){
	.flex-bigger a span {
		font-size: 12pt;
	}
}

@media only screen and (max-width:350px){

	.flex-bigger a span {
		font-size: 10pt;
	}
	.mobile-logo {
		margin-left: 42px !important;
	}

}

.footer_payment_icons {
	float:right;
}

#sidebar ul li a {
	color:white;
	width: 100%;
	display: inline-block;
	font-weight: bold;
	
	
}

#sidebar {
	margin-bottom: 30px;
}
#sidebar ul li:hover {
	background-color:white;
}
#sidebar ul li:hover a{
	color:#15488a;
}
#sidebar ul li {
	text-align: center;
	background-color:#15488a;
	margin-bottom: 2px;
	padding:5px;
	transition: 0.3s;
	border:1px solid #045fc780;
}

.home-image-container {
	position:relative;
}
.home-image-container img {
	width:100%;
}
#dashboard .col-md-10 {
	padding-right: 0;
}

.home-image-wrapper {
	position: static;
	margin-bottom: 15px;
}

.dashboard-tiles div:hover a{
	
	color:#15488a;
}
.dashboard-tiles div:hover a {
	background-color:white;
}

.dashboard-tiles div a{
	height: 100%;
	width: 100%;
	text-align: center;
	padding:23px;
	color:white;
	font-weight: bold;
	display: inline-block;
	background-color:#15488a;
	border:1px solid #045fc780;
	transition: 0.3s;
}


.dashboard-tiles div {
	text-align: center;
	margin-bottom: 2px;
	padding:5px;
	height:100px;
	color:white;
	padding:15px;
}

#dashboard .account h1, #dashboard div h1 {
	color:#15488a;
	font-weight: bold;
	margin-bottom: 30px;
}
#dashboard .account p, .condensed-bold p{
	text-transform: uppercase;
	font-weight: 900;
	font-size: 12px;
	letter-spacing: 2px;
	border:1px solid white;
	padding-right: 10px;
	padding-left: 10px;
	
}

.condensed-bold-element{
	text-transform: uppercase;
	font-weight: normal;
	font-size: 12px;
	letter-spacing: 2px;
	border:1px solid #ddd;
	padding-right: 10px;
	padding-left: 10px;
}
.account {
	padding-left: 5px;
}

.account h1 {
	padding-left: 10px;
}
.account-dashboard{
	padding-left: 0px !important;
}
.account p{
	pointer-events: none;
}
.account p a {
	pointer-events: auto;
}
.account p:hover {
	border:1px solid #333 !important;
}

.home-image-container h1 {
	position: absolute;
	bottom:0;
	right:0;
	padding:20px;
	color:white !important;
	font-weight: bold;
	letter-spacing: 6px;
	font-size: 40px;
	text-align: right;
}

.txt-bold-blue{
	color:#15488a !important;
	font-weight:bold;
}

#sidebar {
	padding-left:0;
}

.mobile-logo {
	max-width: 110px;
	margin-left:50px;
	padding:10px;
	position:absolute;
}

.mobile-logo-container {
	position: fixed;
}

@media (max-width:780px) {
	.hide-on-desktops {
		display: block !important;
	}
	.hide-on-mobiles {
		display: none !important;
	}

	.column_parent{
		display: flex;
		flex-wrap: wrap;
	}
	.product_column{
		margin-bottom: 5px;
	}
}
@media (min-width:781px) {
	.hide-on-desktops {
		display: none !important;
	}
	.hide-on-mobiles {
		display: block !important;
	}
}

@media (min-width:381px) {
	#mobile-brand-logo-sm{
		display: block;
	}
	#mobile-brand-logo-xs{
		display: none;
	}
}

.mt-10 {
	margin-top: 10px !important;
}

.toggle-on { 
	-webkit-box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
	background-color: #15488a;
	background-image: none !important;
}

.toggle-off {
	-webkit-box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
	background-color: #dc5da2 !important;
	background-image: none !important;
}

.dynamic_price {
	opacity:0;
} 


@media (max-width:550px){
	.cancellations-policy{
		font-size: 12px;
		text-align: left !important;
		padding-left: 10px;
	}
}

@media (max-width:650px){
	.cancellations-policy{
		font-size: 12px;
		text-align: center;

	}
}

@media (max-width:470px){
	.cancellations-policy{
		font-size: 10px;
		text-align: left;
		padding-left: 10px;
	}
}

@media (max-width:350px){
	.cancellations-policy{
		font-size: 8px;
		text-align: left;
		padding-left: 5px;
	}
}

.addon-products button.active {
	background: #15488a;
	color:#fff;
}

.addon-products button {
	margin: 2px;
	padding: 3px 9px;
	font-size:75%;
}

.addon-products {
	margin-bottom: 15px;
}
.addon-product-title{
	font-weight: 300;
	font-size: 20px;
}

.product_preview_popup{
	display: none;
}


.pulsating_pink {
	animation: pulse 2.5s infinite;
	border:1px solid #088f64 !important;
}

@keyframes pulse {
	0% {
		background-color: #f9f9f9;
		color: #606366;
	}

	2% {
		background-color: #088f64;
		color:white;
	}


	

	100% {
		background-color: #f9f9f9;
		color: #606366;
	}
}

.pulsating_pink_inverted {
	animation: inverted_pulse 2.5s infinite;
	border:1px solid #088f64 !important;
}

@keyframes inverted_pulse {
	0% {
		background-color: #f9f9f9;
		color: #606366;
	}

	50% {
		background-color: #f9f9f9;
		color: #606366;
	}

	52% {
		background-color: #088f64;
		color:white;
	}

	100% {
		background-color: #f9f9f9;
		color: #606366;
	}
	
}

.vat-primary {
	background-color: #333 !important;
	border-color: #fff;
	background-image: none !important;
	padding-top:6px;
	font-size: 10px;
}

.vat-primary:hover {
	border-color: inherit !important;
}


@media only screen and (max-width:550px){
	#upsell .button_type_5, #upsell .button_type_6{
		padding:2px 6px;
		font-size: 12px;
	}

}
.home-case-study-img{
	object-fit: cover;
	width: 100% !important;
	height: 100px;	
}

.pricetip {
	position: relative;
	display: inline;
}

.pricetip .pricetiptext {
	visibility: hidden;
	background-color: #15488a;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 6px;
	position: absolute;
	z-index: 1;
	top: 150%;
	left: 50%;
	margin-left: -150px;
}

.pricetip .pricetiptext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: 62px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #15488a transparent;
}

.pricetip:hover .pricetiptext {
	visibility: visible;
}
.discounted_product_price_red{
	color:#d41010;
	display: inline;
}

.bespoke-form {
	margin: 0px 0 0; 
	width:100%; 
	height: 44px; 
	float:right; 
	position:relative; 
	border-radius:5px; 
	justify-content: space-evenly;
	display: flex;
}

.bespoke-nav-container {
	display: flex; 
	width:90%; 
	padding:0px; 
	height: 35px; 
	margin:8px; 
	border: unset;
}

nav .uib-datepicker-popup.dropdown-menu {
	position: fixed;
	top: 127px !important;
}

.nav-mobile-search {
	display: none; 
	border-radius:0; 
	position: fixed !important; 
	top: 50px;
	background-color: rgb(21, 72, 138); 
	right: 0; 
	z-index: 105; 
	width:100%;
}

.nav-mobile-search input {
	background-color: white !important; 
	color:white !important; 
	height: unset !important; 
	border: 1px solid !important; 
	padding: 5px !important;
}