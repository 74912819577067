/* ----------------------------------
	1. Reset default browser styles
	2. Basic classes and elements 
	3. Colors
	4. Typography
	5. Header
	6. Sliders
	7. Tabs,Accordions,toggles
	8. Carousels
	9. Twitter Feed
	10. Lists and navigations
	11. Buttons
	12. Tables
	13. Icons
	14. Main content elements
	15. Forms
	16. Footer
	17. Animations
	18. Responsive changes
	19. Retina ready
-------------------------------------*/


/* -----------------------------------

	2. Basic classes and elements 

------------------------------------- */

::-webkit-selection{
	background:#41bedd;
	color:#fff;
}
::-moz-selection{
	background:#41bedd;
	color:#fff;
}
::selection{
	background:#41bedd;
	color:#fff;
}
/*
::-webkit-scrollbar{
	width:10px;
	background:#f7f9f9;
}
::-webkit-scrollbar-thumb{
	background:-webkit-gradient(linear,left top,right bottom,from(#70269f),to(#35eef6));
	background:linear-gradient(top,#70269f,#35eef6);
}*/
::-webkit-input-placeholder{
	-webkit-transition:text-indent .5s ease,color .5s ease;
	color:#899096;
}
input::-moz-placeholder{
	color:#899096;
}
::ms-input-placeholder{
	color:#899096;
}
[placeholder]:focus::-webkit-input-placeholder{
	text-indent:10em;
	color:transparent;
}

dl .main_menu_links a{
	color:#34383d;
	font-weight: 400;
}

dl.active .main_menu_links a{
	color:#15488a;
}

.circle,
.flickr_list li:hover,
#dribbble_feed li:hover,
.owl-pagination > .owl-page,
input[type="radio"] + label:after,
input[type="radio"] + label:before{
	-webkit-border-radius:50%;
	-moz-border-radius:50%;
	border-radius:50%;
}
.f_none{
	float:none;
}
.f_left{
	float:left;
}
.f_right{
	float:right;
}
.d_block{
	display:block;
}
.d_table{
	display:table;
}
.d_table > [class*="col-"]:last-child{
	padding-right:0px;
}
.d_table > [class*="col-"]:first-child{
	padding-left:0px;
}
.d_table_cell{
	display:table-cell;
}
.d_inline_b,
.clients.brands .owl-pagination{
	display:inline-block;
}
.d_inline_m{
	display:inline-block;
	vertical-align: middle;
}
.d_none,
.sticky_menu #side_menu,
.sticky_menu #open_side_menu{
	display:none;
}

.min_height_240{
	min-height: 240px;
}
.v_align_m{
	vertical-align: middle;
}
.v_align_b{
	vertical-align: bottom;
}
.v_align_t{
	vertical-align: top;
}
.relative,
.tooltip_container{
	position:relative;
	z-index:1;
}
.half_column{
	width:50%;
}
.im_half_container .half_column:last-child{
	margin-left:-25px;
}
.ie9 .im_half_container .half_column:last-child{
	margin-left:-4px;
}
.counter{
	counter-reset: counter;
}
.counter_inc{
	counter-increment: counter;
}
.ls_medium{
	letter-spacing: -1px;
}
.w_auto{
	width:auto;
}
.w_full,
.wide_layout,
[class*="_isotope_container"] .shop_isotope_item.list_view{
	width:100%;
}
.translucent{
	opacity:.5;
}
.wrapper{
	overflow:hidden;
}
.ellipsis{
	text-overflow:ellipsis;
}
.w_break{
	word-break:break-all;
}
.t_align_c,
.clients.brands .owl-controls{
	text-align:center;
}
.t_align_r{
	text-align:right;
}
.t_align_l{
	text-align:left;
}
/*.t_align_l,.fp_item:hover,.fp_item:hover .fp_price{
	text-align:left;
}*/
.lh_inherit{
	line-height: inherit;
}
.lh_small{
	line-height:1.18em;
}
.lh_medium{
	line-height:1.125em;
}
.lh_large{
	line-height:1.375em;
}
.fs_ex_small,#countdown.type_2 dd{
	font-size:0.75em;
}
.fs_small{
	font-size:0.812em;
}
.fs_medium,
.accordion_item .icon-plus{
	font-size:0.875em;
}
.fw_ex_light{
	font-weight:100;
}
.fw_light{
	font-weight:300;
}
.fw_normal{
	font-weight:400;
}
.fw_ex_bold{
	font-weight:900;
}
.m_right_2{
	margin-right:2px;
}
.m_right_5{
	margin-right:5px;
}
.m_right_8{
	margin-right:8px;
}
.m_right_10{
	margin-right:10px;
}
.m_right_15{
	margin-right:15px;
}
.m_right_20{
	margin-right:20px;
}
.m_right_30{
	margin-right:30px;
}
.m_right_40{
	margin-right:40px;
}
.m_right_60{
	margin-right:60px;
}
.m_left_5{
	margin-left:5px;
}
.m_left_8{
	margin-left:8px;
}
.m_left_10{
	margin-left:10px;
}
.m_left_15{
	margin-left:15px;
}
.m_bottom_0{
	margin-bottom:0px !important;
}
.m_bottom_3{
	margin-bottom:3px;
}
.m_bottom_5{
	margin-bottom:5px;
}
.m_bottom_8{
	margin-bottom:8px;
}
.m_bottom_10{
	margin-bottom:10px;
}
.m_bottom_12{
	margin-bottom:12px;
}
.m_bottom_15{
	margin-bottom:15px;
}
.m_bottom_20{
	margin-bottom:20px;
}
.m_bottom_23{
	margin-bottom:23px;
}
.m_bottom_25{
	margin-bottom:25px;
}
.m_bottom_30{
	margin-bottom:30px;
}
.m_bottom_35{
	margin-bottom:35px;
}
.m_bottom_40{
	margin-bottom:40px;
}
.m_bottom_45{
	margin-bottom:45px;
}
.m_bottom_50{
	margin-bottom:50px;
}
.m_bottom_55{
	margin-bottom:55px;
}
.m_bottom_70{
	margin-bottom:70px;
}
.m_top_0{
	margin-top:0px !important;
}
.m_top_5{
	margin-top:5px;
}
.m_top_8{
	margin-top:8px;
}
.m_top_10{
	margin-top:10px;
}
.m_top_15{
	margin-top:15px;
}
.m_top_20{
	margin-top:20px;
}
.m_top_35{
	margin-top:35px;
}
.m_top_45{
	margin-top:45px;
}
.p_bottom_0{
	padding-bottom:0 !important;
}
.p_top_0{
	padding-top:0 !important;
}
.tt_uppercase{
	text-transform: uppercase;
}
.db_centered{
	margin-left:auto;
	margin-right:auto;
}
.container3d{
	-webkit-transform-style:preserve-3d;
	-moz-transform-style:preserve-3d;
	-o-transform-style:preserve-3d;
	-ms-transform-style:preserve-3d;
	transform-style:preserve-3d;
	-webkit-transform:perspective(600);
	-moz-transform:perspective(600);
	-o-transform:perspective(600);
	-ms-transform:perspective(600);
	transform:perspective(600);
	perspective:600px;
}
.tr_all,
.popup_wrap:before,
.popup_wrap:after,
.owl-pagination > div{
	-webkit-transition:all .5s ease;
	-moz-transition:all .5s ease;
	-o-transition:all .5s ease;
	transition:all .5s ease;
	-webkit-animation-duration:.5s;	
	-moz-animation-duration:.5s;	
	-o-animation-duration:.5s;	
	animation-duration:.5s;	
}
.tr_all_long{
	-webkit-transition:all 1.1s ease;
	-moz-transition:all 1.1s ease;
	-o-transition:all 1.1s ease;
	transition:all 1.1s ease;
	-webkit-animation-duration:1.1s;	
	-moz-animation-duration:1.1s;	
	-o-animation-duration:1.1s;	
	animation-duration:1.1s;
}
.tr_all_medium{
	-webkit-transition:all .8s ease;
	-moz-transition:all .8s ease;
	-o-transition:all .8s ease;
	transition:all .8s ease;
	-webkit-animation-duration:.8s;	
	-moz-animation-duration:.8s;	
	-o-animation-duration:.8s;	
	animation-duration:.8s;	
}
.border_none{
	border:none !important;
}
.mw_none{
	min-width:0 !important;
}
.trf_none,
.ie9 [class*="animate_f"]:not(.with_ie){
	-webkit-transform:none !important;
	-moz-transform:none !important;
	-o-transform:none !important;
	-ms-transform:none !important;
	transform:none !important;
}
.shadow_1{
	-webkit-box-shadow:0 1px 1px rgba(0,0,0,.15);
	-moz-box-shadow:0 1px 1px rgba(0,0,0,.15);
	-o-box-shadow:0 1px 1px rgba(0,0,0,.15);
	-ms-box-shadow:0 1px 1px rgba(0,0,0,.15);
	box-shadow:0 1px 1px rgba(0,0,0,.15);
}
.r_corners,
input[type="checkbox"] + label:before,
.ui-slider,.zoomWindow{
	-webkit-border-radius:4px;
	-moz-border-radius:4px;
	border-radius:4px;
}
.r_corners_2{
	-webkit-border-radius:7px;
	-moz-border-radius:7px;
	border-radius:7px;	
}
.rc_first_hr{
	-webkit-border-radius:4px 0 0 4px;
	-moz-border-radius:4px 0 0 4px;
	border-radius:4px 0 0 4px;
}
.rc_last_hr{
	-webkit-border-radius:0 4px 4px 0;
	-moz-border-radius:0 4px 4px 0;
	border-radius:0 4px 4px 0;
}
.vc_child:after{
	content:"";
	height:100%;
	display:inline-block;
	vertical-align: middle;
}
.boxed_layout{
	width:1250px;
	margin:0 auto;
}

/* -----------------------------------

	3. Colors

------------------------------------- */

/* scheme color*/
a,a:not([class*="_hover"]):hover,
.main_menu .current > a,
.md_no-touch .main_menu li:hover > a,
.md_touch .main_menu .touch_open_sub > a,
.main_menu > .current_click > a,
.sub_menu .current_click > a,
blockquote.type_2:before,
.thumbnails_container > ul > .active article > p,
.thumbnails_container > ul > li:hover article > p,
blockquote.type_2 > p:last-of-type:after,
.main_menu li:hover > a i[class|="icon"],
a:not([class*="_hover"]):hover [class*="icon_wrap"][class*="color_"],
a:not([class*="_hover"]):hover [class|="icon"][class*="color_"],
.categories_list li:hover > a,.side_menu_list li:hover > a,.categories_list li:hover > a > *,.side_menu_list li:hover > a > *,
.color_scheme,.color_scheme_hover:hover,.color_scheme_hover:hover [class*="color_"],
.dropdown_2_container.login:hover > [class*="icon_wrap_"] > [class|="icon"]{
	color:#41bedd;
}
mark,
#open_switcher,
.bg_color_blue,
.tabs_nav .active a,
.owl-pagination > .active,
.bg_color_blue_hover:hover,
.p_table.bg_color_blue_hover.active,
[class*="icon_wrap_"].animation_fill.color_scheme:before{
	background:#41bedd;
}
.translucent_bg_color_scheme,
.translucent_bg_color_scheme.image_fixed:before{
	background-color:rgba(65,190,221,.7);
}
.tabs_nav .active a,
.accordion_item.active,
.owl-pagination > .active,
.p_table.bg_color_blue_hover:hover,
.p_table.bg_color_blue_hover.active,
.dropdown .sub_menu,
.color_scheme_hover:not(.color_light):hover,.color_scheme_hover:hover [class*="color_"],
.dropdown_2_container.login:hover > [class*="icon_wrap_"]{
	border-color:#41bedd;
}
.dropdown_2_container.login .dropdown_2:before{
	border-bottom-color:#41bedd;
}
.dropdown_2_container.login .dropdown_2{
	border-top-color:#41bedd;
}
.tabs_nav .active + li a{
	border-left-color:#41bedd;
}
.main_menu > li > a:after{
	background:#3fb8d6;
}
/* other colors */
.color_default{
	color:#606366;
}
.color_black,
.color_black_hover:hover{
	color:#000;
}
.color_grey_light,
.accordion_item:not(.active) dt [class*="icon_wrap"]{
	color:#bdc3c7;
}
.color_grey_light_2{
	color:#d1d5d8;
}
.color_grey_light_3{
	color:#ecf0f1;
}
.color_grey_light_4{
	color:#cbd0d4;
}
.color_grey_light_5{
	color:#bfc4c8;
}
.color_grey,
.tweet_time a{
	color:#899096;
}
mark,
.color_light,
.social_icons a:hover,
.tabs_nav .active a,
.checkout_video:hover *,
.paginations .active a,
.color_light_hover:hover,
.paginations li a:hover,
.step:hover .step_counter,
.link_container:hover [class*="icon_wrap_"],
.p_table:hover > *:not([class*="button_type"]),
.p_table.active > *:not([class*="button_type"]),
.steps_nav li .animation_fill.type_2,
[class*="button_type_"].transparent:not(.color_light):hover,
[class*="button_type_"]:not(.transparent),
.animation_fill[class*="color_"]:hover,
.item_services h6:hover .animation_fill{
	color:#fff;
}
.color_light,
.p_table:hover .pt_icon,
.p_table.active .pt_icon,
.p_table:hover [class*="button_type"],
.p_table.active [class*="button_type"],
.link_container:hover [class*="icon_wrap_"]{
	border-color:#fff;
}
.color_light_2{
	color:#f7f9f9;
}
.color_dark,
.tweet_text a:hover,
.color_dark_hover:hover,
.select_list li:hover,
[class*="button_type_"].color_dark,
.breadcrumbs li a.color_default:hover{
	color:#34383d;
}
.breadcrumbs li a.color_default:hover{
	border-color:#34383d;	
}
[class*="button_type_"].color_dark,
.accordion_item:not(.active) dt [class*="icon_wrap"]{
	border-color:#bdc3c7;
}
.translucent_bg_color_dark,
.translucent_bg_color_dark.image_fixed:before{
	background:rgba(0,0,0,.7);
}
.color_grey_light[class*="icon_wrap"]{
	border-color:#d2d6d9;
}
.checkout_video:hover .video_button i[class|="icon"],
.color_purple,
.step:hover h3,
blockquote.type_3:before,
blockquote.type_3 > p:last-of-type:after,
.color_purple_hover:hover,
.category_link:hover .category_title,
.color_purple [class*="icon_wrap_"],
[class*="button_type_"]:not(.transparent).color_purple:hover{
	color:#4e4ca0;
}
.border_color_purple,
.paginations .active a,
.paginations .active:last-child a,
.paginations li a:hover,
.paginations li:last-child a:hover,
.step:hover .step_counter,
[class*="icon_wrap_"].color_purple,
.color_purple [class*="icon_wrap_"],
[class*="button_type"].color_purple,
.bg_color_purple_hover:hover,
.p_table.bg_color_purple_hover:hover,
.p_table.bg_color_purple_hover.active,
[class*="icon_wrap_"].color_purple_hover:hover,
[class*="button_type"].color_purple_hover:not(.color_light):hover{
	border-color:#4e4ca0;
}
.paginations .active + li a,
.paginations li:hover + li a{
	border-left-color:#4e4ca0;
}
.bg_color_purple,
.paginations .active a,
.paginations li a:hover,
.step:hover .step_counter,
.title_counter_type:before,
.bg_color_purple_hover:hover,
.animation_fill.color_purple:before,
.p_table.bg_color_purple_hover.active,
[class*="button_type_"].transparent.color_purple:hover,
[class*="button_type_"].color_purple:not(.transparent){
	background:#4e4ca0;
}
.translucent_bg_purple,
.translucent_bg_purple.image_fixed:before{
	background-color:rgba(78,76,160,.7);
}
.color_pink,
.color_pink_hover:hover,
.color_pink_hover:hover [class*="color_"],
[class*="button_type_"].color_pink:not(.transparent):hover,
.dropdown_2_container.shoppingcart:hover > [class*="icon_wrap_"] > [class|="icon"]{
	color:#a255c4;
}
.border_color_pink,
[class*="icon_wrap_"].color_pink,
.color_pink_hover:hover [class*="icon_wrap_"],
[class*="button_type_"].color_pink,
.p_table.bg_color_pink_hover:hover,
.p_table.bg_color_pink_hover.active,
[class*="button_type_"].color_pink_hover:not(.color_light):hover,
[class*="icon_wrap_"].color_pink_hover:hover,
.dropdown_2_container.shoppingcart:hover > [class*="icon_wrap_"]{
	border-color:#a255c4;
}
.dropdown_2_container.shoppingcart .dropdown_2:before{
	border-bottom-color:#a255c4;
}
.dropdown_2_container.shoppingcart .dropdown_2{
	border-top-color:#a255c4;
}
.bg_color_pink,
.ui-slider-range,
.bg_color_pink_hover:hover,
.p_table.bg_color_pink_hover.active,
.animation_fill.color_pink:before,
[class*="button_type_"].transparent.color_pink:hover,
[class*="button_type_"].color_pink:not(.transparent){
	background:#a255c4;
}
.color_green,
.color_green_hover:hover,
[class*="button_type_"].color_green_hover:hover,
[class*="button_type_"].color_green:not(.transparent):hover{
	color:#088f64;
}
.select_button_left,
input:not([type="submit"]).success,
[class*="button_type_"].color_green_hover:hover,
[class*="button_type_"].color_green{
	border-color:#088f64;
}
.bg_color_green,
[class*="button_type_"].color_green.transparent:hover,
[class*="button_type_"].color_green:not(.transparent){
	background:#088f64;
}
/* //---------------------------------- */

.color_green_transparent,
.color_green_transparent_hover:hover,
[class*="button_type_"].color_green_transparent_hover:hover,
[class*="button_type_"].color_green_transparent:not(.transparent):hover{
	color: #fff;
	background:#088f64;
	font-weight: 600;
}
.select_button_left,
input:not([type="submit"]).success,
[class*="button_type_"].color_green_transparent_hover:hover,
[class*="button_type_"].color_green_transparent{
	border-color:#088f64;
}
.bg_colgreen_or_transparent,
[class*="button_type_"].color_green_transparent.transparent:hover,
[class*="button_type_"].color_green_transparent:not(.transparent){
	color:#088f64;
	font-weight: 600;
	background:#cde8e0;
}


/* //---------------------------------- */


.translucent_bg_green,
.translucent_bg_green.image_fixed:before{
	background-color:rgba(39,174,96,.7);
}
.bg_color_dark_green{
	background:#00a847;
}
.color_blue,
.color_blue_hover:hover,
[class*="button_type_"].color_blue:not(.transparent):hover{
	color:#55c0db;
}
.main_menu > li > a,
#menu_button.color_blue,
[class*="icon_wrap_"].color_blue,
[class*="button_type_"].color_blue,
[class*="icon_wrap_"].color_blue_hover:hover,
[class*="button_type_"].color_blue_hover:hover{
	border-color:#55c0db;
}
[class*="button_type_"].transparent.color_blue:hover,
[class*="button_type_"].color_blue:not(.transparent),
.animation_fill.color_blue:before{
	background:#55c0db;
}
.color_red,
.required:after,
.required_l:before,
.color_red_hover:hover,
.color_red_hover:hover [class*="color_"],
[class*="button_type_"].color_red:not(.transparent):hover{
	color:#e74c3c;
}
.bg_color_red,
[class*="button_type_"].transparent.color_red:hover,
[class*="button_type_"].color_red:not(.transparent){
	background:#e74c3c;
}
input:not([type="submit"]).error,
[class*="button_type_"].color_red,
.blog_side_button[class*="border_"]:hover{
	border-color:#e74c3c;
}
.translucent_bg_red,
.translucent_bg_red.image_fixed:before{
	background-color:rgba(231,76,60,.9);
}
.color_yellow,
[class*="button_type_"].color_yellow:not(.transparent):hover{
	color:#f1c40f;
}
.color_light_green{
	color:#27ae60;
}
[class*="button_type_"].color_yellow,
.p_table.bg_color_yellow_hover:hover,
.p_table.bg_color_yellow_hover.active{
	border-color:#f1c40f;
}
.bg_color_yellow,
.bg_color_yellow_hover:hover,
.p_table.bg_color_yellow_hover.active,
[class*="button_type_"].color_yellow:not(.transparent),
[class*="button_type_"].color_yellow.transparent:hover{
	background:#f1c40f;	
}
.bg_color_orange,
.bg_color_orange_hover:hover,
.p_table.bg_color_orange_hover.active,
[class*="button_type_"].color_orange:not(.transparent),
[class*="button_type_"].color_orange.transparent:hover{
	background:#ffb347;	
}
.color_dark_blue{
	color:#3498db;
}
.bg_color_dark_blue{
	background:#3498db;
}
.bg_color_dark{
	background:#161618;
}
input:not([type="submit"]).info{
	border-color:#3498db;
}
.translucent_bg_orange,
.translucent_bg_orange.image_fixed:before{
	background-color:rgba(219,175,0,.7);
}
.color_orange{
	color:#e67e22;
}
input:not([type="submit"]).warning{
	border-color:#e67e22;
}
.bg_light,
[class*="button_type_"].transparent.color_light:hover,
.p_table:hover [class*="button_type"],
.p_table.active [class*="button_type"]{
	background:#fff;
}
.bg_light_2,
.md_no-touch .sub_menu li:hover > a,
.sub_menu .current > a,
.sub_menu .touch_open_sub > a,
table:not(.table_type_9) tr:hover,
.categories_list ul a:hover,.side_menu_list ul a:hover,
.sub_menu .current_click > a{
	background:#f7f9f9;
}
.bg_light_3,.category_link:hover .category_title,
.thumbnails_container > ul > .active,
.thumbnails_container > ul > li:hover{
	background:#ecf0f1;
}
.bg_light_4{
	background:#e3e7e9;
}
.facebook:hover,.facebook_static_color{
	background:#39599f;
	border-color:#39599f;
}
.twitter:hover,.twitter_static_color{
	background:#40bff5;
	border-color:#40bff5;	
}
.googleplus:hover,.googleplus_static_color{
	background:#eb5b4c;
	border-color:#eb5b4c;	
}
.pinterest:hover,.pinterest_static_color{
	background:#b8242a;
	border-color:#b8242a;	
}
.dribbble:hover,.dribbble_static_color{
	background:#e65992;
	border-color:#e65992;	
}
.flickr:hover,.flickr_static_color{
	background:#ff1981;
	border-color:#ff1981;	
}
.youtube:hover,.youtube_static_color{
	background:#cd322c;
	border-color:#cd322c;	
}
.vimeo:hover,.vimeo_static_color{
	background:#44bbff;
	border-color:#44bbff;	
}
.instagram:hover,.instagram_static_color{
	background:#a47a5e;
	border-color:#a47a5e;	
}
.linkedin:hover{
	background:#328dd3;
	border-color:#328dd3;	
}
.facebook_color:hover *{
	color:#39599f !important;
}
.twitter_color:hover *{
	color:#40bff5 !important;
}
.googleplus_color:hover *{
	color:#eb5b4c !important;
}
.pinterest_color:hover *{
	color:#b8242a !important;
}
.label.color_scheme{
	background:#e6f7fb;
}
.label.color_pink{
	background:#f6eef9;
}
.rss{
	background: #e67e22;
	border-color:#e67e22;
}

/* -----------------------------------

	4. Typography

------------------------------------- */

html{
	overflow-x:hidden;
}
body{
	font:400 16px/1.5em "Lato","sans-serif";
	color:#606366;
	background: transparent;
	overflow-x:hidden;
	/* height: 200px; */
	background-attachment: fixed;
}
.tr_delay,
a:not([class*="tr_all"]){
	-webkit-transition:color 0.1s ease,background-color 0.1s ease,border-color 0.1s ease;
	-moz-transition:color 0.1s ease,background-color 0.1s ease,border-color 0.1s ease;
	-o-transition:color 0.1s ease,background-color 0.1s ease,border-color 0.1s ease;
	transition:color 0.1s ease,background-color 0.1s ease,border-color 0.1s ease;
}
.tr_delay:hover,
a:not(.tr_all):hover{
	-webkit-transition:color .1s ease,background-color .1s ease,border-color .1s ease;
	-moz-transition:color .1s ease,background-color .1s ease,border-color .1s ease;
	-o-transition:color .1s ease,background-color .1s ease,border-color .1s ease;
	transition:color .1s ease,background-color .1s ease,border-color .1s ease;
}
.tr_inherit{
	-webkit-transition:inherit;
	-moz-transition:inherit;
	-o-transition:inherit;
	transition:inherit;
}
h1,.pt_price,#countdown dt{
	font-size:3.75em;
}
h2,
.first_letter_1 > .fl,
.item_statistic dt{
	font-size:3em;
}
h3,.day_of_the_month,
#countdown.type_2 dt{
	font-size:2.25em;
}
h4{
	font-size:1.375em;
}
h5{
	font-size:1.25em;
}
h6,.fs_large{
	font-size:16px;
}
h1,h2,h3,h4,h5,h6,.pt_price,
.item_statistic dt,#countdown dt,.article_stats,
.lh_ex_small,.clients.brands .owl-controls {
	line-height:1em;
}
.fs_middle,
.p_table:hover .pt_title:not(.pt_time),
.p_table.active .pt_title:not(.pt_time){
	font-size:1.875em;
}
.mega_menu_container p{
	padding-left:20px;
}
blockquote{
	padding:24px 25px 22px 50px;
	word-break:break-all;
	line-height: 1.42em;
}
blockquote.type_2{
	background:#ecf0f1;
}
blockquote.type_2:after{
	content:"";
	width:0;
	height:0;
	border-left:11px solid transparent;
	border-top:8px solid #ecf0f1;
	border-right:11px solid transparent;
	position:absolute;
	left:24px;
	bottom:-8px;
}
blockquote:before,
blockquote > p:last-of-type:after{
	font-size:50px;
	font-weight:bold;
	opacity:.5;
}
blockquote:before{
	content:open-quote;
	position:absolute;
	left:19px;
	top:33px;
}
blockquote > p:last-of-type:after{
	content:close-quote;
	display: inline-block;
	line-height: 0;
	height:0;
	vertical-align: -27px;
	margin-left:12px;
}
.first_letter_1 > .fl{
	margin:12px 10px 0 0;
}
.first_letter_2 > .fl{
	font-size:1.5em;
	border-width:1px;
	border-style:solid;
	width:40px;
	height:40px;
	padding-top:7px;
	margin:6px 10px 0 0;
}
.title_counter_type{
	padding:12px 20px 14px 70px;
	font-size:1.5em;
	position:relative;
}
.title_counter_type:before{
	content:counter(counter);
	font-style:italic;
	color:#fff;
	position:absolute;
	left:0;
	padding:12px 0;
	height:100%;
	width:50px;
	text-align:center;
	top:0;
}
.static_head_title_1{
	font-size:3.25em;
}
.bn_title{
	padding:3px 21px 3px;
	width:11.09%;
}
.title_size_1{
	font-size:2.625em;
}

/* -----------------------------------

	5. Header

------------------------------------- */

[role="banner"]{
	padding-top:3px;
	width:inherit;
	z-index:150;
}
[role="banner"] .container > a:only-child{
	margin:35px 0;
}
/* sticky */
.sticky_menu [role="banner"]{
	border-bottom-color:transparent;
	border-bottom-style:solid;
}
.gradient_line{
	display:block;
	width:100%;
	position:absolute;
	top:0;
}
.header_top_part{
	padding:6px 0 8px;
}
[role="banner"] [class*="_part"]{
	width:inherit;
}
.header_bottom_part.fixed,.sticky_part.fixed{
	position:fixed;
	top:0;
	-webkit-backface-visibility:hidden;
}
.header_bottom_part.type_2{
	padding:30px 0;
}
.header_bottom_part.type_3{
	padding:22px 0;
}
.sticky_part:after,.header_bottom_part:not([class*="type_"]):after,.header_bottom_part.type_3.sticky:after{
	content:"";
	display:block;
	position:absolute;
	height:3px;
	width:100%;
	bottom:-3px;
	left:0;
	opacity:0;
	background:url("/images/shadow.png") repeat-x;
}
.sticky_part.shadow_light:not(.fixed):after{
	opacity:.5;
}
.main_menu > li > a{
	display:block;
	padding:36px 19px 37px;
}
.main_menu.type_2 > li > a{
	padding-top:15px;
	padding-bottom:16px;
}
.main_menu.type_2 > li:first-child > a{
	padding-left:0;
}
.main_menu.type_2 > li > a:after{
	display:none;
}
.main_menu > li > a:after{
	content:"";
	display:block;
	height:2px;
	width:100%;
	position:absolute;
	opacity:0;
	left:0;
	bottom:-2px;
	-webkit-transition:opacity 1.1s ease;
	-moz-transition:opacity 1.1s ease;
	-o-transition:opacity 1.1s ease;
	transition:opacity 1.1s ease;
}
.md_no-touch .main_menu > li:hover > a:after,
.md_no-touch .main_menu > .current > a:after,
.md_touch .main_menu > .current > a:after,
.md_touch .main_menu > .touch_open_sub > a:after{
	opacity:1;
	-webkit-transition:opacity .1s ease;
	-moz-transition:opacity .1s ease;
	-o-transition:opacity .1s ease;
	transition:opacity .1s ease;
}
.main_menu > li > ul{
	top:100%;
	margin-top:2px;
}
.main_menu.type_2 > li > ul{
	margin-top:0;
}
.mega_menu_container,
.mega_menu_container .container3d .sub_menu{
	position:absolute;
	top:100%;
	padding:21px 20px;
	-webkit-transform:translateY(55px);
	-moz-transform:translateY(55px);
	-o-transform:translateY(55px);
	-ms-transform:translateY(55px);
	transform:translateY(55px);
	opacity:0;
	visibility: hidden;
}
.mega_menu_container .container3d .sub_menu{
	padding:0;
	top:-1px;
}
.sub_menu.relative,.container_zoom_image{
	z-index:0;
}
.sub_menu:not(.relative){
	border-left:1px solid #f7f7f7;
	border-right:1px solid #f7f7f7;
	border-bottom:1px solid #f7f7f7;
	min-width:127px;
	position:absolute;
	-webkit-box-shadow:0 1px 1px rgba(0,0,0,.05);
	-moz-box-shadow:0 1px 1px rgba(0,0,0,.05);
	-o-box-shadow:0 1px 1px rgba(0,0,0,.05);
	-ms-box-shadow:0 1px 1px rgba(0,0,0,.05);
	box-shadow:0 1px 1px rgba(0,0,0,.05);
	-webkit-transform:rotate3d(1,0,0,-90deg);
	-moz-transform:rotate3d(1,0,0,-90deg);
	-o-transform:rotate3d(1,0,0,-90deg);
	-ms-transform:rotate3d(1,0,0,-90deg);
	transform:rotate3d(1,0,0,-90deg);
	-webkit-transform-origin:0 0 0;
	-moz-transform-origin:0 0 0;
	-o-transform-origin:0 0 0;
	-ms-transform-origin:0 0 0;
	transform-origin:0 0 0;
}
.sub_menu a{
	padding:9px 18px;
	white-space:nowrap;
}
.sub_menu .container3d > a{
	padding-right:36px;
}
.sub_menu .sub_menu{
	left:100%;
	top:0;
	margin-left:1px;
	-webkit-transform:rotate3d(0,1,0,90deg);
	-moz-transform:rotate3d(0,1,0,90deg);
	-o-transform:rotate3d(0,1,0,90deg);
	-ms-transform:rotate3d(0,1,0,90deg);
	transform:rotate3d(0,1,0,90deg);
}
.md_no-touch .main_menu li:hover > .sub_menu,
.md_no-touch .dropdown:hover .sub_menu,
.md_touch .main_menu .touch_open_sub > .sub_menu{
	-webkit-transform:rotate3d(0,0,0,0deg);
	-moz-transform:rotate3d(0,0,0,0deg);
	-o-transform:rotate3d(0,0,0,0deg);
	-ms-transform:rotate3d(0,0,0,0deg);
	transform:rotate3d(0,0,0,0deg);
}
.ie9 .sub_menu:not(.relative){
	visibility: hidden;
}
doesnotexist:-o-prefocus, .sub_menu:not(.relative) {
  visibility: hidden;
}
doesnotexist:-o-prefocus, .main_menu li:hover > .sub_menu,.dropdown:hover .sub_menu{
	visibility:visible;
}
.ie9 .main_menu li:hover > .sub_menu,
.ie9 .dropdown:hover .sub_menu{
	visibility:visible;
}
.main_menu *,.tooltip,.tooltip_type_2,.item_services h6,
.steps_nav li [class*="icon_wrap"],.animation_fill{
	-webkit-backface-visibility:hidden;
	-moz-backface-visibility:hidden;
	-o-backface-visibility:hidden;
	backface-visibility:hidden;
}
.dropdown a img{
	margin-top:6px;
}
.dropdown > a{
	padding:6px 10px;
	display:block;
	z-index:1;
}
.dropdown .sub_menu a{
	padding:5.5px 10px;
}
.dropdown{
	z-index:15;
	margin-left:-3px;
}
.dropdown:last-child > a{
	padding-right:0;
}
.dropdown .sub_menu{
	min-width:100px;
	width:100%;
	border-top-width:2px;
	border-top-style:solid;
}

/* -----------------------------------

	6. Sliders

------------------------------------- */

.r_slider{
	width:100%;
	position:relative;
}
[class*="ios_nav_"],.ls-nav-prev,.ls-nav-next{
	position:absolute;
}
[class*="ios_nav_"],
.flexslider .flex-direction-nav a,
.simple_slideshow .flex-direction-nav a,
.tp-rightarrow.default,.tp-leftarrow.default,[class*="ls-nav-"]{
	width:50px;
	height:50px;
	cursor:pointer;
	background:url("/images/prevnext.png") no-repeat 0 1px;
	opacity:.5;
	top:50% !important;
	margin-top:-25px !important;
}
.tp-rightarrow,.simple_slideshow .flex-next,.ios_nav_next,
.flexslider .flex-next{
	background-position:1px -47px !important;
}
.ls-nav-prev{
	background-position:0 2px !important;
}
.ls-nav-next{
	background-position:3px -46px !important;
}
.tp-leftarrow:hover,
.tp-rightarrow:hover,
[class*="ios_nav_"]:hover,
.jackbox[data-group]:hover,
.popup_wrap [class*="icon_wrap"]:hover,
.simple_slideshow .flex-direction-nav a:hover,
.flexslider .flex-direction-nav a:hover,
.ls-nav-prev:hover,.ls-nav-next:hover{
	background-color:rgba(255,255,255,.2);
	opacity:1;
}
.caption iframe{
	-webkit-box-shadow:0 0 18px 0 rgba(0,0,0,.25);
	-moz-box-shadow:0 0 18px 0 rgba(0,0,0,.25);
	-o-box-shadow:0 0 18px 0 rgba(0,0,0,.25);
	-ms-box-shadow:0 0 18px 0 rgba(0,0,0,.25);
	box-shadow:0 0 18px 0 rgba(0,0,0,.25);
}
.simple_slideshow .flex-next{
	right:20px;
}
.simple_slideshow .flex-prev{
	left:20px;
}
[class*="col-"]:not(.snormal_size) .simple_slideshow .flex-direction-nav a,
.blog_isotope_item .simple_slideshow .flex-direction-nav a{
	width:36px;
	height:36px;
	margin-top:-18px !important;
	background-size:100% 200%;
}
[class*="col-"] .simple_slideshow .flex-prev,
.blog_isotope_item .simple_slideshow .flex-prev{
	background-position:0 2px;
}
[class*="col-"] .simple_slideshow .flex-next,
.blog_isotope_item .simple_slideshow .flex-next{
	background-position:2px 90% !important;
}
.slider_title_1{
	font-size:9.375em;
	line-height:.75em;
}
.slider_title_2{
	font-size:4.5em;
}
.slider_title_3{
	font-size:5em;
}
.iosslider {
	position: relative;
	padding-bottom:26.5%;
	overflow: hidden;	
	width: 100%;
	height: 0;
}
.iosslider .slider {
	width: 100%;
	height: 100%;
}
.iosslider .slider .slide {
	float: left;
	width: 100%;
}
.iosslider .slider .slide img{
	max-width:none;
	width:100%;
	height:auto;
}
.iosslider .slider .slide [class^="item"]{
	position:absolute;
	left:50%;
}
.iosslider .slider .slide .item{
	margin-left:115px;
	top:17.95%;
}
.iosslider .slider .slide .item_2{
	left:0;
	top:14.73%;
	width:100%;
}
.iosslider .slider .slide .item_3{
	margin-left:-588px;
	top:14.73%;
}
.flex_container{
	width:74.35%;
	-webkit-border-radius:4px 0 0 4px;
	-moz-border-radius:4px 0 0 4px;
	border-radius:4px 0 0 4px;
}
.thumbnails_container{
	width:25.64%;
	-webkit-border-radius:0 4px 4px 0;
	-moz-border-radius:0 4px 4px 0;
	border-radius:0 4px 4px 0;
}
.flexslider .slides > li > iframe{
	width:100%;
}
.flexslider .flex-direction-nav a{
	top:auto !important;
	bottom: 20px;
	margin-top:0 !important;
}
.flexslider .flex-next{
	right:20px !important;
}
.flexslider .flex-prev{
	right:80px !important;
	left:auto !important;
}
#layerslider_video [class^="ls-nav-"]{
	top:50% !important;
	margin-top:-25px !important;
	background-image:url("/images/prevnext.png");
}

/* -----------------------------------

	7. Tabs,Accordions,Toggles

------------------------------------- */

.tabs_nav li:first-child a{
	-webkit-border-radius:4px 0 0 4px;
	-moz-border-radius:4px 0 0 4px;
	border-radius:4px 0 0 4px;
}
.tabs_nav li:last-child a{
	-webkit-border-radius:0 4px 4px 0;
	-moz-border-radius:0 4px 4px 0;
	border-radius:0 4px 4px 0;
}
.tabs_nav a{
	padding:9px 20px 10px;
}
.tabs_nav.type_2 a{
	padding:5px 28px;
}
.tabs_nav.type_3 a{
	padding-left:14px;
	padding-right:14px;
}
.tabs_nav a,
.border_grey,
.accordion_item,
.ui-slider{
	border:1px solid #bdc3c7;
}
.tabs_nav li:not(:last-child) a{
	border-right:none;	
}
.accordion_item dt{
	padding:9px 50px 10px 19px;
	cursor:pointer;
}
.accordion_item dd{
	padding:7px 20px 23px;
}
.accordion_item dt [class*="icon_wrap"]{
	position:absolute;
	right:19px;
	top:50%;
	margin-top:-11px;
	font-size:16px;
}

/* -----------------------------------

	8. Carousels

------------------------------------- */

.brands_carousel .owl-item{
	padding:0 15px;
}
[class*="fp_nav_"]{
	position:absolute;
	top:38.5%;
}
.ios_nav_prev,
.lp_nav_prev,
.c_nav_prev.nav_type_3,
.fp_nav_prev,.pc_nav_prev,.rp_nav_prev,
.extended.simple_slideshow .flex-prev{
	left:30px;
}
.ios_nav_next,
.lp_nav_next,
.c_nav_next.nav_type_3,
.fp_nav_next,.pc_nav_next,.rp_nav_next,
.extended.simple_slideshow .flex-next{
	right:30px;
}
.owl-pagination > div{
	width:12px;
	height:12px;
	border:1px solid rgba(255,255,255,.5);
	float:left;
}
.owl-pagination > div:not(:last-child){
	margin-right:8px;
}
.bg_light_2 .owl-pagination > div:not(.active),
.clients.brands:not(.with_video) .owl-pagination > div:not(.active){
	border-color:#bfc4c8;
}
[class*="pc_nav_"],[class*="rp_nav"],[class*="fproducts_nav_"]:not(.fn_type_2),[class*="ln_nav_"],
[class*="fp_nav_"].nav_type_2,[class*="lp_nav_"]{
	position:absolute;
	top:50%;
	margin-top:-18px;
}
[class*="c_nav_"].nav_type_2{
	position:absolute;
	top:38px;
}
[class*="c_nav_"].nav_type_3{
	position:absolute;
	top:50%;
	margin-top:20px;
}
.twc_prev,.twc_next{
	position:absolute;
	top:50%;
	margin-top:-12px;
}
.twc_prev{
	left:0;
}
.twc_next{
	right:0;
}
.related_projects .owl-item,
.wfilter_carousel .owl-item{
	padding-bottom:75px;
}
[class*="rp_nav"]{
	margin-top:-47px;
}
[class*="fproducts_nav_"]:not(.fn_type_2){
	margin-top:-90px;
}
[class*="ln_nav_"]{
	top:78px;
}
.fproducts_nav_prev,.ln_nav_prev{
	left:-50px;
}
.fproducts_nav_next,.ln_nav_next{
	right:-50px;
}
.c_nav_prev.nav_type_2{
	left:-54px;
}
.c_nav_next.nav_type_2{
	right:-54px;
}
.boxed_layout .fproducts_nav_prev,.boxed_layout .ln_nav_prev{
	left:-35px;
}
.boxed_layout .fproducts_nav_next,.boxed_layout .ln_nav_next{
	right:-35px;
}
.boxed_layout .c_nav_next.nav_type_2{
	right:-32px;
}
.boxed_layout .c_nav_prev.nav_type_2{
	left:-32px;
}
.featured_products .owl-item{
	padding-bottom:1px;
}
.thumbnails_carousel{
	width:80px;
}
[class*="fp_nav_"].nav_type_2{
	margin-top:15px;
}
#breaking_news{
	width:87.03%;
	margin-top:3px;
}
.photo_carousel img{
	min-width:100%;
}

/* -----------------------------------

	9. Twitter Feed

------------------------------------- */

.single{
	padding:0 34px;
}
.tweet_list li{
	padding-left:35px;
}
.single .tweet_list li {
	padding-left:0;
}
.tweet_list li > [class*="icon_wrap_"]{
	position:absolute;
	left:0;
	top:4px;
}

/* -----------------------------------

	10. Lists and Navigations

------------------------------------- */

.hr_list > li{
	float:left;
}
.m_left_0,.hr_list > li:first-child{
	margin-left:0;
}
.hr_list > li:last-child{
	margin-right:0;
}
.hr_list:after,.clearfix:after,
.clients.brands .owl-pagination:after{
	content:"";
	display:block;
	clear:both;
	width:100%;
}
.vr_list li:not(:first-child),
.select_list li:not(:first-child){
	border-top:1px solid #ecf0f1;
}
.vr_list:not(.sub_menu) li{
	padding:9px 5px 9px 35px;
}
.social_icons{
	padding-top:2px;
}
.breadcrumbs{
	margin-top:4px;
}
.breadcrumbs li a,
.project_description h4 a,
.project_description .category a{
	border-bottom:1px solid transparent;
}
.breadcrumbs li a:hover,
.project_description h4 a:hover,
.project_description .category a:hover{
	color:#646464;
	border-color:#646464;
	text-decoration:none !important;
}
.dotted_list li:not(:last-child):after{
	content:".";
	font-size:25px;
	position:absolute;
	display:block;
	width:3px;
	height:3px;
	line-height: 0;
	right:-10px;
	top:50%;
	margin-top:-8px;
}
.dotted_list.type_2 li:not(:last-child):after{
	right:-17px;
}
.vr_list_type_2 li,
.vr_list_type_4 li a{
	padding-left:28px;
}
.vr_list_type_2 li [class*="icon_wrap"],
.vr_list_type_4 li a [class*="icon_wrap"]{
	margin:4px 0 0 -28px;
}
.vr_list_type_2 li > [class|="icon"]{
	margin-left:-28px;
}
.vr_list_type_3 li:before{
	content: counter(counter) ".";
	display:inline-block;
	margin-right:3px;
}
.vr_list_type_5 li{
	padding-left:37px;
}
.vr_list_type_5 li .icon_wrap_size_1{
	margin-left:-37px;
	margin-top:2px;
}
.vr_list_type_5 li [class*="button_type_"]{
	margin-left:-37px;
}
.paginations li a{
	width:39px;
	height:35px;
	line-height: 28px;
	display:block;
	/* border-top:1px solid #bdc3c7; */
	/* border-bottom:1px solid #bdc3c7; */
	/* border-left:1px solid #bdc3c7; */
}
.paginations li:last-child a{
	/* border-right:1px solid #bdc3c7; */
}
.steps_nav li .container3d{
	width:140px;
	height:140px;
}
.steps_nav li [class*="icon_wrap"]{
	position:absolute;
}
.step_counter:before,
.steps_nav li .step_num:before{
	content:counter(counter);
	font-style:italic;
	font-weight:300;
	display:inline-block;
}
.steps_nav li .step_num:before{
	font-size:1.36em;
	margin-top:-10px;
}
.steps_nav .active .step_num,
.steps_nav li [class*="icon_wrap"]:not(.step_num){
	-webkit-transform:rotate3d(1,0,0,180deg);
	-moz-transform:rotate3d(1,0,0,180deg);
	-o-transform:rotate3d(1,0,0,180deg);
	-ms-transform:rotate3d(1,0,0,180deg);
	transform:rotate3d(1,0,0,180deg);
}
.steps_nav .active [class*="icon_wrap"]:not(.step_num){
	-webkit-transform:rotate3d(1,0,0,0deg);
	-moz-transform:rotate3d(1,0,0,0deg);
	-o-transform:rotate3d(1,0,0,0deg);
	-ms-transform:rotate3d(1,0,0,0deg);
	transform:rotate3d(1,0,0,0deg);
}
.steps_nav li:not(:last-child):after{
	content:"";
	display:block;
	width:22px;
	height:40px;
	position:absolute;
	right:-42px;
	top:50%;
	margin-top:-20px;
	background:url("/images/icon-right.png") no-repeat;
}
.sitemap_list ul{
	padding-left:30px;
}
.flickr_list{
	margin:0 -7.5px;
}
.flickr_list li,#dribbble_feed li{
	margin:0 7.5px 15px;
}
.flickr_list img{
	width:80px;
	height:80px;
}
.stripe_list li:not(:last-child){
	position:relative;
	margin-right:19px;
}
.stripe_list li:not(:last-child)::after{
	content:"";
	display:block;
	width:1px;
	height:11px;
	background:#c7cccf;
	position:absolute;
	top:50%;
	margin-top:-4px;
	right:-10px;
}
.contact_info_list{
	padding-top:6px;
}
.added_items_list{
	padding:20px 0 0 2px;
}
.added_items_list li{
	padding-right:35px;
}
.added_items_list li > [class|="icon"]{
	cursor:pointer;
	position:absolute;
	right:0px;
	top:-5px;
	display:block;
	width:16px;
	height:16px;
}
.added_items_list.type_2{
	margin-top:-17px;
}
.added_items_list.type_2 li{
	padding:17px 10px 17px 0;
}
.added_items_list.type_2 li > [class|="icon"]{
	top:15px;
}
.fp_item .rating_list{
	margin-top:-5px;
}
.rating_list li:not(:last-child){
	margin-right:2px;
}
.rating_list li{
	width:15px;
	height:15px;
	cursor:pointer;
}
.rating_list li > [class|="icon"]{
	position:absolute;
	left:0;
}
.categories_list li:not(:first-child) > a,
.categories_list ul{
	border-top:1px solid #ecf0f1;
}
.categories_list{
	margin-top:-6px;
}
.categories_list li a{
	padding:5px 0 7px;
}
.categories_list ul a{
	padding:4px 0 6px 28px;
}
.categories_list ul ul a{
	padding-left:43px;
}



.side_menu_list li:not(:first-child) > a,
.side_menu_list ul{
	border-top:1px solid #ecf0f1;
}
.side_menu_list{
	margin-top:-6px;
}
.side_menu_list li a{
	padding:5px 0 7px;
}
.side_menu_list ul a{
	padding:4px 0 6px 28px;
}
.side_menu_list ul ul a{
	padding-left:43px;
}



.helper-list{
	height:0;
	overflow: hidden;
}
.news_list > li:first-child{
	padding-top:0;
}
.news_list > li{
	padding:20px 0 20px 20px;
}
.thumbnails_container{
	padding:10px 0;
}
.thumbnails_container > ul > li{
	padding:20px 15px 20px 20px;
	cursor: pointer;
}

/* -----------------------------------

	11. Buttons

------------------------------------- */

.search_buttons{
	width:40px;
	height:40px;
}
.search_buttons > button{
	position:absolute;
	top:0;
	left:0;
}
.search_buttons > .active{
	z-index:1;
}
[class*="button_type"]{
	border-width:1px;
	border-style:solid;
	padding-left:29px;
	padding-right:29px;
	backface-visibility:hidden;
}
.button_type_1{
	padding-top:2px;
	padding-bottom:2px;
}
.button_type_2{
	padding-top:4px;
	padding-bottom:5px;
}
.button_type_3{
	padding-top:10px;
	padding-bottom:9px;
}
.button_type_4{
	padding-top:19px;
	padding-bottom:20px;
}
.button_type_5{
	min-height:40px;
	padding:7px 18px 7px;
}
.button_type_6{
	padding:2px 10px;
	height:40px;
	min-width:40px;
}
.button_type_7{
	padding:2px 10px;
	height:45px;
}
[class*="button_type"]:not(.transparent):hover,
[class*="select_button_"]:hover,.checkout_video:hover .video_button{
	background:transparent;
}
.popup_buttons{
	position:absolute;
	top:50%;
	left:50%;
	z-index:3;
	opacity:0;
	-webkit-transform:translate(-50%,-50%);
	-moz-transform:translate(-50%,-50%);
	-o-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	transform:translate(-50%,-50%);
}
#back_to_top{
	position:fixed;
	top:59.6%;
	z-index:5;
}
#open_side_menu{
	position:fixed;
	top:30px;
	right:-40px;
	cursor:pointer;
	z-index:25;
	opacity:0;
}
.side_main_menu > li > ul,
.side_main_menu > li > ul ul{
	padding-left:10px;
}
#menu_button{
	width:50px;
	height:40px;
	border-width:1px;
	border-style:solid;
	font-size:30px;
}
[class*="page_button"],.mini_side_offset{
	padding-left:18px;
	padding-right:18px;
}
.tags_list [class*="button_type"]{
	padding-left:10px;
	padding-right:10px;
}
.blog_side_button{
	height:70px;
	width:70px;
}
.blog_side_button [class|="icon"]{
	font-size:30px;
}
.calendar_nav{
	padding:8px 0 7px;
}
.calendar_nav [data-calendar-nav]{
	position: absolute;
	top:50%;
	margin-top:-9px;
}
[data-calendar-nav="prev"]{
	left:10px;
}
[data-calendar-nav="next"]{
	right:10px;
}
#back_to_top [class|="icon"]{
	margin-top:-3px;
}
.color_button{
	border:1px solid #bec3c7;
	width:30px;
	height:30px;
	padding:3px;
	background-clip:content-box;
}
.color_button:hover{
	border-color:#363a3f;
}
.quantity > button{
	width:29px;
	height:33px;
}
.open_product{
	position:relative;
	margin:-50px 10px 0 0;
	z-index:110;
	width:40px;
	height:40px;
	border-color: #15488a;
	line-height: 40px;
	opacity: .7;	
}
.open_product i[class|="icon"]{
	color: #15488a;
	font-size:1.9em;
	display: inline-block;
	margin:-3px 0 0 -2px;
}
.open_product:hover{
	opacity:.7;
	background:rgba(52,56,61,.2) !important;
}
[class*="select_button_"]{
	padding:10px 15px 9px;
	min-width:214px;
	border-style:solid;
	-webkit-transition:background-color .5s ease,color .5s ease,border-color .5s ease !important;
	-moz-transition:background-color .5s ease,color .5s ease,border-color .5s ease !important;
	transition:background-color .5s ease,color .5s ease,border-color .5s ease !important;
}
.select_button_left{
	-webkit-border-radius:4px 0 0 4px;
	-moz-border-radius:4px 0 0 4px;
	border-radius:4px 0 0 4px;
	border-width:1px 0px 1px 1px;
	/*margin-right:15px;*/
}
.select_button_right{
	-webkit-border-radius:0 4px 4px 0;
	-moz-border-radius:0 4px 4px 0;
	border-radius:0 4px 4px 0;
	border-width:1px 1px 1px 0px;
	border-color:#fff;
	/*margin-left:15px;*/
}
[class*="select_button_"]:before,
[class*="select_button_"]:after{
	content:"";
	position:absolute;
	top:-1px;
	display:block;
	width:14px;
	height:45px;
	-webkit-transition:opacity .5s;
	-moz-transition:opacity .5s;
	-o-transition:opacity .5s;
	transition:opacity .5s;
}
.select_button_left:after,.select_button_left:before{
	background:url("/images/select_button_left.png") no-repeat;
	right:-14px;
}
.select_button_right:after,.select_button_right:before{
	background:url("/images/select_button_right.png") no-repeat;
	left:-14px;
}
[class*="select_button_"]:before{
	background-position:0 -45px;
}
.or{
	padding:10px 8px 9px;
	font-size:0.625em;
}
.video_button{
	width:60px;
	height:60px;
	border-width:2px;
	border-style:solid;
	line-height:56px;
}
.video_button:after{
	border-color:#4e4ca0 !important;
}
.video_button:before{
	background:#fff !important;
}
.video_button i[class|="icon"]{
	font-size:2.2em;
	margin:0px 0 0 5px;
}

/* -----------------------------------

	12. Tables

------------------------------------- */

table td,
table th{
	padding:9px 18px 10px;
	border:1px solid #bdc3c7;
}
table td{
	padding-top:9px;
	padding-bottom:9px;
}
table tr:first-child td,
table tr:first-child th{
	border-top:none;
}
table tr:last-child td{
	border-bottom:none;
}
table tr td:first-child,
table tr th:first-child{
	border-left:none;
}
table tr td:last-child,
table tr th:last-child{
	border-right:none;
}
.three_columns th{
	width:33.3333%;
}
.p_table{
	padding:35px 19px 39px;
	-webkit-transition:background-color .5s ease,border-color .5s ease;
	-moz-transition:background-color .5s ease,border-color .5s ease;
	-o-transition:background-color .5s ease,border-color .5s ease;
	transition:background-color .5s ease,border-color .5s ease;
}
.p_table:hover,
.p_table.active{
	margin:-10px -10px 0;
	padding:36px 29px 50px;
}
.border_light{
	border:1px solid #fff;
}
.pt_icon{
	width:100px;
	height:100px;
	font-size:39px;
	border:1px solid transparent;
}
.pt_title{
	font-size:1.375em;
	padding-bottom:2px;
}
.pt_icon,.pt_title,.pt_price,.price_icon{
	-webkit-transition:color .5s ease;
	-moz-transition:color .5s ease;
	-o-transition:color .5s ease;
	transition:color .5s ease;
}
.pt_time{
	padding-bottom:3px;	
}
.price_icon{
	font-size:1.8em;
	margin-top:13px;
}
.p_table:hover .pt_icon,
.p_table.active .pt_icon{
	font-size:50px;
}
.calendar{
	border-collapse: separate;
}
.calendar th,
.calendar td{
	width:14.28%;
	border:none;
	vertical-align: middle;
}
.calendar .bg_light_2 th{
	border-top:1px solid #bdc3c7;
	border-bottom:1px solid #bdc3c7;
}
.calendar th{
	padding:3px 0 2px;
}
.calendar td{
	padding:6.5px 0;
}
.calendar td.active span{
	width:30px;
	padding:2px 0;
}
.table_type_2 td:not([colspan]),.table_type_4 td{
	padding:19px;
}
.table_type_8 td:not([colspan]){
	padding:17px 19px 0;
	height:120px;
}
.table_type_5 tr:first-child td:not(:first-child){
	padding:19px 19px 6px;
}
.table_type_2 tr:first-child th:nth-child(2){
	width:330px;
}
.table_type_2 tr:first-child th:nth-child(3),
.table_type_2 tr:first-child th:nth-child(4),
.table_type_2 tr:first-child th:nth-child(7),
.table_type_8 tr:first-child th:nth-child(4),
.table_type_8 tr:first-child th:nth-child(7){
	width:100px;
}
.table_type_2 tr:first-child th:first-child,
.table_type_2 tr:first-child th:nth-child(5),
.table_type_4 tr:first-child th:nth-child(3),
.table_type_8 tr:first-child th:nth-child(3){
	width:140px;
}
.table_type_2 tr:first-child th:nth-child(6),
.fe_width_3{
	width:90px;
}
.table_type_2 tr:first-child th:nth-child(8){
	width:120px;
}
.table_type_3 td:first-child,
.table_type_4 tr:first-child th:first-child,
.table_type_5 td:first-child,
.table_type_8 tr:first-child th:nth-child(2){
	width:200px;
}
.table_type_4 tr:first-child th:nth-child(2){
	width:300px;
}
.table_type_5 td:not(:first-child){
	width:325px;
}
.table_type_6 td:first-child,
.table_type_6 td:nth-child(2),
.table_type_7 td:first-child{
	width:150px;
}
.table_type_6 td:nth-child(3){
	width:400px;
}
.table_type_8 tr:first-child th:first-child,
.table_type_8 tr:first-child th:nth-child(6){
	width:80px;
}
.table_type_8 tr:first-child th:nth-child(5){
	width:65px;
}
.table_type_9 td{
	padding:0;
	border:none;
}
.table_type_9 td:first-child{
	width:110px;
}

/* -----------------------------------

	13. Icons

------------------------------------- */

i[class|="icon"]{
	line-height: inherit;
}
[class*="icon_wrap"] i[class|="icon"]{
	display:block;
	width:inherit;
	height:inherit;
	margin:-1px 0 0 -1px;
	backface-visibility:hidden;
}
[class*="icon_wrap"]{
	border-width:1px;
	border-style:solid;
	text-align:center;
}
[class*="icon_wrap"].type_2{
	border-width:2px;
}
[class*="icon_wrap"].d_inline_m{
	margin-top:-2px;
}
.icon_wrap_size_1 [class*="icon-mail-alt"]{
	font-size:10px;
}
.icon_wrap_size_1 [class*="icon-location"]{
	font-size:16px;
}
.icon_wrap_size_0{
	width:18px;
	height:18px;
	line-height: 18px;
	font-size:12px;
}
.icon_wrap_size_1{
	width:22px;
	height:22px;
	line-height: 22px;
	font-size:12px;
}
.icon_wrap_size_2{
	width:40px;
	height:40px;
	line-height: 40px;
	font-size:23.3px;
}
.icon_wrap_size_3{
	width:50px;
	height:50px;
	line-height: 50px;
	font-size:23px;
}
.icon_wrap_size_4{
	width:36px;
	height:36px;
	line-height: 36px;
	font-size:20px;
}
.icon_wrap_size_5{
	width:24px;
	height:24px;
	line-height: 24px;
	font-size:13px;
}
.icon_wrap_size_6{
	width:100px;
	height:100px;
	line-height: 100px;
	font-size:40px;
}
.icon_wrap_size_7{
	width:140px;
	height:140px;
	line-height: 140px;
	font-size:53px;
}
.main_menu > li [class|="icon"]{
	font-size:13px;
	margin-left:4px;
}
.main_menu > li {
	z-index:10;
}
.main_menu.type_2 > li > .sub_menu:before,
.main_menu.type_2 > li > .sub_menu > li:first-child > .sub_menu:before,
.main_menu > li > .mega_menu_container:before{
	content:"";
	display: block;
	width:100%;
	height:3px;
	position: absolute;
	top:0;
	z-index:2;
	left:0;
	background:url("/images/shadow.png") repeat-x;
}
.sub_menu li a i[class|="icon"]{
	color:#939597;
	position:absolute;
	right:17px;
	top:50%;
	-webkit-transform:translateY(-43%);
	-moz-transform:translateY(-43%);
	-o-transform:translateY(-43%);
	-ms-transform:translateY(-43%);
	transform:translateY(-43%);
}
.vr_list:not(.sub_menu) li [class|="icon"]{
	position:absolute;
	top:9px;
	left:17px;
	font-size:9px;
}
.item_services h6 [class*="icon_wrap_"]{
	position:absolute;
	left:-70px;
	top:-6px;
}
.animation_fill:after,.animation_fill:before{
	content:"";
	position:absolute;
	width:inherit;
	height:inherit;
	z-index:-1;
	top:-1px;
	left:-1px;
	-webkit-border-radius:inherit;
	-moz-border-radius:inherit;
	border-radius:inherit;
	-webkit-transform:scale(.25);
	-moz-transform:scale(.25);
	-o-transform:scale(.25);
	-ms-transform:scale(.25);
	transform:scale(.25);
	opacity:0;
	-webkit-transition:-webkit-transform .8s ease,opacity .8s ease;
	-moz-transition:transform .8s ease,opacity .8s ease;
	-o-transition:transform .8s ease,opacity .8s ease;
	transition:transform .8s ease,opacity .8s ease;
}
.animation_fill:after{
	border-width:inherit;
	border-style: solid;
	border-color: #fff;
	-webkit-transform:scale(.25);
	-moz-transform:scale(.25);
	-o-transform:scale(.25);
	-ms-transform:scale(.25);
	transform:scale(.25);
	-webkit-transition-delay:.1s;
	-moz-transition-delay:.1s;
	-o-transition-delay:.1s;
	transition-delay:.1s;
}
.animation_fill.type_2:after,.animation_fill.type_2:before{
	top:-2px;
	left:-2px;
}
.animation_fill:hover:after,
.steps_nav li .animation_fill:after,
.item_services h6:hover .animation_fill:after{
	-webkit-transform:scale(.85);
	-moz-transform:scale(.85);
	-o-transform:scale(.85);
	-ms-transform:scale(.85);
	transform:scale(.85);
	opacity:1;
}
.checkout_video:hover .animation_fill.type_2:after,
.steps_nav li .animation_fill.type_2:after,
.animation_fill.type_2:hover:after{
	-webkit-transform:scale(.92) !important;
	-moz-transform:scale(.92) !important;
	-o-transform:scale(.92) !important;
	-ms-transform:scale(.92) !important;
	transform:scale(.92) !important;
}
.animation_fill:hover:before,
.checkout_video:hover .animation_fill.type_2:before,
.steps_nav li .animation_fill.type_2:before,
.item_services h6:hover .animation_fill:before{
	-webkit-transform:scale(1) !important;
	-moz-transform:scale(1) !important;
	-o-transform:scale(1) !important;
	-ms-transform:scale(1) !important;
	transform:scale(1) !important;
	opacity:1 !important;
}
.checkout_video:hover .animation_fill.type_2:after,
.checkout_video:hover .animation_fill.type_2:before{
	opacity:1 !important;
}
.popular{
	position:absolute;
	top:-6px;
	right:-6px;
	z-index:2;
}
.paginations i[class|="icon"],.product_current_price{
	font-size:1.5em;
}
.like_project .icon-heart-empty-1{
	font-size:22px;
}
.login_icon{
	position:absolute;
	top:50%;
	right:20px;
	-webkit-transform:translateY(-50%);
	-moz-transform:translateY(-50%);
	-o-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
}
.login_form .login_icon{
	right:15px;
}
.icon-heart.fs_large{
	font-size:1.27em;
}
.paginations i.icon-layout,
.paginations i.icon-menu{
	font-size:24px;
}
[class*="button_type_"].mini_side_offset .icon-check.fs_large{
	font-size:25px;
	margin-top:-2px;
}
[class*="fproducts_nav"].fn_type_2 i[class|="icon"],
[class*="web_design_"] i[class|="icon"]{
	font-size:20px;
	margin-top:-2px;
}
.thumbnails_carousel i[class|="icon"]{
	font-size:19px;
}
.thumbnails_carousel .flex-direction-nav li:first-child i[class|="icon"]{
	display: inline-block;
	margin-top:-2px;
}

/* -----------------------------------

	14. Main Content Elements

------------------------------------- */

hr{
	height:1px;
	background:#ecf0f1;
}
.bg_gradient,
.divider_type_2,.gradient_line,#qLbar{
	background:#70269f;
	background:-webkit-gradient(linear,left top,right top,from(#70269f),to(#35eef6));
	background:-webkit-linear-gradient(left,#70269f,#35eef6);
	background:-moz-linear-gradient(left,#70269f,#35eef6);
	background:-o-linear-gradient(left,#70269f,#35eef6);
	background:-ms-linear-gradient(left,#70269f,#35eef6);
	background:linear-gradient(left,#70269f,#35eef6);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#70269f', endColorstr='#35eef6',GradientType=1 );
}
.divider_type_2,.gradient_line{
	height:3px;
}
.divider_type_3{
	background:#d9dee0;
}
.divider_type_4{
	background:rgba(236,240,241,.07);
}
.divider_type_5,.p_table:hover hr,.p_table.active hr{
	background:rgba(255,255,255,.5);
}
.show,
.translucent:hover,
#thumbnails .active,
.search_buttons > .active,
.popup_wrap:hover .popup_buttons,
[class*="select_button"]:hover:before,
.popup_wrap:hover .project_description,
.rating_list .active > [class|="icon"]:last-child,
.accordion_item.active dt [class*="icon_wrap"]:first-child,
.wfilter_carousel .owl-item:hover .popup_wrap .popup_buttons,
.related_projects .owl-item:hover .popup_wrap .popup_buttons{
	opacity:1;
}
.hide,
.search_buttons > button,
[class*="select_button"]:before,
[class*="select_button"]:hover:after,
.fp_item:hover .fp_images > img:first-child,
.rating_list li > [class|="icon"]:last-child,
.rating_list .active > [class|="icon"]:first-child,
.accordion_item dt [class*="icon_wrap"]:first-child,
.accordion_item.active dt [class*="icon_wrap"]:nth-child(2){
	opacity:0;
}
.section_offset{
	padding: 33px 0 67px;
}
.kent_section_offset{
	padding: 20px 0 6px;
}
.section_offset_2{
	padding:70px 0 60px;
}
.section_offset_3{
	padding:30px 0 23px;
}
.page_title,.coming_soon{
	padding:100px 0 104px;
}
.coming_soon.type_2{
	padding:55px 0;
	min-height:none;
	min-height:0px;
}
.page_title_2{
	padding:30px 0;
}
.page_title.type_3{
	padding:130px 0;
	max-height:none;
}
.iframe_video_wrap{
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56%;
}
.iframe_video_wrap.type_2{
	padding-bottom:61%;
}
.video_wrap{
	position:absolute;
	width:100%;
	height:0;
	padding-bottom:57%;
	left:0;
	top:0;
	z-index:-1;
}
.video_wrap > video,
.iframe_video_wrap > iframe,
.slider_video_wrap > iframe{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.video_wrap > video{
	top:-10%;
}
.item_services{
	padding-left:70px;
}
.image_bg_1{background:rgba(236,240,241,.4) url("/images/image_bg_1.png") 0 -150px;}
.image_bg_2{background:url("/images/image_bg_2.jpg") no-repeat;}
.image_bg_3{background:url("/images/image_bg_3.jpg") no-repeat;}
.image_bg_4{background:url("/images/image_bg_5.jpg") no-repeat;}
.image_bg_5{background:url("/images/image_bg_8.jpg") no-repeat;}
.image_bg_6{background:url("/images/home_img_13.jpg") no-repeat;}
.image_bg_7{background:url("/images/image_bg_13.jpg") no-repeat;}
.image_bg_8{background:url("/images/home_img_19.jpg") no-repeat;}
.image_bg_9{background:url("/images/image_bg_4.jpg") no-repeat;}
.image_bg_10{background:url("/images/image_bg_6.jpg") no-repeat;}
.image_bg_11{background:url("/images/image_bg_7.jpg") no-repeat;}
.image_bg_12{background:url("/images/image_bg_9.jpg") no-repeat;}
.image_bg_13{background:url("/images/image_bg_10.jpg") no-repeat;}
.image_bg_14{background:url("/images/image_bg_11.jpg") no-repeat;}
.image_bg_15{background:url("/images/image_bg_12.jpg") no-repeat;}
.image_bg_16{background:url("/images/home_img_18.jpg") no-repeat;}
[class*="image_bg_"]{
	background-attachment:fixed;
	background-size:cover;
}
[class*="translucent_bg"].image_fixed{
	background-color:transparent;
}
[class*="translucent_bg"][class*="image_bg_"].image_fixed{
	background-attachment: scroll;
}
[class*="translucent_bg"].image_fixed.type_2:before{
	z-index:0;
}
.darkness{
	background-size:auto auto;
	padding:153px 0;
}
.darkness.type_2{
	padding:135px 0;
}
.darkness:after{
	background:rgba(0,0,0,.5);
}
.darkness.type_3:after{
	background:rgba(0,0,0,.4);	
}
.darkness.type_4{
	padding:96px 0;
	background-size:cover;
}
[class*="translucent_bg"].image_fixed:before,.darkness:after{
	content:"";
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	z-index:-1;
}
[class*="translucent_bg"].image_fixed > img.page_404{
	position:absolute;
	top:0;
	left:0;
	z-index:-2;
	left:50%;
	-webkit-transform:translateX(-50%);
	-moz-transform:translateX(-50%);
	-o-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
}
.popup_wrap:before,
.popup_wrap:after{
	content:"";
	display:block;
	position:absolute;
	width:50%;
	height:100%;
	left:0;
	top:0;
	background:rgba(0,0,0,.3);
	-webkit-transform:translateX(-100%);
	-moz-transform:translateX(-100%);
	-o-transform:translateX(-100%);
	-ms-transform:translateX(-100%);
	transform:translateX(-100%);
	z-index:1;
}
.popup_wrap:after{
	-webkit-transform:translateX(100%);
	-moz-transform:translateX(100%);
	-o-transform:translateX(100%);
	-ms-transform:translateX(100%);
	transform:translateX(100%);
	left:auto;
	right:0;
}
[class*="tooltip"]:not(.tooltip_container){
	position:absolute;
	white-space: nowrap;
	opacity:0;
	visibility: hidden;
}
.tooltip{
	background:#e6f7fb;
	margin-bottom:10px;
	padding:4px 9px;
}
.tooltip,.tooltip_type_2.top{
	bottom:100%;
	left:50%;
	-webkit-transform:translate(-50%,-15px);
	-moz-transform:translate(-50%,-15px);
	-o-transform:translate(-50%,-15px);
	-ms-transform:translate(-50%,-15px);
	transform:translate(-50%,-15px);
}
.tooltip:after{
	content:"";
	width:0;
	height:0;
	border-left:6px solid transparent;
	border-top:5px solid #606366;
	border-right:6px solid transparent;
	position:absolute;
	bottom:-5px;
	left:50%;
	margin-left:-6px;
}
.tooltip_type_2{
	background:rgba(52,56,61,.8);
	padding:2px 10px;
}
.tooltip_type_2:after{
	content:"";
	position:absolute;
}
.tooltip_type_2.bottom{
	top:100%;
	left:50%;
	-webkit-transform:translate(-50%,15px);
	-moz-transform:translate(-50%,15px);
	-o-transform:translate(-50%,15px);
	-ms-transform:translate(-50%,15px);
	transform:translate(-50%,15px);
}
.tooltip_type_2.left{
	top:50%;
	right:100%;
	margin-right:9px;
	-webkit-transform:translate(-15px,-50%);
	-moz-transform:translate(-15px,-50%);
	-o-transform:translate(-15px,-50%);
	-ms-transform:translate(-15px,-50%);
	transform:translate(-15px,-50%);
}
.tooltip_type_2.right{
	top:50%;
	left:100%;
	margin-left:9px;
	-webkit-transform:translate(15px,-50%);
	-moz-transform:translate(15px,-50%);
	-o-transform:translate(15px,-50%);
	-ms-transform:translate(15px,-50%);
	transform:translate(15px,-50%);
}
.tooltip_type_2.top:after{
	top:100%;
	border-left:7px solid transparent;
	border-top:5px solid rgba(52,56,61,.8);
	border-right:7px solid transparent;
	left:50%;
	margin-left:-7px;
}
.tooltip_type_2.bottom:after{
	bottom:100%;
	border-left:7px solid transparent;
	border-bottom:5px solid rgba(52,56,61,.8);
	border-right:7px solid transparent;
	left:50%;
	margin-left:-7px;
}
.tooltip_type_2.left:after{
	top:50%;
	border-top:7px solid transparent;
	border-left:5px solid rgba(52,56,61,.8);
	border-bottom:7px solid transparent;
	left:100%;
	margin-top:-7px;
}
.tooltip_type_2.right:after{
	top:50%;
	border-top:7px solid transparent;
	border-right:5px solid rgba(52,56,61,.8);
	border-bottom:7px solid transparent;
	right:100%;
	margin-top:-7px;
}
.tooltip_container:hover .tooltip,
.tooltip_container:hover .tooltip_type_2.top,
.tooltip_container:hover .tooltip_type_2.bottom{
	-webkit-transform:translate(-50%,0px);
	-moz-transform:translate(-50%,0px);
	-o-transform:translate(-50%,0px);
	-ms-transform:translate(-50%,0px);
	transform:translate(-50%,0px);
	opacity:1 !important;
	visibility: visible;
}
.tooltip_container:hover .tooltip_type_2.left,
.tooltip_container:hover .tooltip_type_2.right{
	-webkit-transform:translate(0px,-50%);
	-moz-transform:translate(0px,-50%);
	-o-transform:translate(0px,-50%);
	-ms-transform:translate(0px,-50%);
	transform:translate(0px,-50%);
	opacity:1;
	visibility: visible;
}
#side_menu{
	position:fixed;
	top:0;
	right:-280px;
	z-index:26;
	width:280px;
	background:#34383d;
	padding:33px 30px;
	height:100%;
	overflow-y:auto;
}
#side_menu > hr{
	margin-right:-30px;
	margin-left:-30px;
}
.alert_box{
	padding:13px 55px 13px 20px;
}
.alert_box i[class|="icon"]{
	width:16px;
	height:16px;
	position:absolute;
	top:50%;
	right:20px;
	margin-top:-8px;
	cursor:pointer;
	line-height: 16px;
	font-size:17px;
}
.alert_box.warning{
	background:#fae5d3;
	color:#d35400;
}
.alert_box.warning i[class|="icon"]{
	color:#fae5d3;
	background:#d35400;
}
.alert_box.error{
	background:#f2d7d5;
	color:#c0392b;
}
.alert_box.error i[class|="icon"]{
	color:#f2d7d5;
	background:#c0392b;
}
.alert_box.success{
	background:#d5f5e3;
	color:#27ae60;
}
.alert_box.success i[class|="icon"]{
	color:#d5f5e3;
	background:#27ae60;
}
.alert_box.info{
	background:#d6eaf8;
	color:#2980b9;
}
.alert_box.info i[class|="icon"]{
	color:#d6eaf8;
	background:#2980b9;
}
.progress_indicator{
	position:relative;
	height:9px;
}
.progress_indicator > div{
	position:absolute;
	top:2px;
	left:2px;
	right:2px;
	bottom:2px;
	width:0;
}
.item_statistic{
	height:140px;
}
.item_statistic [class|="icon"]{
	position:absolute;
	z-index:0;
	opacity:.15;
	font-size:137px;
	line-height: 140px;
	top:0;
	left:0;
	width:100%;
}
.item_statistic .icon-coffee{
	font-size:145px;
	margin-top:-10px;
}
.step_counter{
	border-width:2px;
	border-style:solid;
	width:95px;
	-webkit-border-radius:4px 0 0 4px;
	-moz-border-radius:4px 0 0 4px;
	border-radius:4px 0 0 4px;
}
.step_img_container .d_table_cell:not(.step_counter){
	padding:0 0 0 5px;
}
.step_img_container.right .d_table_cell:not(.step_counter){
	padding:0 5px 0 0;
}
.step_img_container.right .step_counter{
	-webkit-border-radius:0 4px 4px 0;
	-moz-border-radius:0 4px 4px 0;
	border-radius:0 4px 4px 0;	
}
.step_counter:before{
	font-size:4.5em;
	margin-top:16px;
}
.step_description{
	padding-top:12px;
}
.coming_soon{
	background:url("/images/coming_soon_img_1.jpg") no-repeat;
	background-size:cover;
	min-height:514px;
}
#countdown dl{
	padding:30px 20px 23px;
}
#countdown dl hr{
	margin-top:12px;
}
[class*="_isotope_container"]{
	margin-left:-15px;
	margin-right:-15px;
	overflow: hidden;
	-webkit-transition:height .7s ease;
	-moz-transition:height .7s ease;
	-o-transition:height .7s ease;
	transition:height .7s ease;
}
[class*="_isotope_container"].without_text .portfolio_item,
[class*="_isotope_container"] .blog_isotope_item,
[class*="_isotope_container"] .shop_isotope_item{
	margin-top:5px;
	margin-bottom:5px;
}
[class*="_isotope_container"] .blog_isotope_item,
[class*="_isotope_container"] .shop_isotope_item{
	border-left:15px solid transparent;
	border-right:15px solid transparent;
}
.portfolio_item{
	margin:25px 0;
	border-left:15px solid transparent;
	border-right:15px solid transparent;
}
[class*="_isotope_container"].two_columns .portfolio_item,
[class*="_isotope_container"].two_columns .blog_isotope_item{
	width:50%;
}
[class*="_isotope_container"].three_columns .portfolio_item,
[class*="_isotope_container"].three_columns .blog_isotope_item,
[class*="_isotope_container"].three_columns .shop_isotope_item:not(.list_view){
	width:25%;
}
[class*="_isotope_container"].four_columns .portfolio_item,
[class*="_isotope_container"].four_columns .blog_isotope_item{
	width:25%;
}
.project_description{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:2;
	opacity:0;
	padding-left:50px;
	padding-right:50px;
}
.project_description.type_2{
	padding-left:10px;
	padding-right:10px;
}
.project_description_up{
	padding:16px 0 8px;
	position:absolute;
	top:100%;
	left:0;
	width:100%;
	z-index:5;
}
.portfolio_item.type_2 .popup_wrap:hover img{
	-webkit-transform:translateY(-37px);
	-moz-transform:translateY(-37px);
	-o-transform:translateY(-37px);
	-ms-transform:translateY(-37px);
	transform:translateY(-37px);
}
.portfolio_item.type_2 .popup_wrap:hover .project_description{
	-webkit-transform:translateY(-27px);
	-moz-transform:translateY(-27px);
	-o-transform:translateY(-27px);
	-ms-transform:translateY(-27px);
	transform:translateY(-27px);	
}
.portfolio_item.type_2 .popup_wrap:hover .project_description_up{
	-webkit-transform:translateY(-100%);
	-moz-transform:translateY(-100%);
	-o-transform:translateY(-100%);
	-ms-transform:translateY(-100%);
	transform:translateY(-100%);
}
.project_description_up [class*="col-"].d_table_cell{
	padding:0 20px;
}
.p_carousel_description{
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	padding:15px 20px 13px;
	z-index:-1;
	max-height:75px;
	min-height: 75px;
}
.related_projects .owl-item:hover .p_carousel_description,
.wfilter_carousel .owl-item:hover .p_carousel_description{
	-webkit-transform:translateY(100%);
	-moz-transform:translateY(100%);
	-o-transform:translateY(100%);
	-ms-transform:translateY(100%);
	transform:translateY(100%);
}
.like_project{
	margin-top:-8px;
}
.blog_date{
	padding:14px 0 7px;
}
.blog_date > span:first-child{
	margin-bottom:1px;
}
[class*="border_color_"]{
	border-width:1px;
	border-style:solid;
}
.link_container{
	padding:17px 19px 19px;
}
.link_container.type_2{
	padding-left:17px;
	padding-right:17px;
}
.link_container [class*="icon_wrap_"],[class*="latest_projects_"],.tags_icon,
[class*="specials_"],td .reviews{
	margin-top:2px;
}
.blog_side_container{
	width:70px;
	margin-left:-100px;
}
figcaption.blog_post .blog_side_container{
	margin-top:-9px;
}
.blog_post{
	padding-left:100px;
}
.advertising_area{
	padding:0px 10px 10px;
}
.advertising_area.type_2{
	padding:0 8px 8px;
}
.advertising_area.type_3{
	padding:0 70px 10px;
}
.advertising_area.type_4{
	padding:0;
}
.advertising_area.type_3 > div:not(.advertising_row),
.advertising_area.type_4 > div:not(.advertising_row){
	height:90px;
}
.advertising_area.type_4{
	width:728px;
}
.advertising_area > div:not(.advertising_row){
	height:250px;
}
.advertising_area > span{
	font-size:0.625em;
}
.advertising_area .half_column > div{
	font-size:0.75em;
	line-height: 1.2em;
	/*height:125px;*/
}
.advertising_area .half_column{
	padding:0 2px;
}
.advertising_row{
	margin:0 -2px;
}
.advertising_row > .clearfix:first-child{
	margin-bottom:4px;
}
.blog_isotope_container{
	margin-top:-15px;
}
.blog_isotope_item > article{
	padding:19px 19px 27px;
}
.comment{
	padding:13px 20px;
}
.comment:before{
	content:"";
	width:0;
	height:0;
	position:absolute;
	left:30px;
	top:-8px;
	border-left:10px solid transparent;
	border-bottom:8px solid #ecf0f1;
	border-right:10px solid transparent;
}
.comment_level_2{
	padding-left:30px;
}
.addthis_floating_style{
	position:relative !important;
	margin-top:15px;
	left:0 !important;
	top:0 !important;
}
.category_select{
	width:215px;
	z-index:14;
}
.dropdown_2{
	position:absolute;
	top:100%;
	margin-top:15px;
	width:380px;
	right:0;
	border-top-width:3px;
	border-top-style:solid;
	-webkit-transform-origin:100% 0;
	-moz-transform-origin:100% 0;
	-o-transform-origin:100% 0;
	-ms-transform-origin:100% 0;
	transform-origin:100% 0;
	-webkit-transform:translateY(20px);
	-moz-transform:translateY(20px);
	-o-transform:translateY(20px);
	-ms-transform:translateY(20px);
	transform:translateY(20px);
	opacity:0;
	visibility: hidden;
}
.dropdown_2:before{
	content:"";
	position:absolute;
	top:-8px;
	right:15px;
	border-left:6px solid transparent;
	border-bottom-width:5px;
	border-bottom-style:solid;
	border-right:6px solid transparent;
}
.dropdown_2:after{
	content:"";
	display:block;
	height:15px;
	width:100%;
	position:absolute;
	top:-18px;
	right:0;
}
.dropdown_2_container{
	z-index:12;
}
.dropdown_2_container .dropdown_2{
	padding:27px 15px 0;
}
.dropdown_2_container.shoppingcart .dropdown_2 .sc_header,.total_price{
	margin-left:-15px;
	margin-right:-15px;
	padding:4px 15px 3px;
	border-bottom:1px solid #ecf0f1;
}
.dropdown_2 .sc_footer{
	padding:16px 15px;
	margin:0 -15px;
	border-top:1px solid #ecf0f1;
}
.item_description{
	max-width:150px;
}
.total_price{
	border-top:1px solid #ecf0f1;
	padding:8px 50px 8px 15px;
}
.total_price .price{
	min-width:60px;
	margin-bottom:1px;
}
/*.fp_images > img:last-child{
	position:absolute;
	top:0;
	left:0;
	z-index:-1;
}*/
.labels_container{
	position:absolute;
	left:10px;
	top:10px;
	z-index:2;
}
.label{
	width:50px;
	height:50px;
}
.client_item::after{
	content:"";
	display:block;
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:#fff;
	z-index:-1;
}
.category_title{
	padding:15px 5px 14px;
}
.quantity{
	border:1px solid #e1e4e6;
	/* min-width: 90px; */
}
.privacy{
	padding:17px 19px 15px;
}
.banners_container{
	padding:10px 10px 8px;
}
.product_view{
	width: 45%;
}
.product_info{
	width: 52%;
}
.zoomWindowContainer > div{
	z-index:100 !important;
}
.slider_video_wrap{
	padding-right:30px;
}
.mini_coming_soon{
	padding-top:30px;
}
#countdown.type_2 dt{
	font-weight:600;
	margin-bottom:0;
}
#countdown.type_2 dl{
	text-align: center;
	padding:7px 15px 2px;
}
#countdown.type_2 hr{
	margin:8px 0 2px;
}
#countdown.type_2 [class*="col-"]{
	padding:0 5px;
}
#countdown.type_2 .row{
	margin-left:-5px;
	margin-right:-5px;
}
.wfilter_carousel:after{
	content:"";
	display:none;
	width:32px;
	height:32px;
	background:url("/images/loader2.gif") no-repeat;
	position:absolute;
	top:50%;
	left:50%;
	margin:-59px 0 0 -16px;
}
.wfilter_carousel.changed:after{
	display:block;
}
.screens,.mobile{
	height:450px;
}
.screens > [class^="layer_"],.mobile > [class^="layer_"]{
	position:absolute;
	bottom:0;
}
.screens > .layer_1{
	left:50%;
	margin-left:-344px;
	z-index:2;
}
.screens > .layer_2{
	left:0;
	z-index:1;
}
.screens > .layer_3{
	right:0;
}
.mobile > .layer_2{
	right:-10px;
}
.tablet > .layer_1{
	max-width:none;
	margin-top:-63px;
}
.stat{
	width:100px;
}
.breaking_news{
	padding:5px 0;
}
.news_main_item{
	width:54.02%;
}
.news_list{
	width:44.82%;
}
.fs_caption{
	background:rgba(255,255,255,.7);
	padding:45px 20px 17px;
	position:absolute;
	/*bottom:20px;*/
	left:20px;
	width:430px;
}
.fs_caption > header{
	position:absolute;
	top:0;
	left:0;
	padding:5px 20px 7px;
	-webkit-border-bottom-right-radius:4px;
	-moz-border-bottom-right-radius:4px;
	border-bottom-right-radius:4px;
}
.create_account_form_wrap{
	background:rgba(255,255,255,.6);
	padding:28px 20px 30px;
	width:390px;
}
.clients_item:before{
	content:"";
	display:block;
	position:absolute;
	-webkit-border-radius:5px 5px 5px 5px;
	-moz-border-radius:5px 5px 5px 5px;
	border-radius:5px 5px 5px 5px;
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
	background:#fff;
	z-index:-1;
}
.form_description{
	padding-left:65px;
}

/* -----------------------------------

	15. Forms

------------------------------------- */

[role="banner"] [role="search"]:not(.type_3){
	position:absolute;
	width:100%;
	height:100%;
	left:-47px;
	top:0;
	z-index:11;
	-webkit-transform:translateX(-100px);
	-moz-transform:translateX(-100px);
	-o-transform:translateX(-100px);
	-ms-transform:translateX(-100px);
	transform:translateX(-100px);
	opacity:0;
	visibility: hidden;
}
textarea,
.select_title,
input:not([type="submit"]){
	border: 1px solid #DEE0E2;
	height:40px;
	padding:5px 15px;
	/* font-style:italic; */
}
textarea{
	resize:none;
	outline:none;
	height:250px;
}
.height_2{
	height:150px;
}
.height_3{
	height:160px;
}
.height_4{
	height:210px;
}
.height_5{
	height:120px;
}
form li label{
	width:17%;
}
form li input,
form li textarea,form .inline_select{
	width:82%;
}
label[for^="input_"]:not([for="input_1"]){
	width:144px;
}
input[type="radio"] + label,
input[type="checkbox"] + label{
	width:auto !important;
	position:relative;
	padding-left:36px;
	cursor:pointer;
}
input[type="radio"] + label:after,
input[type="radio"] + label:before,
input[type="checkbox"] + label:before{
	content:"";
	display:block;
	position:absolute;
	background:#fff;
	top:0;
	left:0;
	width:26px;
	height:26px;
	border:1px solid #e2e5e7;
}
input[type="radio"] + label:after{
	display:none;
	border:none;
	background:#606366;
	width:10px;
	height:10px;
	left:8px;
	top:8px;
}
input[type="checkbox"] + label:after{
	content:'\e914';
	font-family: "fontello";
	position:absolute;
	left:8px;
	top:2px;
	display:none;
}
input[type="radio"]:checked + label:after,
input[type="checkbox"]:checked + label:after{
	display:block;
}
[role="banner"] [role="search"]:not(.type_3) input[type="text"]{
	width:520px;
}/*
.custom_select{
	position:relative;
}
.select_title{
	padding:6px 35px 5px 13px;
	cursor:pointer;
}
.custom_select.type_2 .select_title{
	font-style:normal;
	height:35px;
	padding-top:4px;
	padding-bottom:4px;
}*/
.products_filter{
	width:180px;
}
.show_per_page_select{
	min-width:70px;
}
.select_title:after,
.side_main_menu .has_sub_menu > a:after{
	content:'\e9f9';
	font-family: "fontello";
	display:block;
	font-style:normal;
	position: absolute;
	top:0;
	height:40px;
	line-height:40px;
	color:#606366;
	font-size:20px;
	right:14px;
}
.select_title.active:after,
.side_main_menu .has_sub_menu.active > a:after{
	content:'\e9f8';
}
/*.custom_select.type_2 .select_title:after{
	font-size:17px;
	height:34px;
	line-height: 34px;
}*/
.select_list{
	width:100%;
	position:absolute;
	top:100%;
	-webkit-transform:translateY(15px);
	-moz-transform:translateY(15px);
	-o-transform:translateY(15px);
	-ms-transform:translateY(15px);
	transform:translateY(15px);
	opacity:1;
	visibility: visible;
	z-index:2;
}
.select_list li{
	cursor: pointer;
	padding:6px 13px 5px;
}
.select_list li:hover{
	background:#f7f7f7;
}
[role="search"].type_2 input[type="text"]{
	padding-right:45px;
}
[role="search"].type_2 button{
	position:absolute;
	top:0;
	width:46px;
	height:100%;
	right:0;
	font-size:23px;
}
form .row{
	margin-left:-5px;
	margin-right:-5px;
}
form [class*="col-"]{
	padding-left:5px;
	padding-right:5px;
}
#capcha{
	width:100px;
}
#contactform [class*="button_type"]{
	min-width:120px;
}
form.login_form [class*="button_type"]{
	min-width:72px;
	padding-left:10px;
	padding-right:10px;
}
.notify_me input[type="email"]{
	width:77.19%;
}
.notify_me button{
	width:21.05%;
}
.side_main_menu .has_sub_menu > a:after{
	height:100%;
	line-height: inherit;
	right:0;
	font-size:13px;
	opacity:.8;
	color:inherit;
}
[role="search"].type_3{
	width:350px;
}
input[class*="_limit"]{
	border:none;
	padding:0;
	font-style:normal;
}
.login_icon + input:not([type="submit"]){
	padding-right:40px;
}
.ui-slider{
	height:9px;
	cursor:pointer;
	position:relative;
	padding:0 2px;
}
.ui-slider-handle{
	display:block;
	width:13px;
	height:20px;
	position:absolute;
	margin-left:-6px;
	top:-7px;
	background:url("/images/slider_range_icon.png") no-repeat;
}
.ui-slider-range{
	position:absolute;
	top:2px;
	bottom:2px;
}
.quantity input[type="text"]{
	border-top:none;
	border-bottom:none;
	border-left:1px solid #e1e4e6;
	border-right:1px solid #e1e4e6;
	padding-left:2px;
	padding-right:2px;
	text-align:center;
	font-style:normal;
	height:38px;
	width:40px;
}
.quantity.type_2 input[type="text"]{
	width: 45px;
}
.coupon{
	width:325px;
	margin:2px 3px;
}
.coupon + button{
	margin-top:-10px;
}
.required:after,.required_l:before{
	content:"*";
}
.required:after{
	display:inline-block;
	margin-left:3px;
}
.fe_width_1{
	width:170px;
}
.fe_width_2,.table_type_3{
	width:370px;
}
.subscribe_form{
	background:#dbf2fa;
	padding:10px;
}
.subscribe_form input[type="email"]{
	width:76%;
}
.subscribe_form button{
	width:22.88%;
}
[role="search"]:not(.type_3).type_4{
	width:calc(100% - 50px);
	left:0;
}
[role="search"]:not(.type_3).type_4 input[type="text"]{
	width:100%;
	height:100%;
	border:none;
	padding-left:0;
}

/* chrome special fixes*/
@media only screen and (-webkit-min-device-pixel-ratio:0){
	.side_main_menu .has_sub_menu > a:after{
		top:1px;
	}
	.categories_list li a{
		padding:6px 0;
	}
	.categories_list ul a{
		padding:4px 0 5px 28px;
	}

	.side_menu_list li a{
		padding:6px 0;
	}
	.side_menu_list ul a{
		padding:4px 0 5px 28px;
	}
	.open_product i[class|="icon"]{
		margin:-4px 0 0 -2px;
	}
	[class*="select_button_"]:before,
	[class*="select_button_"]:after{
		-webkit-transition:opacity .56s;
		-moz-transition:opacity .56s;
		-o-transition:opacity .56s;
		transition:opacity .56s;
	}
	.flex_container .slides > li > img{
		-webkit-border-radius:4px 0 0 4px;
	}
	.icon_wrap_size_1 > .icon-lifebuoy{
		font-size:.95em;
	}
	.owl-carousel:not(.wfilter_carousel) .owl-item{
		padding:0 1px;
	}
	.photo_carousel .owl-item{
		padding-left:0 !important;
		padding-right: 0 !important;
	}
}
/* firefox special fixes*/
@-moz-document url-prefix() { 
	.breadcrumbs i[class|="icon"]{
		margin-top:2px;
	}
	.stripe_list li:not(:last-child)::after{
		margin-top:-4px;
	}
	.button_type_2{
		padding-top:3px;
		padding-bottom:6px;
	}
	.button_type_3{
		padding-top:10px;
		padding-bottom:10px;
	}
	.button_type_5{
		padding-top:6px;
		padding-bottom:7px;
	}
	.button_type_6{
		padding-top:1px;
		padding-bottom:3px;
	}
	.category_title{
		padding:14px 5px 15px;
	}
	/*.custom_select.type_2 .select_title{
		padding-top:3px;
	}*/
	.paginations i[class|="icon"]{
		margin-top:-.5px;
		display:inline-block;
	}
	table td{
		padding-top:8px;
		padding-bottom:10px;
	}
	.thumbnails_carousel .flex-direction-nav li:first-child i[class|="icon"]{
		margin-top:-3px;
	}
	.thumbnails_carousel .flex-direction-nav li:last-child i[class|="icon"]{
		margin-top:-2px;
	}
	.project_description_up{
		padding:15px 0 9px;
	}
	.tabs_nav.type_2 a{
		padding-top:4px;
		padding-bottom:5px;
	}
	#breaking_news{
		margin-top:2px;
	}
	.button_type_1{
		padding-top:1px;
		padding-bottom:3px;
	}
	.icon_wrap_size_2{
		line-height: 39px;
	}
	[class*="icon_wrap"] .icon-play{
		margin-left:0px !important;
	}
	[class*="icon_wrap"] .icon-plus,[class*="icon_wrap"] .icon-minus{
		margin-top:-2px !important;
	}
	[class*="icon_wrap"] [class^="icon-angle-"]{
		margin-top:-2px !important;
	}
	.icon_wrap_size_0 .icon-link{
		font-size:.95em;
		margin-top:-2px;
	}
	.step .d_table_cell img{
		width:100%;
	}
	.video_button i[class|="icon"]{
		margin-top:1px;
	}
}

/* -----------------------------------

	16. Footer

------------------------------------- */

[role="contentinfo"]{
	padding:44px 0 0;
}
.footer_bottom_part{
	padding:24px 0;
	border-top:1px solid #cbd0d4;
}

/* -----------------------------------

	17. Animations

------------------------------------- */
[class*="animate_f"],#back_to_top{
	opacity:0;
	visibility: hidden;
}
.animate_ftr{
	-webkit-transform:translateX(-60px);
	-moz-transform:translateX(-60px);
	-o-transform:translateX(-60px);
	-ms-transform:translateX(-60px);
	transform:translateX(-60px);
}
.animate_ftl,#back_to_top{
	-webkit-transform:translateX(-60px);
	-moz-transform:translateX(-60px);
	-o-transform:translateX(-60px);
	-ms-transform:translateX(-60px);
	transform:translateX(-60px);
}
.animate_ftt{
	-webkit-transform:translateY(60px);
	-moz-transform:translateY(60px);
	-o-transform:translateY(60px);
	-ms-transform:translateY(60px);
	transform:translateY(60px);
}
.animate_ftb{
	-webkit-transform:translateY(-60px);
	-moz-transform:translateY(-60px);
	-o-transform:translateY(-60px);
	-ms-transform:translateY(-60px);
	transform:translateY(-60px);
}
.animate_fctr{
	-webkit-transform:translateX(-50%);
	-moz-transform:translateX(-50%);
	-o-transform:translateX(-50%);
	-ms-transform:translateX(-50%);
	transform:translateX(-50%);
}
.animate_fctl{
	-webkit-transform:translateX(50%);
	-moz-transform:translateX(50%);
	-o-transform:translateX(50%);
	-ms-transform:translateX(50%);
	transform:translateX(50%);
}
.wfilter_carousel .owl-item:hover .popup_wrap:before,
.wfilter_carousel .owl-item:hover .popup_wrap:after,
.related_projects .owl-item:hover .popup_wrap:before,
.related_projects .owl-item:hover .popup_wrap:after,
.popup_wrap:hover:before,
.popup_wrap:hover:after,
.horizontal_animate_finished[class*="animate_"],
[role="banner"] [role="search"]:not(.type_3).horizontal_animate_finished,
#back_to_top.horizontal_animate_finished,
.dropdown_2_container:hover .dropdown_2,
.fp_item:hover [class*="animate_fc"],.no_children_animate [class*="animate_f"]{
	-webkit-transform:translateX(0px);
	-moz-transform:translateX(0px);
	-o-transform:translateX(0px);
	-ms-transform:translateX(0px);
	transform:translateX(0px);
	opacity:1;
	visibility: visible;
}
.vertical_animate_finished,
.md_no-touch .main_menu li:hover > .mega_menu_container,
.md_touch .main_menu .touch_open_sub > .mega_menu_container,
.md_no-touch .mega_menu_container .container3d:hover .sub_menu,
.md_touch .mega_menu_container .container3d.touch_open_sub .sub_menu,
.no_children_animate [class*="animate_f"]{
	-webkit-transform:translateY(0px);
	-moz-transform:translateY(0px);
	-o-transform:translateY(0px);
	-ms-transform:translateY(0px);
	transform:translateY(0px);
	opacity:1;
	visibility: visible;
}
.animate_fsj_finished{
	-webkit-animation-name:sizejump;
	-moz-animation-name:sizejump;
	-o-animation-name:sizejump;
	animation-name:sizejump;
	-webkit-animation-timing-function:ease;
	-moz-animation-timing-function:ease;
	-o-animation-timing-function:ease;
	animation-timing-function:ease;
	opacity:1;
	visibility: visible;
}
@-webkit-keyframes sizejump{
	0% {-webkit-transform:scale(.8);}
	50% {-webkit-transform:scale(1.05);}
	100% {-webkit-transform:scale(1);}
}
@keyframes sizejump{
	0% {-moz-transform:scale(.8);-o-transform:scale(.8);transform:scale(.8);}
	50% {-moz-transform:scale(1.05);-o-transform:scale(1.05);transform:scale(1.05);}
	100% {-moz-transform:scale(1);-o-transform:scale(1);transform:scale(1);}
}
.ie9 [class*="animate_f"]:not(.with_ie),.fade_finished,.fp_item .animate_fctl{
	opacity:1;
	visibility: visible;
}
#close_side_menu{
	opacity:.2;
}

.about_counters{
		font-size: 3.5em !important;
	}

/* -----------------------------------

	18. Responsive changes

------------------------------------- */

/* slider layers fix*/
@media only screen and (max-width:1600px){
	.slider_title_1{
		font-size:6em;
	}
	.slider_title_2{
		font-size:3.5em;
	}
	.iosslider .slider .slide .item_3,.iosslider .slider .slide .item_2{
		top:8%;
	}
	.w_lg_half_column{
		width:50% !important;
	}
	.about_counters{
		font-size: 3.5em !important;
	}
	
}

@media only screen and (min-width:1200px){
	.w_lg_910{
		width: 910px !important;
	}
}

@media only screen and (max-width:1200px),only screen and (max-width:1200px) and (orientation: landscape){
	/* scheme color */
	.tabs_nav.type_3 .active a{
		border-right-color:#41bedd !important;
	}
	.tabs_nav.type_3 .active + li a{
		border-top-color:#41bedd;
	}

	.boxed_layout{
		width:1000px;
	}
	.main_menu > li:last-child > ul{
		left:auto;
		right:0;
	}
	[class*="animate_f"]{
		-webkit-transform:none;
		-moz-transform:none;
		-o-transform:none;
		-ms-transform:none;
		transform:none;
		opacity:1;
		visibility: visible;
	}
	iframe[src^="http://www.facebook.com/plugins"]{
		width:100% !important;
		height:auto !important;
	}
	#scroll_sidebar{
		position:relative !important;
		top:auto !important;
		right:auto !important;
	}
	.step_description{
		padding-top:0;
	}
	.tabs_nav.type_3 li:first-child a{
		-webkit-border-radius:4px 4px 0 0;
		-moz-border-radius:4px 4px 0 0;
		border-radius:4px 4px 0 0;
	}
	.tabs_nav.type_3 li:last-child a{
		-webkit-border-radius:0 0 4px 4px;
		-moz-border-radius:0 0 4px 4px;
		border-radius:0 0 4px 4px;
	}
	.tabs_nav.type_3 li:not(:last-child) a{
		border-right:1px solid #bdc3c7;
		border-bottom:none;
	}
	.tabs_nav.type_3 .active + li a{
		border-left-color:#bdc3c7;
	}
	.iosslider .slider .slide h1,.slider_title_1,.slider_title_2,.slider_title_3{
		font-size:2.5em;
	}
	[role="banner"].type_2 .header_top_part{
		padding-bottom:6px !important;
	}
	.category_link{
		width:136px;
	}
	.table_type_2 tr:first-child th:nth-child(2){
		width:285px;
	}
	.table_type_2 tr:first-child th:nth-child(5){
		width:190px;
	}
	.table_type_2 td:not([colspan]),.table_type_2 th,
	.table_type_8 td:not([colspan]),.table_type_8 th{
		padding-left:15px;
		padding-right:15px;
	}
	.table_type_8 td:not([colspan]){
		padding-bottom:10px;
	}
	.table_type_4 tr:first-child th:nth-child(2){
		width:180px;
	}
	.title_size_1{
		line-height: 1em;
	}
	.mini_coming_soon{
		padding-top:0;
	}
	.darkness{
		padding:120px 0;
	}
	.darkness.type_2{
		padding:100px 0;
	}
	.bn_title{
		width:13.3%;
	}
	#breaking_news{
		width:83.03%;
	}
	.thumbnails_container > ul > li{
		width:25%;
	}
	.iosslider .slider .slide .item_3,.iosslider .slider .slide .item_2{
		top:6%;
	}
	.iosslider .slider .slide .item{
		top:8%;
	}
	.iosslider .slider .slide .item_3{
		margin-left:-480px;
	}
	.flex_container{
		-webkit-border-radius:5px 5px 0 0;
		-moz-border-radius:5px 5px 0 0;
		border-radius:5px 5px 0 0;
	}
	.thumbnails_container{
		-webkit-border-radius:0 0 4px 4px;
		-moz-border-radius:0 0 4px 4px;
		border-radius:0 0 4px 4px;
	}
	.main_menu > li > a{
		padding-left:16px;
		padding-right:16px;
	}
	.page_title.type_3{
		padding:75px 0;
	}
	.md_wrapper{
		overflow: hidden;
	}
	.f_md_left{
		float:left;
	}


	.w_md_910{
			width: 910px !important;
	}
	.f_md_none,.hr_list > .f_md_none{
		float:none;
	}
	.d_md_block{
		display:block;
	}
	.d_md_none,
	.zoomContainer,
	.steps_nav li:not(:last-child):after{
		display:none;
	}
	.d_md_inline_b{
		display:inline-block;
	}
	.t_md_align_c,.fp_item:hover,.fp_item:hover .fp_price{
		text-align:center;
	}
	.fp_item:hover .fp_price{
		text-align: left;
	}
	.t_md_align_l,.list_view .fp_item:hover,.list_view .fp_item:hover .fp_price{
		text-align:left;
	}
	.m_md_right_0{
		margin-right:0px;
	}
	.m_md_right_10{
		margin-right:10px;
	}
	.m_md_right_15{
		margin-right:15px;
	}
	.m_md_right_20{
		margin-right:20px;
	}
	.m_md_bottom_5{
		margin-bottom:5px;
	}
	.m_md_bottom_10{
		margin-bottom:10px;
	}
	.m_md_bottom_15{
		margin-bottom:15px;
	}
	.m_md_bottom_30{
		margin-bottom:30px;
	}

	.w_md_half_column{
		width:50% !important;
	}
	.about_counters{
		font-size: 3em !important;
	}
}
.w_md_full,.flex_container,.thumbnails_container{
	width:100%;
}
@media only screen and (max-width:992px),only screen and (max-width:992px) and (orientation: portrait){
	.boxed_layout{
		width:780px;
	}
	.container,.project_description{
		padding-left:10px;
		padding-right:10px;
	}
	.main_menu > li > a{
		padding:25px 3.5px;
	}
	.main_menu > li [class|="icon"]{
		margin-left:0;
	}
	.main_menu > li:last-child > ul{
		left: auto;
		right:0;
	}
	.item_services{
		padding-left:60px;
	}
	.item_services h6 [class*="icon_wrap_"]{
		left:-60px;
	}
	[class*="button_type"]:not(.button_type_6){
		padding-left:27px;
		padding-right:27px;
	}
	[role="banner"] [role="search"]:not(.type_3){
		width:93%;
		left:0;
	}
	.tabs_nav a{
		padding-left:7px;
		padding-right:7px;
	}
	.steps_nav li .step_num:before{
		margin-top:-4px;
	}
	.steps_nav li .icon_wrap_size_7,
	.steps_nav li .container3d{
		width:105px;
		height:105px;
		line-height: 105px;
	}
	.flickr_list img{
		width:72px;
		height:auto;
	}
	.calendar td.active span{
		width: 26px;
		padding: 1px 0;
	}
	.blog_isotope_container[class*="_columns"].type_2 .blog_isotope_item > article {
		padding: 14px 14px 27px;
	}
	.blog_isotope_container[class*="_columns"].type_2{
		margin-top:-2px;
		margin-left:-2px;
		margin-right:-2px;
	}
	.blog_isotope_container[class*="_columns"].type_2 .blog_isotope_item{
		border-left-width:2px;
		border-right-width:2px;
		margin:2px 0;
	}
	.portfolio_isotope_container.home .portfolio_item{
		width:50%;
	}
	.category_select,.type_3[role="search"]{
		width:200px;
	}
	.category_link{
		width:100px;
	}
	.screens{
		height:350px;
	}
	.screens > .layer_1{
		width:70%;
		margin-left:-35%;
	}
	.screens > .layer_2,
	.screens > .layer_3{
		width:50%;
	}
	.tablet > .layer_1{
		max-width:100%;
		margin-top:0;
	}
	.table_type_2 td:not([colspan]),.table_type_2 th{
		padding-left:5px;
		padding-right:5px;
	}
	.table_type_2 .quantity input[type="text"]{
		width:30px;
	}
	.table_type_4 td:not([colspan]),.table_type_4 th,.table_type_5 td,.table_type_5 th{
		padding-left:15px;
		padding-right:15px;
	}
	.table_type_4 td:not(:last-child),.table_type_4 th:not(:last-child){
		width:22%;
	}
	.table_type_4 img{
		width:100px;
	}
	.table_type_8 td:not([colspan]),.table_type_8 th,.table_type_8 td[colspan]{
		padding-left:6px;
		padding-right:6px;
	}
	.coupon{
		width: 280px;
	}
	.slider_video_wrap{
		padding-right:0;
	}
	.product_view{
		/* width:470px; */
	}
	.iosslider .slider .slide .item,
	.iosslider .slider .slide .item_3{
		min-width:400px;
		margin-left:0px;
	}
	.iosslider .slider .slide .item_3{
		top:3%;
		margin-left:-364px;
	}
	.p_sm_top_0{
		padding-top: 0px !important;
	}
	.w_sm_full,.advertising_area.type_4,form .inline_select{
		width:100%;
	}

	.w_sm_700{
		width:700px !important;
	}
	.w_sm_auto{
		width:auto;
	}
	.f_sm_none{
		float:none;
	}
	.f_sm_left{
		float:left;
	}
	.f_sm_right{
		float:right;
	}
	.t_sm_align_l{
		text-align: left;
	}
	.t_sm_align_c{
		text-align: center;
	}
	.d_sm_block{
		display:block;
	}
	.d_sm_none{
		display:none;
	}
	.d_sm_inline_b{
		display:inline-block;
	}
	.m_sm_right_0{
		margin-right:0px;
	}
	.m_sm_right_5{
		margin-right:5px;
	}
	.m_sm_right_15{
		margin-right:15px;
	}
	.m_sm_left_15{
		margin-left:15px;
	}
	.m_sm_top_15{
		margin-top:15px;
	}
	.m_sm_top_25{
		margin-top:25px;
	}
	.m_sm_bottom_0{
		margin-bottom:0px;
	}
	.m_sm_bottom_5{
		margin-bottom:5px;
	}
	.m_sm_bottom_10{
		margin-bottom:10px;
	}
	.m_sm_bottom_20{
		margin-bottom:20px;
	}
	.m_sm_bottom_30{
		margin-bottom:30px;
	}
	.m_sm_bottom_60{
		margin-bottom:60px;
	}
	.about_counters{
		font-size: 2.2em !important;
	}
	.w_sm_half_column{
		width: 50%;
	}
}

@media only screen and (min-width:768px) and (max-width:991px){
	.sm_xs_w_full{
		width: 100%;
	}
	.sm_xs_f_none{
		float: none;
	}
	.sm_xs_t_align_c{
		text-align: center;
	}
	
}


@media only screen and (max-width:767px){
	.about_counters{
		font-size: 3em !important;
	}
	/* scheme color */
	.tabs_nav .active a{
		border-right-color:#41bedd !important;
	}
	.tabs_nav .active + li a{
		border-top-color:#41bedd;
	}
	h1:not(.ls-l){
		font-size:1.75em;
	}
	.page_title,.page_title.type_3{
		padding:20px 0;
	}
	.boxed_layout{
		width:auto;
		margin-left:10px;
		margin-right: 10px;
	}
	[class*="image_bg_"]{
		background-attachment:scroll;
		background-position:0 0;
	}
	[role="banner"]{
		padding-bottom:1px;
	}
	[role="banner"] [role="search"]{
		position:relative !important;
		top:auto !important;
	}
	[role="banner"]{
		border-bottom:none !important;
	}
	.header_bottom_part{
		/* padding-bottom:20px; */
	}
	.type_2[role="banner"] .header_top_part{
		padding-bottom:1px !important;
	}
	.header_bottom_part.type_2{
		padding:0;
	}
	.main_menu > li > a,
	.main_menu.type_2 > li > a{
		padding:8px 40px 9px 20px;
		border-top-width:1px;
		border-right-width:1px;
		border-left-width:1px;
		border-bottom-width:2px;
		border-style:solid;
	}
	.main_menu > li > a > i{
		position: absolute;
		height:100%;
		right:15px;
	}
	.main_menu.type_2 > li:first-child > a{
		padding-left:20px;
	}
	[class*="translucent_bg"].image_fixed > img{
		left:-70%;
	}
	.r_xs_corners{
		-webkit-border-radius:4px;
		-moz-border-radius:4px;
		border-radius:4px;
	}
	.mega_menu_container{
		width:100% !important;
		left:0 !important;
		padding:0;
	}
	.mega_menu_container,
	.sub_menu:not(.relative),
	.mega_menu_container .container3d .sub_menu{
		position:relative;
		top:auto;
		margin-top: 5px;
		border:1px solid #F7F7F7;
	}
	.main_menu.type_2 > li > ul{
		margin-top:5px;
	}
	.dropdown .sub_menu{
		position:absolute;
		top:100%;
		border-top:2px solid #41bedd;
		margin-top:0px;
	}
	.sub_menu .sub_menu,
	[role="banner"] [role="search"]:not(.type_3){
		left:0;
		margin-left:0;
	}
	.sub_menu .sub_menu,
	.mega_menu_container .container3d .sub_menu{
		margin-top:0;
		border:none;
		-webkit-border-radius:0px;
		-moz-border-radius:0px;
		border-radius:0px;
	}
	.sub_menu ul li a{
		padding-left:30px;
	}
	.sub_menu ul ul li a{
		padding-left:40px;
	}
	.section_offset,.section_offset_2{
		padding:35px 0 40px;
	}
	.kent_section_offset{
		padding:10px 0 10px;
	}
	[role="contentinfo"]{
		padding-top:35px;
	}
	.r_xs_corners_none{
		-webkit-border-radius:0px;
		-moz-border-radius:0px;
		border-radius:0px;
	}
	.tabs_nav li:first-child a{
	}
	.tabs_nav li:last-child a{

	}
	.tabs_nav li:not(:last-child) a{

	}
	.tabs_nav .active + li a{
		border-left-color:#bdc3c7;
	}
	.clients_item{
		max-width:170px;
	}
	.d_table > [class*="col-"]:last-child{
		padding-right:15px;
	}
	.d_table > [class*="col-"]:first-child{
		padding-left:15px;
	}
	.p_table:hover,.p_table.active{
		margin:0;
		padding:35px 19px 39px;
	}
	.page_title{
		max-height:268px;
	}
	[class*="_isotope_container"] .portfolio_item,
	[class*="_isotope_container"] .blog_isotope_item,
	.blog_isotope_container[class*="_columns"].type_2 .blog_isotope_item{
		border-left:none;
		border-right:none;
		margin-top:15px;
		margin-bottom:15px;
	}
	.project_description,.p_carousel_description,.blog_post{
		padding-left:0;
		padding-right:0;
	}
	.p_xs_hr_0{
		padding-left:0 !important;
		padding-right:0 !important;
	}
	.blog_side_container{
		margin-left:0;
	}
	.advertising_area.type_3{
		padding-left:30px;
		padding-right:30px;
	}
	.blog_isotope_container[class*="_columns"].type_2 .blog_isotope_item > article {
		padding: 19px 19px 27px;
	}
	.dropdown_2{
		width:280px;
		right:auto;
		left:50%;
		margin-left:-140px;
	}
	.item_description{
		max-width:145px;
	}
	.dropdown_2:before{
		right:50%;
		margin-right:-6px;
	}
	.featured_products .owl-wrapper{
		padding-bottom:2px;
	}
	.clients.brands .owl-controls{
		margin-top:20px;
	}
	[class*="addthis_button_"],.addthis_counter{
		display:block !important;
	}
	.thumbnails_carousel .flex-direction-nav{
		margin-top:15px !important;
	}
	[role="search"]:not(.type_3).type_4 input[type="text"]{
		border:1px solid #e1e4e6;
		padding-left:20px;
		height:40px;
	}
	.header_bottom_part.type_3{
		padding-bottom:14px;
	}
	[class*="select_button"]{
		-webkit-border-radius:4px;
		-moz-border-radius:4px;
		border-radius:4px;
		border-width:1px;
	}
	.darkness{
		padding:80px 0;
	}
	.darkness.type_3{
		padding:20px 0;
	}
	.screens{
		height:0;
		padding-bottom:48%;
	}
	.tablet{
		padding-bottom:30px;
	}
	.mobile{
		height:0;
		padding-bottom:67%;
	}
	.news_list > li {
		padding-left:0;
	}
	.video_wrap > video{
		width:auto;
		height:auto;
	}
	.coming_soon{
		background-size:100% 100%;
	}
	.table_type_8 td:not([colspan]){
		height:auto;
	}
	.form_description{
		padding-left:0;
		padding-right:0;
	}
	.db_xs_centered,
	[class*="_isotope_container"],
	.blog_isotope_container[class*="_columns"].type_2,
	[role="banner"] [role="search"]:not(.type_3){
		margin-left:auto;
		margin-right: auto;
	}
	.bs_xs_none,
	.sub_menu.bs_xs_none{
		-webkit-box-shadow:none;
		-moz-box-shadow:none;
		-o-box-shadow:none;
		box-shadow:none;
	}
	.tr_xs_none{
		-webkit-transition:none !important;
		-moz-transition:none !important;
		-o-transition:none !important;
		transition:none !important;
	}
	.trf_xs_none{
		-webkit-transform:none !important;
		-moz-transform:none !important;
		-o-transform:none !important;
		-ms-transform:none !important;
		transform:none !important;
		opacity:1 !important;
		visibility: visible !important;
	}
	.w_xs_auto,.notify_me button,.bn_title{
		width: auto !important;
	}
	.w_xs_full,
	#breaking_news,
	.thumbnails_container > ul > li,
	.notify_me input[type="email"],
	[role="banner"] [role="search"],
	.addthis_floating_style.addthis_counter_style,
	[role="banner"] [role="search"]:not(.type_3) input[type="text"],
	[class*="_isotope_container"][class*="_columns"] .portfolio_item,
	[class*="_isotope_container"][class*="_columns"] .blog_isotope_item,
	[class*="_isotope_container"][class*="_columns"] /*.shop_isotope_item:not(.list_view)*/,
	.addthis_floating_style.addthis_counter_style .addthis_internal_container{
		width:100%;
	}
	.f_xs_none,
	.hr_list > .f_xs_none{
		float:none;
	}
	.d_xs_none,
	.main_menu > li > a:after,
	[class*="select_button"]:after,
	[class*="select_button"]:before,
	[role="banner"] [role="search"]:after,
	[class*="ios_nav_"],
	.tp-leftarrow,.tp-rightarrow,
	.main_menu.type_2 > li > .sub_menu:before,
	.main_menu > li > .mega_menu_container:before,
	.main_menu.type_2 > li > .sub_menu > li:first-child > .sub_menu:before{
		display:none;
	}
	.w_xs_half_column{
		width:50% !important;
	}
	.f_xs_left,[class*="addthis_button_"],.addthis_counter{
		float:left;
	}
	.f_xs_right{
		float: right;
	}
	.d_xs_block{
		display:block;
	}
	.d_xs_inline_b{
		display:inline-block;
	}
	.d_xs_inline_m{
		display:inline-block;
		vertical-align: middle;
	}
	.t_xs_align_c{
		text-align:center;
	}
	.t_xs_align_l{
		text-align:left;
	}
	.m_xs_left_0{
		margin-left:0px;
	}
	.m_xs_left_5{
		margin-right:5px;
	}
	.m_xs_right_0{
		margin-right:0;
	}
	.m_xs_right_5{
		margin-right:5px;
	}
	.m_xs_right_8{
		margin-right:8px;
	}
	.m_xs_right_15{
		margin-right:15px;
	}
	.m_xs_right_20{
		margin-right:20px;
	}
	.m_xs_top_0{
		margin-top:0px;
	}
	.m_xs_top_10{
		margin-top:10px;
	}
	.m_xs_top_15{
		margin-top:15px;	
	}
	.m_xs_top_20{
		margin-top:20px;
	}
	.m_xs_bottom_0{
		margin-bottom:0;
	}
	.m_xs_bottom_5{
		margin-bottom:5px;
	}
	.m_xs_bottom_8{
		margin-bottom:8px;
	}
	.m_xs_bottom_10{
		margin-bottom:10px;
	}
	.m_xs_bottom_15{
		margin-bottom:15px;
	}
	.m_xs_bottom_20{
		margin-bottom:20px;
	}
	.m_xs_bottom_30{
		margin-bottom:30px;
	}
	.m_xs_bottom_50{
		margin-bottom:50px;
	}
	.t_xs_align_r{
		text-align: right;
	}
	.responsive_table thead tr,.responsive_table td.d_xs_none{
		display:none !important;
	}
	.responsive_table.rt_type_2 tbody tr{
		counter-reset: counter;
	}
	.responsive_table tbody td[colspan]{
		display: block;
		text-align: right;
		border-left:none;
		border-right: none;
		border-top:none;
		width:100%;
	}
	.responsive_table tbody td:not([colspan]){
		display:block;
		padding-left:52% !important;
		position:relative;
		border-left:none;
		border-right: none;
		border-top:none;
		width:100%;
	}
	.responsive_table tbody tr:nth-child(2n+1){
		background:#f7f9f9;
	}
	.responsive_table tbody td:not([colspan]):before{
		content: attr(data-title);
		position:absolute;
		left:15px;
		top:50%;
		width:40%;
		-webkit-transform:translateY(-50%);
		-moz-transform:translateY(-50%);
		-o-transform:translateY(-50%);
		-ms-transform:translateY(-50%);
		transform:translateY(-50%);
	}
	.responsive_table.rt_type_2 tbody tr td{
		counter-increment: counter;
	}
	.responsive_table.rt_type_2 tbody td:not([colspan]):before{
		content:counter(counter)". "attr(data-title);
	}
	.responsive_table tbody td:not([colspan]):after{
		content:"";
		display:block;
		height:100%;
		border-left:1px solid #bdc3c7;
		position:absolute;
		left:49%;
		top:0;
	}
	.responsive_table tr:last-child td:not(:last-child){
		border-bottom:1px solid #bdc3c7;
	}
	.coupon{
		width: 80%;
	}
	.hide-on-mobiles-checkout{
		display: none !important;
	}
	.hide-on-desktops-checkout{
		display: table-cell !important;
	}
	.table_type_2 .quantity-checkout input[type="text"] {
		border: 1px solid #e1e4e6;
		border-radius: 4px;
		padding-left:2px;
		padding-right:2px;
		text-align: center;
		font-style: normal;
		height: 33px;
		width: 100%;
	}
	.m_xs_bottom_0_checkout{
		margin-bottom: 0px;
	}
	.mobile_cart_counter{
		display: block !important;
	}
}
@media only screen and (max-width: 780px){
	.mobile_cart_counter {
		display: block !important;
	}
}

.hide-on-desktops-checkout{
	display: none;
}

.mobile_cart_counter{
	width: 16px;
	height: 16px;
	border-radius: 10px;
	background-color: red;
	color: #fff;
	position: absolute;
	top: 11px;
	right: 5px;
	text-align: center;
	font-size: 10px;
	display: none;
	line-height: 17px;
	z-index: 9999;
  }
.close_btn{
	float: right !important;
}
.hide-on-desktops-table {
	display: none !important;
}
@media (max-width: 780px){
	.hide-on-desktops-table {
		display: table !important;
	}
	.wsmenu>.wsmenu-list>li>.close_btn {
		padding: 9px 12px 9px 12px;
		font-size: 14px;
		text-align: left;
		border-right: solid 0px;
		background-color: transparent;
		color: #15488a;
		line-height: 25px;
		position: static;
		display: block;
	  }
	/* #scroll_down_btn {
		position: fixed;
		z-index: 99;
		font-size: 18px;
		outline: none;
		background-color: rgba(220, 220, 220, 0.521);
		color:#15488a;
		cursor: pointer;
		width:100%;
		text-decoration: none;
		top: 450px;
		text-align: center;
		line-height: 12px;
		padding: 9px;
	} */
	#scroll_down_btn {
		color: #c38d2a;
		bottom: 20px;
		left: 50%;
		z-index: 2;
		font: normal 400 20px/1 'Josefin Sans', sans-serif;
		letter-spacing: .1em;
		text-decoration: none;
		transition: opacity .3s;
		position: fixed;
		top: 470px;
		right: 4%;
		text-align: right;
		height: 24px;
		margin-left: -12px;
		box-sizing: border-box;
	}
	#swipe_to_scroll_span {
		display: unset !important;
	}

}

#swipe_to_scroll_span {
	display: none;
}


@media only screen and (max-width:696px){
	.steps_nav li .icon_wrap_size_7,
	.steps_nav li .container3d{
		width:60px;
		height:60px;
		line-height: 60px;
		font-size:30px;
	}
}
@media only screen and (max-width:500px){
	.product_view{
		width:100%;
	}
	.temp_mobile_heading {	
		text-align: center;
		padding: 0px 12px 18px 12px;
		font-size:2.3rem !important;
	}
	.temp_mobile_header{
		display: block !important;
		/* margin-top: 10px; 
		margin-bottom: -10px; */
	}
}
.temp_mobile_header{
	display: none;
}
@media only screen and (min-width:390px) and (max-width:570px){
	.t_sm_mxs_align_c{
		text-align:center;
	}
	.w_sm_mxs_full{
		width:100% !important;
	}
	.d_sm_mxs_block{
		display:block;
	}
	.f_sm_mxs_none{
		float: none;
	}
	.m_sm_mxs_top_15{
		margin-top:15px;
	}

}

@media only screen and (max-width:390px){
	.table_type_5 .quantity input[type="text"]{
		width:30px;
	}
	.w_mxs_full{
		width:100% !important;
	}
	.d_mxs_none{
		display:none;
	}
	.d_mxs_block{
		display:block;
	}
	.d_mxs_inline_b{
		display:inline-block;
	}
	.d_mxs_table_cell{
		display:table-cell;
	}
	.m_mxs_left_0{
		margin-left:0;
	}
	.m_mxs_right_0{
		margin-right:0;
	}
	.m_mxs_bottom_5{
		margin-bottom:5px !important;
	}
	.m_mxs_bottom_10{
		margin-bottom:10px;
	}
	.f_mxs_none{
		float:none;
	}
	.f_mxs_right{
		float:right;
	}
	.f_mxs_left{
		float:left;
	}
	.f_mxs_clear_both{
		clear: both;
	}
	.m_mxs_top_15{
		margin-top:15px;
	}
	.w_mxs_25{
		width:25px;
	}

	.w_mxs_auto{
		width:auto;
	}

	.min_mxs_height_0{
		min-height: 0px;
	}

	.w_mxs_20_per{
		width:20%;
	}

	.f_mxs_size{
		font-size: 0.8em !important;
	}
	.padding_mxs_22{
		padding: 22px !important;
	}

	.w_mxs_80_per{
		width:80%;
	}
	
	.social_icon{
		width:35px;
		height: 35px;
	}

}

/* -----------------------------------

	19. Retina ready

------------------------------------- */

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 144 dpi){
	.simple_slideshow .flex-direction-nav a,
	.ls-nav-prev,.ls-nav-next{
		background-image:url("/images/prevnext@2x.png");
		background-size:50px 100px;
	}
	.steps_nav li:after{
		background-image:url("/images/icon-right@2x.png");
		background-size:22px 40px;
	}
	.select_button_left:after,.select_button_left:before{
		background-image:url("/images/select_button_left@2x.png");
		background-size:14px 90px;
	}
}

.paginations li{
	padding: 0px;
	float: left;
	margin: 1px;
	border:none;
	border: 1px solid #D7D4D4;
}
/* .bottom_nav_chat {
	position: fixed;
	bottom:0;
	right:0;
	background-color: red;
	z-index:100;
} */


@media (max-width:991px){
	.category_tile_block {
		overflow: hidden;
	}
	.category_tile_image {
		/* clip-path: polygon(0% 25%, 100% 25%, 100% 75%, 0 75%); */
		/* margin-top: -25%; */
		margin-bottom: -25%;
	}
	.min_height_240 {
		min-height: 0;
	}
}


.join_our_network{
	background-color: #15488a;
	height: 35x;
	color: #fff;
	font-size: 16px;
	text-align: left;
	padding: 5px 20px;
}
#postcodeForm {
	padding:10px;
	
}
#postcode {
	max-width:61%;
	border: 1px solid #bdc3c7;
	height:33px;
	padding:5px 15px;
	border-radius: 4px;
	text-transform: uppercase;
}

.postcodeCheckerBlock{
	height: 100%;
    padding: 20px 15px 20px 15px;
    background-color: rgba(164, 214, 5, 0.1);
    border: 1px solid #a4d605;
    border-top: none;
}


.calculate-delivery-cost {
	width:35%;
	height: 33px !important;
	background-color: #15488a ;
	color:white;
	border:1px solid #15488a;
	-webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s;
    -o-animation-duration: .3s;
    animation-duration: .3s;
}

.calculate-delivery-cost:hover {
	color:#15488a;
	background-color: white;
}
.calculate-delivery-cost:focus {
	color:#ccc;
}


@media screen and (max-width:1200px){
	.calculate-delivery-cost {
		font-size: 11px;
	}
	.join_our_network{
		font-size: 15px;
	}
}

.home_page_article_block {
	border:1px solid #bdc3c7;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: 15px;
}

.larger-font-btn {
	font-size: 20px;
	background-color: #27ae60;
}


@media screen and (max-width:609px) {
	/* #relatedProductSelection .owl-item {
		max-width:49.5% !important;
		width:48% !important;
		box-sizing: border-box !important;
		padding:0 !important;
	}
	#relatedProductSelection .owl-wrapper {
		width:100% !important;
	} */
	#relatedProductSelection .owl-item figcaption h6{

		font-size: 11pt;
	}
	#relatedProductSelection .owl-item figcaption p{
		text-align: center;
		font-size: 9pt;
		width: 100%;
	}
	.related-product-rating {
		display: none;
	}
	.related-product-pricing  *{
		font-size: 9pt !important;
	}
	.related-product-pricing {
		text-align: center !important;
	}
	
}

@media screen and (max-width:765px) {
	.category_tile_image {
		margin-bottom: -25%;
		margin-top: -25%;
	}
	.slider-hider {
		display:none;
	}
	.position_static_xs {
        position: static !important;
        width: 95% !important;
    }
}
@media screen and (min-width:378px) {
	.category_tile_image {
		margin-top:unset;
	}

}
.text-responsive-left {
	text-align: left !important;
}

.postcode-checker-loader {
	position: absolute;
    top: 10%;
	left: 35%;
}
#shippingCalculator {
	position:relative;
	overflow: hidden;
}
.postcode-checker-loader-bg {
	width: 100%;
	height: 100%;
	background-color: #fff;
	position: absolute;
	opacity:0.5;
}

.postcode-checker-loader-wrapper {
	display: none;
}

.colour_picker {
	width:100%;
	border: 1px solid #bdc3c7;
    border-radius: 8px;
    margin-bottom: 15px;
}

#colourPicker{ 
	border-radius: 8px;	
}

.colour_picker_header {
	background-color: #15488a;
    height: 35px;
    color: #fff;
    font-size: 16px;
    text-align: left;
	padding: 5px 20px;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}

.colour_circle {
	display:inline-block;
	height:30px;
	width:30px;
	border-radius: 6px;
	border:1px solid #979a9c;
	margin:2px 7px 3px 7px;
	position: relative;
}

.colour_list {
	width:91%;
	margin:0 auto;
	padding-top: 7px;
	padding-bottom: 7px;
}

.colour-popup {
	position: absolute;
	border:1px solid #979a9c;
	padding:8px;
	top:-30px;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(255,255,255,.85);
	color:#34383d;
	z-index:999999;
	border-radius: 8px;
}

.colour_list a:hover .colour_circle div {
	display: block !important;
}

@media (max-width:1199px){
	.colour_circle {
		height: 25px;
		width: 25px;
		margin: 3px 5px;
	}	
}

/* The container */
.radio-wrapper {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 1
	2px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radio-wrapper input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
	position: absolute;
    top: 12px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .radio-wrapper:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio-wrapper input:checked ~ .checkmark {
	background-color: #15488a;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-wrapper input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radio-wrapper .checkmark:after {
	top: 8px;
    left: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: white;
  }

  
  /* Customize the label (the container) */
  .checkbox-container {
	  display: block;
	  position: relative;
	  padding-left: 35px;
	  margin-top: 6px;
	  margin-bottom: 12px;
	  cursor: pointer;
	  font-size: 16px;
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	user-select: none;
	border-radius:4px;
}
  
/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkboxmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkboxmark {
	background-color: #ccc;
}

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkboxmark {
	  background-color: #15488a;
	}
  
	/* Create the checkmark/indicator (hidden when not checked) */
  .checkboxmark:after {
	  content: "";
	  position: absolute;
	  display: none;
	}
  
	/* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkboxmark:after {
	  display: block;
	}
	
  /* Style the checkmark/indicator */
  .checkbox-container .checkboxmark:after {
	left: 10px;
    top: 6px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

  .options-header {
	  margin-bottom: 15px;
	  font-size: 18px;
  }
.surcharge-pricing{
  font-size: 15px;
  margin-top: 10px;
  margin-left:5px;
}
.surcharge-option-row {
	margin: 5px 0;
}

.surcharges { 
	padding:0 20px 0 5px !important;
	margin-bottom: 30px;
}
.surcharges-calendar {
	padding:0 2px 0 2px !important;
	margin-top: -5px !important;
}

.calendar-navbar {
	margin-bottom: 20px !important;
}
.time-slot {
	color:#15488a;
	font-size: 13.6px;
	text-align: center;
	margin-right: 5px;
}

input[type=radio] {
	appearance: none !important;
}

.calendar-view {
	margin-bottom: 30px;
}

.btn-calendar {
	background-color: #15488a;
	color: white;
	background-image: none;
	border-radius: 4px;
	box-shadow: none;
	height: 48px;
	width: 48px;
}

.btn-calendar:hover {
	background-color: white;
	color: #15488a;
}

.review-your-order {
	margin:30px;
}

.month-view {
	border-radius: 2px;
    overflow: hidden;
    border: 1px solid #ddd;
	padding: 0px;
	box-shadow: 0 1px 2px rgba(0,0,0,0.075);
}

.surcharges:first-child {
	border-right:1px solid rgb(240, 240, 240);
}


@media (max-width:1199px){
	.surcharges {
		font-size:15px;
	}
	.surcharges select {
		padding:3px;
		margin-top: 9px;
	}
	.time-slot {
		font-size: 11px;
	}
	.radio-wrapper small {
		font-size: 11px;
	}
	.radio-wrapper {
		font-size: 15px;
	}

}

@media (max-width:767px){
	.surcharges:first-child {
		border:0;
	}
}
#corona-banner {
	display: none !important;
	width: 100%;
}
#corona-banner-small {
	display: none !important;
	width: 100%;
}


@media (min-width:767px){
	#corona-banner{
		display: block !important;
	}

}

@media (max-width:767px){
	#corona-banner-small{
		display: block !important;
	}
}
/* 

.info-badge {
	background-color: #15488a;
	border-radius: 50%;
	color:white;
	font-size: 10px;
	padding:0;
	display: inline-block;
	text-align: center;
	height: 15px;
	width: 15px;
	line-height: 15px;
	cursor: pointer;
	position: relative;
}

.info-badge:hover {
	background-color: #fff;
	color:#15488a;
	border:1px solid #15488a;
}

.info-badge .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: white;
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	opacity: 1;
	position: absolute;
	top:-3px;
	left:20px;
	z-index: 1;
	border:1px solid #15488a;
	font-size: 13px;
	
  }

  .info-badge:hover .tooltiptext {
	visibility: visible !important;
  } */

  /* .info-modal {
	  display: none;
  } */

#info-modal-list {
	list-style-type: circle !important;
	padding:0 0 0 20px;
}
#info-modal-list li {
	padding:5px 0;
}
@media (max-width:575.98px){
	.toggle-wedding-category{
		display: block;
	}
	.toggle-wedding-category-2{
		display: none;
	}
}

@media (min-width:575.98px){
	.toggle-wedding-category-2{
		display: block;
	}
	.toggle-wedding-category{
		display: none;
	}
}
#collapse-btn-venues {
	height: 33px !important;
	background-color: #15488a ;
	color:white;
	border:1px solid #15488a;
	-webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-animation-duration: .3s;
    -moz-animation-duration: .3s;
    -o-animation-duration: .3s;
    animation-duration: .3s;
}

#collapse-btn-venues:hover {
	color:#15488a;
	background-color: white;
}
#collapse-btn-venues:focus {
	color:#ccc;
}

#hire_days_container{
	background-color: #ecf0f1;
	border-radius: 8px;
	padding:10px;
	margin-bottom: 15px;
	font-size: 13px;
}
#no_of_hire_days_range::-moz-range-thumb {
	width: 20px;
	height: 20px;
	background: #15488a;
	cursor: pointer;
	border-radius: 50%;
}
#no_of_hire_days_range::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	background: #15488a;
	cursor: pointer;
	border-radius: 50%;
}
#no_of_hire_days_range{
	-webkit-appearance: none;
	width: 100%;
	height:5px !important;
	background: #d3d3d3;
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
	border-radius:4px;
	border:none !important;
	padding:0px;
}
.basket-button {
	color: #000;
}
@media only screen and (max-width:780px){
	.hide-on-desktop-view {
		display: none !important;
	}
	.mobile-text-left {
		text-align: unset !important;
	}
	.remove-on-quote {
		float:left;
		margin-left: 5px;
		margin-top: 8px;
		display: flex !important;
	}
	#remove_product_quote_page {
		margin: unset !important;
	}
}
@media (max-width:320px){
	#added_to_cart_col{
		margin-left: -1rem;
		font-size: 1rem !important;
	}
}
#added_to_cart_col_table{
	border-bottom: 0px;
	font-size:1.5rem
}
