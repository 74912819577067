
.designer_original {
  position: absolute;
  z-index: 2;
  left: -8px;
  bottom: -6px;
  color: #34383d;
  font-size: 12px;
  margin-left: 10px;
}
.designer_original_product {
  position: absolute;
  z-index: 2;
  left: -8px;
  bottom: -12px;
  color: #34383d;
  font-size: 12px;
  margin-left: 10px;
}
.designer_image {
    width: 40%;
    display: block;
    position: relative;
}

.designer_image_product {
  width: 70%;
  display: block;
  position: relative;
}

.wsmain{
  background: #15488a;
  max-width: 1140px;
  margin:0px auto;
}
.pinned_wsmain{
  background: #15488a;
  max-width: 1140px;
  margin:0px auto;
}
.wsmenu-list>li>a:hover{
  color: #fff;
}
.wsmenu-list>li>a .fa{
  color:#fff;
}
.wsmenu{
  width: 100%;
}
.wsmenu-submenu{
  top: 47px;
}
.smallogo{
  background: #15488a;
  box-shadow:none;
  -webkit-box-shadow:none;
  font-size:25px;
  padding-top: 12px;
  top:0;
}
.smallogo:focus,.smallogo:active,.smallogo,.smallogo:hover{
  color: #fff !important;
}
/* .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after{
  background:#fff;
} */
.topmenusearch input:hover,.topmenusearch input:focus,.topmenusearch input{
  width: 100%;
  background-color: #15488a;
  color:#15488a;
}
.topmenusearch .btnstyle{
  opacity: 1;
}
.topmenusearch .btnstyle:hover, .topmenusearch .btnstyle:focus{
  background-color: #15488a;
}
.pinned_topmenusearch input:hover,.pinned_topmenusearch input:focus,.pinned_topmenusearch input{
  width: 100%;
  background-color: #15488a;
  color:#15488a;
}
.pinned_topmenusearch .btnstyle{
  opacity: 1;
}
.pinned_topmenusearch .btnstyle:hover, .pinned_topmenusearch .btnstyle:focus{
  background-color: #15488a;
}
.callusicon:focus span, .callusicon:active span,.callusicon span,
.carticon:focus span, .carticon:active span,.carticon span{
  color:#fff;
}
.topmenusearch{
  width: 180px;
}

.carticon {
  color: #c9c9c9;
  font-size: 16px;
  height: 16px;
  position: fixed;
  right: 5px;
  top: 13px;
  transition: all 0.4s ease-in-out 0s;
  width: 25px;
  z-index: 102;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.carticon:focus, carticon:hover {
  color: #fff !important;
}
.wsoffcanvasopener .carticon {
  display: none !important;
}

.topmenusearch input::placeholder,.topmenusearch input::-ms-input-placeholder{
  color:#15488a;
  opacity: 1
}
.pinned_topmenusearch input::placeholder,.pinned_topmenusearch input::-ms-input-placeholder{
  color:#15488a;
  opacity: 1
}


.topmenusearch input::-webkit-input-placeholder {
  color:#15488a !important;
  opacity: 1 !important;
}

.topmenusearch input:-moz-placeholder { /* Firefox 18- */
  color:#15488a !important;
  opacity: 1 !important;
}

.topmenusearch input::-moz-placeholder { /* Firefox 19+ */
  color:#15488a !important;
  opacity: 1 !important;
}

.topmenusearch input:-ms-input-placeholder { /* IE 10+ */
  color:#15488a !important;
  opacity: 1 !important;
}

.topmenusearch input::-ms-input-placeholder { /* Edge */
  color:#15488a !important;
  opacity: 1 !important;
}

.topmenusearch input:placeholder-shown { /* Standard one last! */
  color:#15488a !important;
  opacity: 1 !important;
}
.pinned_topmenusearch input::-webkit-input-placeholder {
  color:#15488a !important;
  opacity: 1 !important;
}

.pinned_topmenusearch input:-moz-placeholder { /* Firefox 18- */
  color:#15488a !important;
  opacity: 1 !important;
}

.pinned_topmenusearch input::-moz-placeholder { /* Firefox 19+ */
  color:#15488a !important;
  opacity: 1 !important;
}

.pinned_topmenusearch input:-ms-input-placeholder { /* IE 10+ */
  color:#15488a !important;
  opacity: 1 !important;
}

.pinned_topmenusearch input::-ms-input-placeholder { /* Edge */
  color:#15488a !important;
  opacity: 1 !important;
}

.pinned_topmenusearch input:placeholder-shown { /* Standard one last! */
  color:#15488a !important;
  opacity: 1 !important;
}
.mobile-bar-container{
  display: none;
  height: 50px;
  background-color: #15488a;
  position: fixed;
  top: 0;
  align-content: stretch;
  flex-flow: row;
  width: 100%;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.47);
}
.mobile-bar-child{
    height: 50px;
    margin: auto;
    flex: 1 auto;
}
.flex-bigger{
  margin-left: 112px;
  flex: 3 0px;
  padding-top: 10px;
  text-align: center;
}
.flex-bigger a{
  color: #fff;
  font-size: 23px;
}
.flex-bigger a:hover{
  color: #fff;
}

@media only screen and (min-width: 780px) {
  #form-mobile-search {
    display: none !important;
  }
  
}

@media only screen and (max-width: 780px) {
  .topmenusearch{
    width: 100%;
  }
  .dropdown_2_container.shoppingcart button{
    display: none;
  }
  .header_top_right{
    height: 0px;
  }
  .wsmenucontainer{
    display: none;
  }
  .pinned_wsmenucontainer{
    display: none;
  }
  .mobile-bar-container{
    display: flex;
  }
}